const LoadingModal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null; // Don't render the modal if it's not open
  
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
        onClick={onClose} // Close the modal when clicking outside
      >
        <div
          style={{
            background: "rgb(229 231 235)",
            padding: "20px",
            borderRadius: "8px",
            maxWidth: "400px",
            width: "100%",
            textAlign: "center",
          }}
          onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside
        >
          {children}
        </div>
      </div>
    );
  };
  
  export default LoadingModal;
  