import React from "react";

const RFQSavings = ({ rfqData }) => {
    const getMinimumQuotation = () => {
        if (!rfqData?.suppliers) return 0;
        const suppliersWithQuotes = rfqData.suppliers.filter(supplier => supplier.quotationReceived);
        const supplierQuotations = suppliersWithQuotes.map((supplier) => {
            const firstQuotationSet = supplier.vendorQuotations?.quotationSets?.[0];
            if (firstQuotationSet) {
                const totalWithoutGst = firstQuotationSet.totalWithoutGst || 0;
                const othersTotalWithoutGst = firstQuotationSet.othersTotalWithoutGst || 0;
                const total = totalWithoutGst + othersTotalWithoutGst;
                return total;
            }
            return Infinity; 
        });

        const minQuotation = Math.min(...supplierQuotations);
        // console.log("minQuotation: ", minQuotation); 
        return minQuotation === Infinity ? 0 : minQuotation; 
    };

    const calculateSavings = () => {
        const awardedSuppliers = rfqData?.suppliers?.filter(supplier => supplier.isAwarded);
        const totalAwardedAmount = awardedSuppliers.reduce((sum, supplier) => {
            const awardedAmount = Number(supplier.closingDetails[0]?.amount) || 0; 
            // console.log("awardedAmount: ", awardedAmount); 
            return sum + awardedAmount; 
        }, 0);
        // console.log("%%totalAwardedAmount", totalAwardedAmount);
        const minQuotation = getMinimumQuotation();
        // console.log("minQuotation: ", minQuotation); 
        const savingsAmount = minQuotation - totalAwardedAmount;
        // console.log("savingsAmount: ", savingsAmount); 
        return savingsAmount < 0 ? 0 : savingsAmount;; 
    };
    

    const savings = calculateSavings();

    return (
        <div className="flex flex-col w-full justify-start">
            <h2 className="text-gray-900 font-semibold">RFQ Saving</h2>
            <h4 className="text-xs font-xs font-bold text-gray-900">
                Saving Amount (excl. GST): Rs. {new Intl.NumberFormat('en-IN').format(savings)}
            </h4>
            <p className="text-gray-600 text-[10px]">
                Note: RFQ saving is the difference between final closure amount and the lowest quotation received at first round.
            </p>
        </div>
    );
};

export default RFQSavings;
