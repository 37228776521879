class SendRfqInfo {
  constructor({ entityId, values }) {
    this.name = values.rfqName;
    // this.estimatedValue = values.estimatedValue;
    this.rfqType = values.requirementType;
    // this.validityDate = values.dueDate;
    this.newCcEmails = values.emailCopyRecipients;
    this.projectName = values.projectName;
    this.projectId = values.projectId;
    // this.location = values.projectLocation;
    // this.creditPeriod = values.creditPeriod;
    this.description = values.rfqDetails;
    // this.indentId = values.indentId;
    this.requesterName = values.requesterName;
    this.requesterMobile = values.requesterMobile;
    this.buyerPublicContact = values.buyerPublicContact;
    this.userBusinessName = values.userBusinessName;
    this.requestedBy = values.requestedBy;
    this.requesterId = values.requesterId;
    this.isEntityUser = values.isEntityUser;
    // this.workStartDate = values.workStartDate;
    this.selectedFilesBase = values.attachments;
    this.entityId = entityId;
    this.entityName = values.entityName;
    this.providingDate = values.providingDate;
    this.acceptCounterTerms = values.acceptCounterTerms;
    this.paymentTerms = values.paymentTerms;
    this.otherCharges = values.otherCharges;

    // Conditionally include fields based on rfqType
    if (this.rfqType === "material") {
      // Only include materials if rfqType is "material"
      if (Array.isArray(values.materials) && values.materials.length > 0) {
        this.materials = values.materials;
      }
    } else if (this.rfqType === "labour") {
      // Only include labourDetails if rfqType is "labour"
      if (Array.isArray(values.labourDetails) && values.labourDetails.length > 0) {
        this.labourDetails = values.labourDetails;
      }
    } else if (this.rfqType === "contractor") {
      // Only include providingFixingDetails if rfqType is "providingFixing"
      if (values.providingFixingDetails) {
        this.providingFixingDetails = values.providingFixingDetails;
      }
    }

    // Include otherCharges as they are not dependent on rfqType
  }
}



class SendEditRfqInfo {
  constructor({ entityId, values }) {
    this.name = values.rfqName;
    this.rfqId = values?.rfqId;
    // this.estimatedValue = values.estimatedValue;
    this.rfqType = values.requirementType;
    // this.validityDate = values.dueDate;
    this.newCcEmails = values.emailCopyRecipients;
    this.projectName = values.projectName;
    this.projectId = values.projectId;
    // this.location = values.projectLocation;
    // this.creditPeriod = values.creditPeriod;
    this.description = values.rfqDetails;
    // this.indentId = values.indentId;
    this.requesterName = values.requesterName;
    this.requesterMobile = values.requesterMobile;
    this.userBusinessName = values.userBusinessName;
    this.requestedBy = values.requestedBy;
    this.requesterId = values.requesterId;
    this.isEntityUser = values.isEntityUser;
    // this.workStartDate = values.workStartDate;
    this.selectedFilesBase = values.attachments;
    this.entityId = entityId;
    this.entityName = values.entityName;
    this.providingDate = values.providingDate;
    this.acceptCounterTerms = values.acceptCounterTerms;
    this.paymentTerms = values.paymentTerms;
    this.otherCharges = values.otherCharges;

    // Conditionally include fields based on rfqType
    if (this.rfqType === "material") {
      // Only include materials if rfqType is "material"
      if (Array.isArray(values.materials) && values.materials.length > 0) {
        this.materials = values.materials;
      }
    } else if (this.rfqType === "labour") {
      // Only include labourDetails if rfqType is "labour"
      if (Array.isArray(values.labourDetails) && values.labourDetails.length > 0) {
        this.labourDetails = values.labourDetails;
      }
    } else if (this.rfqType === "contractor") {
      // Only include providingFixingDetails if rfqType is "providingFixing"
      if (values.providingFixingDetails) {
        this.providingFixingDetails = values.providingFixingDetails;
      }
    }

    // Include otherCharges as they are not dependent on rfqType
  }
}
// class SendEditRfqInfo {
//   constructor({ selectedFilesBase, values }) {
//     this.rfqId = values?.rfqId;
//     this.name = values.rfqName;
//     this.estimatedValue = values.estimatedValue;
//     this.rfqType = values.requirementType;
//     this.validityDate = values.dueDate;
//     this.newCcEmails = values.emailCopyRecipients;
//     this.projectName = values.projectName;
//     this.projectId = values.projectId;
//     this.location = values.projectLocation;
//     this.creditPeriod = values.creditPeriod;
//     this.description = values.rfqDetails;
//     this.indentId = values.indentId;
//     this.selectedFilesBase = selectedFilesBase;
//   }
// }
export default { SendRfqInfo };
export { SendRfqInfo, SendEditRfqInfo };
