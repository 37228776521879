import {
  deleteRfq,
  fetchRfqs,
  fetchSearchRfqs,
  restoreRfq,
  searchRfq,
  searchRfqWithDate,
  trashedRfq,
} from "actions/rfqActions";
import { Tooltip } from "flowbite-react";
import Loading from "components/Loading";
import StaticPopup from "components/StaticPopup";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import rfqService from "services/rfqService";
import { PaginationSection } from "views/CompanyDirectory/components";

const statusShowByColor = (status) => {
  switch (status) {
    case "DRAFT": {
      return (
        <div className="w-fit bg-yellow-200 text-yellow-950 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-100 dark:text-green-800">
          Draft
        </div>
      );
    }
    case "CLOSED": {
      return (
        <div className="w-fit bg-red-200 text-red-950 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-100 dark:text-green-800">
          {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
        </div>
      );
    }
    case "CANCELED": {
      return (
        <div className="w-fit bg-gray-200 text-gray-950 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-100 dark:text-green-800">
          {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
        </div>
      );
    }
    case "DELETED": {
      return (
        <div className="w-fit bg-cyan-200 text-cyan-950 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-100 dark:text-green-800">
          {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
        </div>
      );
    }
    case "EXPIRED": {
      return (
        <div className="w-fit bg-red-200 text-red-950 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-100 dark:text-green-800">
          {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
        </div>
      );
    }
    case "HOLD": {
      return (
        <div className="w-fit bg-purple-200 text-purple-950 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-100 dark:text-green-800">
          {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
        </div>
      );
    }
    case "OPEN": {
      return (
        <div className="w-fit bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-100 dark:text-green-800">
          Open
        </div>
      );
    }
    default: {
      return (
        <div>
          {" "}
          {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
        </div>
      );
    }
  }
};

const RfqsTableSection = ({
  handleGetRfqs,
  entityId,
  userIds,
  rfqsData,
  rfqFilterStatus,
  user,
  workspaceView,
  dateFilterOn,
  fetchFilterDateRfq,
  startDate,
  endDate,
  projectView = false,
  projectId,
  isApprovalRequired
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchRef = useRef(null);

  const [searchValue, setSearchValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [rfqs, setRfqs] = useState(rfqsData?.rfqs ?? []);
  const [currentPage, setCurrentPage] = useState(1);
  const [openRfqDeletePopup, setOpenRfqDeletePopup] = useState(false);
  const [rfqsErrors, setRfqsErrors] = useState({
    error: false,
    errorMassage: "",
  });
  const [openPoIdPopup, setOpenPoIdPopup] = useState(false);
  const [listOfPoIds, setListOfPoIds] = useState([]);
  const [loading, setLoading] = useState(rfqsData?.loading);
  const [loadingDeleteRfq, setLoadingDeleteRfq] = useState(rfqsData?.loading);
  const [deleteRfqIdData, setDeleteRfqIdData] = useState({});
  const [deleteRfqStatus, setDeleteRfqStatus] = useState();
  const rowInfo = [
    "RFQ No.",
    "Create Date",
    "Project Name",
    "RFQ Type",
    "RFQ Subject",
    // "Close Date",
    // "PO ID",
    // "INDENT NUMBER",
    // "No. Of Vendor Selected",
    "Invited/Quotation",
    "Status",
    // "Vendor Awarded",
    "Closed date",
    // "Action",
  ];
  const rows =
    workspaceView === "My Workspace"
      ? projectView
        ? rowInfo?.filter((data) => data !== "ACTION")
        : rowInfo
      : [
        "RFQ NO.",
        "Create Date",
        "Created By",
        "Project Name",
        "RFQ Type",
        "RFQ Subject",

        // "Close Date",
        // "PO ID",
        // "Indent Number",
        // "No. Of Vendor Selected",
        "Invited/Quotation",
        "Status",
        // "Vendor Awarded",
        "Closed date",
        // "Action",
      ];
  const stringUserIds = userIds.map((number) => String(number));
  const totalCount = rfqsData?.totalCount;
  const convertedData = rfqsData?.statusWiseCounts?.reduce((acc, curr) => {
    acc[curr._id] = curr?.count;
    return acc;
  }, {});

  const totalPages =
    rfqFilterStatus == ""
      ? rfqsData?.totalPages
      : convertedData[rfqFilterStatus] > 100
        ? Math.ceil(convertedData[rfqFilterStatus] / 100)
        : 1;

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  const handleOpenRfq = (rfqId) => {
    navigate(`/rfqs/${rfqId}`);
  };

  const handleDeleteRfq = async () => {
    setLoadingDeleteRfq(true);
    if (deleteRfqStatus === "DELETED") {
      const response = await dispatch(
        restoreRfq({
          rfqId: deleteRfqIdData?.id,
          entityId,
          userIds: stringUserIds,
          currentStatus: rfqFilterStatus,
          actionBy: { userId: user?.userId, name: user?.name },
          startDate,
          endDate,
        })
      );
    } else if (deleteRfqStatus === "Delete") {
      const response = await dispatch(
        deleteRfq({
          rfqId: deleteRfqIdData?.id,
          entityId,
          userIds: stringUserIds,
          currentStatus: rfqFilterStatus,
          actionBy: { userId: user?.userId, name: user?.name },
          startDate,
          endDate,
        })
      );
    } else {
      const response = await dispatch(
        trashedRfq({
          rfqId: deleteRfqIdData?.id,
          entityId,
          userIds: stringUserIds,
          currentStatus: rfqFilterStatus,
          actionBy: { userId: user?.userId, name: user?.name },
          startDate,
          endDate,
        })
      );
    }
    setLoadingDeleteRfq(false);
    setOpenRfqDeletePopup(false);
  };

  const handleOpenDeleteRfqPopup = (rfqId, userId, status) => {
    setDeleteRfqIdData({ id: rfqId, userId });
    setDeleteRfqStatus(status);
    setOpenRfqDeletePopup(true);
  };

  const handlePermanentDeleteRfq = (rfqId, userId, status) => {
    setDeleteRfqIdData({ id: rfqId, userId });
    setDeleteRfqStatus(status);
    setOpenRfqDeletePopup(true);
  };

  const handleCloseDeleteRfqPopup = () => {
    setOpenRfqDeletePopup(false);
  };
  const handleOpenPoIdPopup = (supplier) => {
    setOpenPoIdPopup(true);
    setListOfPoIds(poIdList(supplier));
  };

  const closePoIdPopup = () => {
    setOpenPoIdPopup(false);
    setListOfPoIds([]);
  };

  const lengthOfRfqAwardeeVendors = (supplier) => {
    let count = 0;
    supplier.forEach((rfq) => {
      if (rfq.closingDetails) {
        count++;
      }
    });
    return count;
  };

  const poIdList = (supplier) => {
    const list = supplier.map((rfq) => {
      if (rfq.closingDetails?.length >= 1) {
        return rfq?.closingDetails?.map((data) => data?.poNumber);
      }
      return [];
    });
    const filterList = list?.filter((rfq) => rfq?.length >= 1 && rfq);
    return filterList?.length == 0 ? ["--"] : filterList.flat();
  };


  const handleSearchChange = async (e) => {
    setSearchValue(e.target.value);
    if (e.target.value == "") {
      setLoading(true);
      const response = await dispatch(
        searchRfqWithDate({
          projectId,
          entityId,
          searchParam: "",
          status: rfqFilterStatus,
          userIds: stringUserIds,
          startDate: rfqsData?.startDate,
          endDate: rfqsData?.endDate,
          startDateOfClosing: rfqsData?.startDateOfClosing,
          endDateOfClosing: rfqsData?.endDateOfClosing,
          dateFilterIsActive: rfqsData?.dateFilterIsActive,
          closeDateFilterIsActive: rfqsData?.closeDateFilterIsActive,
        })
      );
      setSearchKey("");
      setLoading(false);
    }
  };
  const handleSearchRfqs = async () => {
    setLoading(true);
    const response = await dispatch(
      searchRfqWithDate({
        projectId,
        entityId,
        searchParam: searchValue,
        status: rfqFilterStatus,
        userIds: stringUserIds,
        startDate: rfqsData?.startDate,
        endDate: rfqsData?.endDate,
        startDateOfClosing: rfqsData?.startDateOfClosing,
        endDateOfClosing: rfqsData?.endDateOfClosing,
        dateFilterIsActive: rfqsData?.dateFilterIsActive,
        closeDateFilterIsActive: rfqsData?.closeDateFilterIsActive,
      })
    );

    setLoading(false);
    setSearchKey(searchValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearchRfqs();
      e.preventDefault();
    }
  };

  const handlePageChange = (pageNum) => {
    setCurrentPage(pageNum);
    if (dateFilterOn) {
      fetchFilterDateRfq({ page: pageNum });
    } else {
      dispatch(
        fetchRfqs({
          entityId,
          userIds: stringUserIds,
          status: rfqFilterStatus,
          page: pageNum,
        })
      );
    }
  };

  useEffect(() => {
    setRfqs(rfqsData?.rfqs);
    setSearchValue(rfqsData?.searchParam ?? "");
    setCurrentPage(rfqsData?.currentPage);
    setSearchKey(rfqsData?.searchParam ?? "");
    setLoading(rfqsData?.loading);
    setLoadingDeleteRfq(rfqsData?.loading);
    setRfqsErrors({
      error: rfqsData?.error,
      errorMassage: rfqsData?.errorMessage,
    });
    setTimeout(() => {
      setRfqsErrors({
        error: false,
        errorMassage: "",
      });
    }, 6000);
  }, [rfqsData]);

  useEffect(() => {
    // when user click ctrl + f  then focus on search bar
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "f") {
        event.preventDefault(); // Prevent default Ctrl + F behavior
        searchRef.current.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <div>
      <section className="flex items-center px-4 py-2">
        <div className=" mx-auto w-full">
          <div className=" dark:bg-gray-800 sm:rounded-lg">
            <div className="flex flex-col items-center justify-between  space-y-3 md:flex-row md:space-y-0 md:space-x-4">
              {/* showing results */}
              <div>
                {/* <p className="font-medium text-gray-600">{`${rfqs.length} RFQ Packages`}</p> */}
                <p className="font-medium text-xs text-gray-600">
                  {" "}
                  {rfqs?.length >= 1 && (
                    <>
                      {`Showing ${(rfqsData?.currentPage - 1) * 100 + 1}-${(rfqsData?.currentPage - 1) * 100 + rfqs?.length
                        } of ${rfqs?.length < 99
                          ? rfqs?.length
                          : rfqFilterStatus === ""
                            ? rfqsData?.totalCount
                            : rfqsData?.statusWiseCounts?.find(
                              (item) => item._id === rfqFilterStatus
                            )?.count
                        } RFQ ${rfqs?.length > 1 ? "results" : "result"} `}
                      {searchKey != "" ? (
                        <span>
                          for{" "}
                          <span className="text-[#fd6600] font-bold">
                            "{searchKey}"
                          </span>
                        </span>
                      ) : null}
                    </>
                  )}{" "}
                </p>
              </div>

              {/* search */}
              <div className="w-full md:w-1/2">
                <div className="flex items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="simple-search"
                      ref={searchRef}
                      className="block w-full placeholder:text-xs p-2 pl-10 text-xs text-gray-900 border border-gray-300 rounded-lg bg-gray-100 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Search by Description, RFQ Id, Vendor Name etc."
                      value={searchValue}
                      onChange={handleSearchChange}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {loading ? (
        <div className="flex justify-center items-center h-[50vh]">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10"></div>
        </div>
      ) : (
        <>
          {rfqs?.length >= 1 ? (
            <>
              <div
                id="scrollableDiv"
                className="overflow-x-auto relative max-h-[calc(100vh-270px)]"
              //onScroll={handleCloseDropDown} //we used position fixed to show dropdown when user try to scroll then that dropdown hidden
              >
                <table className="w-full text-xs text-left text-gray-600 dark:text-gray-400 relative">
                  <thead className="sticky top-0 text-xs max-w-full bg-gray-200 text-gray-700  dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      {rows.map((title, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-4 py-3 text-gray-500"
                        >
                          <p
                            className={`${title === "RFQ NO." ||
                              title === "VENDOR SELECTED" ||
                              // title ==="PO ID" ||
                              title === "INDENT NUMBER"
                              ? "text-right "
                              : "text-left "
                              }truncate`}
                          >
                            {title}
                          </p>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rfqs?.map((row, rowIndex) => (
                      <tr key={rowIndex} className="text-gray-600">

                        {/* rfq no. */}
                        <td>
                          <div className="flex  justify-end px-4 py-2">
                            <p className="text-right text-xs text-gray-600 font-medium">
                              {row?.rfqId}
                            </p>
                          </div>
                        </td>

                        {/* create date */}
                        <td>
                          <div className="flex  justify-start px-4 py-2">
                            <p className="text-left  text-xs text-gray-600 font-medium">
                              {row?.createdAt
                                ? new Date(row?.createdAt).toLocaleDateString(
                                  "en-GB"
                                )
                                : "NA"}
                            </p>
                          </div>
                        </td>

                        {workspaceView != "My Workspace" && (
                          <td>
                            <div className="flex  justify-start px-4 py-2">
                              <p className="text-left truncate text-xs text-gray-600 font-medium">
                                {row?.requesterName}
                              </p>
                            </div>
                          </td>
                        )}

                        <td>
                          <div className="w-48 px-4 py-2 ">
                            <p className=" overflow-ellipsis">{row?.projectName}</p>
                          </div>
                        </td>

                        {/* rfq type */}
                        <td className="px-4 py-2">{capitalizeFirstLetter(row?.rfqType)}</td>

                        {/* rfq subject */}

                        <td>
                          <div className="px-4 py-2 max-w-64">
                            <p
                              className="truncate overflow-ellipsis overflow-hidden text-xs text-blue-500 cursor-pointer font-medium"
                              onClick={() => handleOpenRfq(row?.rfqId)}
                            >
                              {row?.name}
                            </p>
                          </div>
                        </td>



                        <td className="text-right px-4 py-2 ">{row?.suppliers?.length}/{row?.suppliers?.filter(supplier => supplier.quotationReceived)?.length || 0}</td>

                        {/* <td className="text-right px-4 py-2">{row?.suppliers?.length}</td> */}
                        <td>
                          <div className="flex  justify-start px-4 py-2">
                            {/* <p className=" text-center text-xs text-gray-600 font-medium">
                      {row?.status}
                    </p> */}
                            {statusShowByColor(row?.status)}
                          </div>
                        </td>

                        <td>
                          <div className="flex  justify-start px-4 py-2">
                            <p className="text-left  text-xs text-gray-600 font-medium">
                              {row?.status === "CLOSED" &&
                                row?.awardeeDetails ? (
                                new Date(
                                  row?.awardeeDetails?.date
                                ).toLocaleDateString("en-GB")
                              ) : row?.status === "CLOSED" &&
                                row?.rfqClosingDetails ? (
                                new Date(
                                  row?.rfqClosingDetails?.date
                                ).toLocaleDateString("en-GB")
                              ) : (
                                <span className="text-gray-300">--</span>
                              )}
                            </p>
                          </div>
                        </td>

                        {/* <td>
                          <div className="flex  justify-start px-4 py-2">
                            {poIdList(row?.suppliers)?.length <= 1 ? (
                              <p className=" text-left text-xs text-gray-600 font-medium">
                                {poIdList(row?.suppliers)[0] === "--" ? (
                                  <span className="text-gray-300">--</span>
                                ) : (
                                  poIdList(row?.suppliers)
                                )}
                              </p>
                            ) : (
                              <p className=" truncate text-left text-xs text-gray-600 font-medium">
                                {poIdList(row?.suppliers)[0]}
                                {", "}
                                <span
                                  className="text-gray-600 underline cursor-pointer ml-1 text-xs"
                                  onClick={() =>
                                    handleOpenPoIdPopup(row?.suppliers)
                                  }
                                >
                                  +{poIdList(row?.suppliers)?.length - 1} more
                                </span>
                              </p>
                            )}
                          </div>
                        </td> */}

                        {/* <td>
                          <div className="flex  justify-end px-4 py-2">
                            <p className="truncate text-right text-xs text-gray-600 font-medium">
                              {row?.indentId ? (
                                row?.indentId
                              ) : (
                                <span className="text-gray-300">--</span>
                              )}
                            </p>
                          </div>
                        </td> */}



                        {/* <td className=" px-4 py-2">
                          <div className="flex justify-end">
                            <p className="pr-4 text-right text-xs text-gray-600  font-medium">
                              {row?.suppliers?.length ?? (
                                <span className="text-gray-300">--</span>
                              )}
                            </p>
                          </div>
                        </td> */}
                        {/* {!projectView && (
                          <td>
                            <div className="flex  justify-start px-4 py-2 font-medium text-blue-700">
                              {row?.status === "DELETED" ? (
                                <div className="flex gap-2">
                                  <Tooltip content="Restore">
                                    <button
                                      onClick={() =>
                                        handleOpenDeleteRfqPopup(
                                          row?.rfqId,
                                          row?.requesterId,
                                          row?.status
                                        )
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                        width="18px"
                                        height="18px"
                                      >
                                        <path
                                          fill="green"
                                          d="M174.7 45.1C192.2 17 223 0 256 0s63.8 17 81.3 45.1l38.6 61.7 27-15.6c8.4-4.9 18.9-4.2 26.6 1.7s11.1 15.9 8.6 25.3l-23.4 87.4c-3.4 12.8-16.6 20.4-29.4 17l-87.4-23.4c-9.4-2.5-16.3-10.4-17.6-20s3.4-19.1 11.8-23.9l28.4-16.4L283 79c-5.8-9.3-16-15-27-15s-21.2 5.7-27 15l-17.5 28c-9.2 14.8-28.6 19.5-43.6 10.5c-15.3-9.2-20.2-29.2-10.7-44.4l17.5-28zM429.5 251.9c15-9 34.4-4.3 43.6 10.5l24.4 39.1c9.4 15.1 14.4 32.4 14.6 50.2c.3 53.1-42.7 96.4-95.8 96.4L320 448v32c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-64-64c-9.4-9.4-9.4-24.6 0-33.9l64-64c6.9-6.9 17.2-8.9 26.2-5.2s14.8 12.5 14.8 22.2v32l96.2 0c17.6 0 31.9-14.4 31.8-32c0-5.9-1.7-11.7-4.8-16.7l-24.4-39.1c-9.5-15.2-4.7-35.2 10.7-44.4zm-364.6-31L36 204.2c-8.4-4.9-13.1-14.3-11.8-23.9s8.2-17.5 17.6-20l87.4-23.4c12.8-3.4 26 4.2 29.4 17L182 241.2c2.5 9.4-.9 19.3-8.6 25.3s-18.2 6.6-26.6 1.7l-26.5-15.3L68.8 335.3c-3.1 5-4.8 10.8-4.8 16.7c-.1 17.6 14.2 32 31.8 32l32.2 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-32.2 0C42.7 448-.3 404.8 0 351.6c.1-17.8 5.1-35.1 14.6-50.2l50.3-80.5z"
                                        />
                                      </svg>
                                    </button>{" "}
                                  </Tooltip>

                                  <Tooltip content="Permanent Delete">
                                    <button
                                      onClick={() =>
                                        handlePermanentDeleteRfq(
                                          row?.rfqId,
                                          row?.requesterId,
                                          "Delete"
                                        )
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-p79yt4"
                                        viewBox="0 0 24 24"
                                        focusable="false"
                                        width="18px"
                                        height="18px"
                                        aria-hidden="true"
                                      >
                                        <path
                                          fill="#4B5563"
                                          d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"
                                        />
                                      </svg>
                                    </button>
                                  </Tooltip>
                                </div>
                              ) : row?.status === "CLOSED" ? (
                                <button disabled={true}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-p79yt4"
                                    viewBox="0 0 24 24"
                                    focusable="false"
                                    width="18px"
                                    height="18px"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fill="#86878b"
                                      d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"
                                    />
                                  </svg>
                                </button>
                              ) : (
                                <Tooltip content="Delete">
                                  <button
                                    onClick={() =>
                                      handleOpenDeleteRfqPopup(
                                        row?.rfqId,
                                        row?.requesterId,
                                        row?.status
                                      )
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-p79yt4"
                                      viewBox="0 0 24 24"
                                      focusable="false"
                                      width="18px"
                                      height="18px"
                                      aria-hidden="true"
                                    >
                                      <path
                                        fill="#4B5563"
                                        d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"
                                      />
                                    </svg>
                                  </button>
                                </Tooltip>
                              )}
                            </div>
                          </td>
                        )} */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <style>
                {`
          .overflow-x-auto::-webkit-scrollbar {
            height: 6px;
          }

          .overflow-x-auto::-webkit-scrollbar-thumb {
            background-color: #949494; 
          }
        `}
              </style>
              {searchValue === "" && (
                <div className="sticky w-full bottom-0 bg-white left-0">
                  <PaginationSection
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="w-full h-[50vh] flex justify-center items-center">
              {searchValue === "" ? (
                <h6>{(user?.IAM_role === "Manager" && isApprovalRequired && workspaceView === "Pending Approvals") ? "No requirements for approval." : "No results found create some rfqs"}</h6>
              ) : (
                <h6>No results found </h6>
              )}
            </div>
          )}
        </>
      )}
      <StaticPopup
        openModal={openRfqDeletePopup}
        handleClose={() => handleCloseDeleteRfqPopup()}
        header={deleteRfqStatus === "DELETED" ? "Restore RFQ" : "Delete RFQ"}
      >
        {deleteRfqStatus === "DELETED" ? (
          <p>Are you sure to restore this RFQ?</p>
        ) : (
          <p>Are you sure to delete this RFQ?</p>
        )}
        <div className="flex gap-4 mt-auto">
          <button
            className="p-2 px-4 border rounded-lg text-white bg-blue-700 hover:bg-blue-800 font-semibold "
            onClick={handleDeleteRfq}
          >
            {loadingDeleteRfq ? <Loading /> : "Yes"}
          </button>
          <button
            className="p-2 px-4 border rounded-lg font-semibold hover:bg-slate-100 "
            onClick={handleCloseDeleteRfqPopup}
          >
            No
          </button>
        </div>
        {rfqsErrors?.error && (
          <p className="text-xs text-red-700">{rfqsErrors?.errorMassage}</p>
        )}
      </StaticPopup>

      <StaticPopup
        openModal={openPoIdPopup}
        handleClose={closePoIdPopup}
        header={"PO Id"}
        width="md"
        height="auto"
        size="sm"
      >
        <p className="text-xs text-gray-600 my-2 ">{listOfPoIds?.join(", ")}</p>
      </StaticPopup>
    </div>
  );
};

export default RfqsTableSection;
