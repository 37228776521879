import StaticPopup from 'components/StaticPopup';
import StaticPopupNew from 'components/StaticPopupNew';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from "react-html-parser";


const RfqOverview = ({ data }) => {
    const [selectedSpecs, setSelectedSpecs] = useState('');
    const [products, setProducts] = useState([]);
    const [openSpecsPopup, setOpenSpecsPopup] = useState(false);
    const [showMaterial, setShowMaterial] = useState(false);
    const [showContractor, setShowContractor] = useState(false);
    const [showLabour, setShowLabour] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedDetail, setSelectedDetail] = useState(null);

    const togglePopup = (detail) => {
        setSelectedDetail(detail);
        setIsPopupOpen(true);
    };

    const closeSpecsPopup = () => {
        setOpenSpecsPopup(false);
    };

    const handleSpecsButtonClick = (specifications) => {
        if (specifications) {
            setSelectedSpecs(specifications);
            setOpenSpecsPopup(true);
        } else {
            console.log("No specifications available for this product.");
        }
    };

    useEffect(() => {
        if (data) {
            setProducts(data.materials || []);
            const rfqType = data.rfqType;
            setShowMaterial(rfqType === "material");
            setShowContractor(rfqType === "contractor");
            setShowLabour(rfqType === "labour");
        }
    }, [data]);

    const attachmentsData = data?.selectedFilesBase
        ? data.selectedFilesBase.map((item) => {
            const lastIndex = item.Name.lastIndexOf('.'); // Extract file extension
            const path = lastIndex !== -1 ? item.Name.substring(lastIndex + 1) : null;
            return { ...item, Path: path }; // Add the Path to determine file type
        })
        : []

    console.log(data);


    return (
        <div>
            {/* Material Table */}
            {showMaterial && products.length > 0 && (
                <div>
                    <h3 className='font-semibold text-sm my-2'>Material Information</h3>
                    {data?.materials?.some(material => material.name || material.quantity || material.brands.length > 0 || material.specifications) && (
                        <div className='border w-full text-xs  shadow-md rounded-lg'>
                            <div className='grid grid-cols-8 gap-2 p-2 font-medium text-xs text-[#727269] bg-[#DDDDD3]'>
                                <p className='col-span-1'>Sr.No.</p>
                                <p className='col-span-2'>Product</p>
                                <p className='col-span-2'>Quantity</p>
                                <p className='col-span-2'>Brand</p>
                                <p className='text-center'>Specifications</p>
                            </div>
                            <div className='max-h-[200px] overflow-y-auto'>
                                {products.map((product, productIndex) => (
                                    <div key={productIndex} className={`grid grid-cols-8 gap-2 font-normal p-2 ${productIndex % 2 === 0 ? 'bg-[#FBFBF9]' : ''}`}>
                                        <p className='col-span-1 pl-2'>{productIndex + 1}</p>
                                        <p className='col-span-2 truncate'>{product?.name || "N/A"}</p>
                                        <p className='col-span-2 truncate'>{product?.quantity ? `${product.quantity} ${product.unit || ''}` : "N/A"}</p>
                                        <p className='col-span-2 break-words'>{product?.brands.length > 0 ? product.brands.join(", ") : "N/A"}</p>
                                        {product?.specifications?.length > 0 ? (
                                            <button
                                                type="button"
                                                className="col-span-1 mx-auto"
                                                onClick={() => handleSpecsButtonClick(product?.specifications)}
                                            >
                                                <img
                                                    src="https://storagereponeevaydevcdn.blob.core.windows.net/business/comment_create_rfq.svg"
                                                    alt="View Specs"
                                                />
                                            </button>
                                        ) : (
                                            <p>Specifications not provided</p>
                                        )}

                                    </div>
                                ))}

                                <StaticPopup
                                    openModal={openSpecsPopup}
                                    handleClose={closeSpecsPopup}
                                    width={"xl"}
                                    bodyStyle="pb-0 pt-0"
                                    className="px-0"
                                    header={"Specifications"}
                                >
                                    <div className='py-8'>
                                        <p className=''>{selectedSpecs || "N/A"}</p>
                                    </div>
                                </StaticPopup>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {/* Providing & Fixing Section */}
            {showContractor && data?.providingFixingDetails && (
                <div>
                    <h3 className='font-semibold text-sm my-2'>Providing & Fixing</h3>
                    <div className='rounded-lg bg-white space-x-2 text-[#494949] text-xs flex shadow-md p-4'>
                        <p>1.</p>
                        <p dangerouslySetInnerHTML={{ __html: data.providingFixingDetails || "N/A" }}></p>
                    </div>
                </div>
            )}

            {/* Labour Table */}
            {data.labourDetails?.length > 0 && (
                <div>
                    <h3 className="text-sm font-bold my-2">Labour Information</h3>
                    <div className="grid grid-cols-4 text-sm">
                        <div className="font-medium text-xs text-[#727269] bg-[#DDDDD3] p-2">Sr.No.</div>
                        <div className="font-medium text-xs text-[#727269] bg-[#DDDDD3] p-2">Skill</div>
                        <div className="font-medium text-xs text-[#727269] bg-[#DDDDD3] p-2">Quantity</div>
                        <div className="font-medium text-xs text-[#727269] bg-[#DDDDD3] p-2">Work Details</div>

                        {data.labourDetails.map((detail, index) => (
                            <React.Fragment key={index}>
                                <div className="text-[#222222] bg-white border-b text-xs pl-4 py-2">{index + 1}</div>
                                <div className="text-[#222222] bg-white border-b text-xs p-2">{detail.skill || "N/A"}</div>
                                <div className="text-[#727269] bg-white border-b text-xs p-2">{detail.quantity || "N/A"}</div>
                                <div className="bg-white p-2 border-b">
                                    <p
                                        className="w-full border-none text-xs text-[#3D3D3D] truncate"
                                        dangerouslySetInnerHTML={{
                                            __html: detail.remark && detail.remark.length > 100 ? `${detail.remark.slice(0, 100)}...` : detail.remark || "N/A"
                                        }}
                                    ></p>
                                    {/* Show the Read More button only if remark is not "N/A" and longer than 100 characters */}
                                    {detail.remark && detail.remark !== "N/A" && detail.remark.length > 100 && (
                                        <button
                                            onClick={() => togglePopup(detail)}
                                            type="button"
                                            className="text-blue-600 text-xs underline"
                                        >
                                            Read More
                                        </button>
                                    )}
                                </div>


                            </React.Fragment>
                        ))}
                    </div>

                    {/* StaticPopupNew Component */}
                    {isPopupOpen && selectedDetail && (
                        <StaticPopupNew
                            openModal={isPopupOpen}
                            handleClose={() => setIsPopupOpen(false)}
                            width={"lg"}
                            height={80}
                            bodyStyle="pb-0 pt-0 px-0"
                            className="px-0 text-sm"
                            header={"Work Details"}
                        >
                            <p
                                className="w-full border-none max-h-[200px] overflow-y-auto py-2 px-4 text-xs text-[#3D3D3D]"
                                dangerouslySetInnerHTML={{ __html: selectedDetail.remark || "N/A" }}
                            ></p>
                        </StaticPopupNew>
                    )}
                </div>
            )}

            <div className="my-5 hidden xl:block">
                {attachmentsData.length > 0 && (
                    <>
                        <h3 className="font-semibold text-sm hidden xl:block">Attached Documents</h3>

                        {attachmentsData.map((doc, index) => (
                            <div key={index} className="px-2 mt-2 flex w-fit items-center bg-white justify-center shadow-md rounded-full py-1 space-x-2 text-[#434343]">
                                {/* Dynamic icon based on file type */}
                                <img src={`/assets/icons/${doc.Path}.png`} alt={doc.Path} className="h-5 w-5" />
                                {/* Document name now clickable */}
                                <a
                                    href={doc.Content}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download={doc.Name}
                                    className="text-xs text-[#434343] hover:underline"
                                >
                                    {doc.Name}
                                </a>
                            </div>
                        ))}
                    </>
                )}
            </div>

            <div className="my-5 hidden xl:block">
                {data?.paymentTerms?.length > 0 && (
                    <>
                        <h3 className="font-semibold text-sm hidden xl:block pb-2">Payment Terms</h3>
                        {/* payment terms */}
                        {data?.paymentTerms && <tr>
                            <td className='text-xs'>{ReactHtmlParser(data?.paymentTerms)}</td>
                        </tr>}

                    </>
                )}
            </div>
        </div>
    );
}

export default RfqOverview;
