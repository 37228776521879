import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';


const getFormattedUnit = (unit) => {
    switch (unit) {
        case "Sq. Feet(SFT)":
            return "sq. ft.";
        case "Running Meter(RMT)":
            return "rmt";
        case "Sq. Meter":
            return "sq. mtr.";
        case "Numbers(Qty)":
            return "nos.";
        case "Cubic Meters(CUM)":
            return "cum";
        case "Kilograms(Kgs)":
            return "kgs";
        case "Metric Ton(M.Ton)":
            return "m. ton";
        case "Liters":
            return "ltrs";
        case "Bags":
            return "bags";
        case "Cubic Foot(CFT)":
            return "cft";
        default:
            return "";
    }
};

const formatChargeKey = (key) => {
    // Add space before uppercase letters and convert the string to proper case
    return key
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between camelCase words
        .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
};



const FullComparison = () => {
    const location = useLocation();
    const rfqData = location.state?.rfqData;
    const { rfqType, suppliers, otherCharges } = rfqData || {};

    const sortedSuppliers = rfqData.suppliers
        .filter(supplier => supplier?.quotationReceived)
        .sort((a, b) => {
            const rankA = a.vendorQuotations?.rank; // Only keep rank
            const rankB = b.vendorQuotations?.rank;

            // Convert ranks like "L1", "L2" to numerical values for comparison
            const numA = parseInt(rankA.substring(1)); // Extract the number part (e.g., "1" from "L1")
            const numB = parseInt(rankB.substring(1));

            return numA - numB; // Ascending order
        });


    const navigate = useNavigate()

    const getLineItems = () => {
        if (rfqType === "material") {
            return rfqData?.materials?.map(item => ({
                name: item.name,
                quantity: item.quantity,
                unit: item.unit,
            }));
        } else if (rfqType === "labour") {
            return rfqData?.labourDetails.map(item => ({
                name: item.skill,
                quantity: item.quantity,
            }));
        } else if (rfqType === "contractor") {
            return [{ name: ReactHtmlParser(rfqData.providingFixingDetails) }];
        }
        return [];
    };

    const getExtraCharges = () => {
        return Object.keys(otherCharges || {}).filter(charge => otherCharges[charge]);
    };

    const lineItems = getLineItems();
    const extraChargesKeys = getExtraCharges();

    const getVendorQuotationData = (supplier, index) => {
        const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
        let vendorItem = {};

        if (rfqType === "material") {
            vendorItem = vendorQuotation?.materialDetails?.[index] || {};
            console.log("vendorItem", vendorItem);
        } else if (rfqType === "labour") {
            vendorItem = vendorQuotation?.labourItems?.[index] || {};
        } else if (rfqType === "contractor") {
            vendorItem = vendorQuotation?.contractorDetails || {};
        }

        return {
            ...vendorItem,
            subTotal: vendorQuotation?.totalWithoutGst,
            grandTotal: supplier.vendorQuotations?.grandTotalWithGst,
            gstTotal: vendorQuotation?.totalWithGst - vendorQuotation?.totalWithoutGst,
            totalBaseAmount: vendorQuotation?.totalWithoutGst + vendorQuotation?.othersTotalWithoutGst,
            otherCharges: vendorQuotation?.otherCharges || {}
        };
    };

    return (
        <div className='h-screen'>
            <div className='sticky top-0 shadow-md grid grid-cols-5 bg-white w-full z-50 py-3'>
                <div className='px-12 col-span-1'>
                    <button onClick={(() => navigate(-1))} className='bg-[#EDEDE9] px-6 py-2 text-xs'>Back</button>
                </div>
                <div className='w-full col-span-4'>
                    <h3 className='text-sm text-[#484848] font-semibold text-center pb-1'>Full Comparison</h3>
                    <div className='flex space-x-8 w-full text-xs'>
                        <p className=' max-w-[500px]'><span className='text-[#6b7280]'>RFQ Name: </span>{rfqData?.name}</p>
                        <p className=''><span className='text-[#6b7280]'>Project Name: </span> {rfqData?.projectName}</p>
                        <p className=''><span className='text-[#6b7280]'>Location: </span> {rfqData?.location}</p>
                    </div>
                </div>
            </div>

            <div className="overflow-x-auto overflow-y-auto" style={{ height: `calc(100vh - 80px)` }}>
                <style>
                    {`
          .overflow-x-auto::-webkit-scrollbar {
            height: 6px;
          }

          .overflow-x-auto::-webkit-scrollbar-thumb {
            background-color: #949494; 
          }
        `}
                </style>
                <table className="max-w-[400px]">
                    <thead>
                        {/* Header for Vendor Names */}
                        <tr>
                            <td className="sticky left-0 z-0 bg-white min-w-[200px]"></td>
                            {sortedSuppliers?.map((supplier, idx) => (
                                <th key={idx} className="py-4 px-2 m-2 min-w-[260px] text-left">
                                    <div className="flex items-center justify-center">
                                        {/* <hr className="border-t border-[#070707] flex-grow" /> */}
                                        <span className="mx-2 text-center font-bold text-xs">
                                            {supplier.businessName}<br /> (Rank: {supplier.vendorQuotations?.rank} - Rs. {new Intl.NumberFormat('en-IN').format(supplier.vendorQuotations?.grandTotalWithoutGst.toFixed(2))}<span className="text-[10px] font-normal"> excl. GST</span>)
                                        </span>

                                        {/* <hr className="border-t border-[#070707] flex-grow" /> */}
                                    </div>
                                </th>
                            ))}
                        </tr>

                        {/* Line Item Headers */}
                        <tr>
                            <th className="sticky mx-2 left-0 bg-white px-4 min-w-[300px] text-[10px] font-normal text-left pb-2 text-gray-500">
                                <div className='grid grid-cols-7 gap-2 uppercase'>
                                    <p className='text-[10px]'>Sr.</p>
                                    {rfqType === "material" && (
                                        <>
                                            <div className='col-span-4'><p className="text-[#707070] text-[10px]">Material</p></div>
                                            <div className='col-span-2'><p className='text-[10px]'>Qty</p></div>
                                        </>
                                    )}

                                    {rfqType === "labour" && (
                                        <>
                                            <div className='col-span-3'><p className="text-[#707070] text-[10px]">Skill</p></div>
                                            <div className='col-span-3'><p className='text-[10px]'>Qty</p></div>
                                        </>
                                    )}

                                    {rfqType === "contractor" && (
                                        <>
                                            <div className='col-span-6'><p className='text-[10px]'>Work Details</p></div>
                                        </>
                                    )}
                                </div>
                            </th>
                            {sortedSuppliers?.map((_, idx) => (
                                <td key={idx} className="px-2">
                                    <div className='grid grid-cols-14 text-[10px] min-w-[260px] py-1 uppercase text-gray-500'>
                                        {rfqType === "material" && (
                                            <>
                                                <p className='col-span-3 text-right px-1 border overflow-x-auto border-white'>MRP</p>
                                                <p className='col-span-2 text-right px-1 border border-white'>Disc</p>
                                                <p className='col-span-3 text-right px-1 border border-white'>Rate</p>
                                                {/* <p className='col-span-3 border border-white'>Delivery Date</p> */}
                                                <p className='col-span-4 text-right px-1 border border-white'>Basic Amt</p>
                                                <p className='col-span-2 text-right border px-1 border-white'>GST</p>

                                            </>
                                        )}
                                        {rfqType === "labour" && (
                                            <>
                                                <p className='col-span-4 text-right border border-white'>Labour Rate</p>
                                                <p className='col-span-3 text-right border border-white'>Qty</p>
                                                <p className='col-span-4 text-right border border-white'>Total</p>
                                                <p className='col-span-3 text-right border border-white'>GST (%)</p>
                                                {/* <p className='col-span-3 text-center border border-white'>Start Date</p> */}
                                            </>
                                        )}
                                        {rfqType === "contractor" && (
                                            <>
                                                <p className='col-span-5 text-right border border-white'>Base Price</p>
                                                {/* <p className='col-span-3 text-right border border-white'>Start Date</p> */}
                                                <p className='col-span-5 text-right border border-white'>Total</p>
                                                <p className='col-span-4 text-right border border-white'>GST (%)</p>
                                            </>
                                        )}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {/* Line Items Mapping */}
                        {lineItems.map((item, index) => (
                            <tr key={index}>
                                <td className={`z-20 sticky left-0 px-4 py-1 my-2 text-[10px] border border-gray-300 ${index % 2 === 0 ? 'bg-blue-50' : 'bg-white'}`}>
                                    <div className='grid grid-cols-7 gap-2'>
                                        <p className='border-r text-[10px]'> {index + 1} </p>
                                        {rfqType === "material" && (
                                            <>
                                                <div className='col-span-4 border-r'>
                                                    <p className="font-medium text-[10px] text-[#707070]">{item.name}</p>
                                                </div>
                                                <div className='col-span-2'>
                                                    {item.quantity && <p className='text-[10px]'>{item.quantity} {getFormattedUnit(item.unit) || ''}</p>}
                                                </div>
                                            </>
                                        )}

                                        {rfqType === "labour" && (
                                            <>
                                                <div className='col-span-3 border-r'>
                                                    <p className="font-medium text-[10px] text-[#707070]">{item.name}</p>
                                                </div>
                                                <div className='col-span-3'>
                                                    {item.quantity && <p className='text-[10px]'>{item.quantity}</p>}
                                                </div>
                                            </>
                                        )}

                                        {rfqType === "contractor" && (
                                            <div className='col-span-6'>
                                                <p className="font-medium text-[10px] text-[#707070]">{item.name}</p>
                                            </div>
                                        )}
                                    </div>
                                </td>
                                {sortedSuppliers?.map((supplier, vendorIdx) => {
                                    const vendorData = getVendorQuotationData(supplier, index);
                                    return (
                                        <td key={vendorIdx} className={`px-2 ${vendorIdx < sortedSuppliers.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}>
                                            <div className={`text-[10px] border grid grid-cols-14 h-full ${index % 2 === 0 ? 'bg-blue-50' : ''}`}>
                                                {rfqType === "material" && (
                                                    <>
                                                        <p className=' border-r text-[10px] px-1 py-1 text-right h-full col-span-3'>{vendorData.basePrice || 0}</p>
                                                        <p className=' border-r text-[10px] px-1 py-1 text-right col-span-2'>{vendorData.discount || 0}%</p>
                                                        <p className='border-r text-[10px] px-2 py-1 text-right col-span-3'>
                                                            {vendorData?.basePrice && vendorData?.discount !== undefined
                                                                ? new Intl.NumberFormat('en-IN').format(vendorData.basePrice - (vendorData.basePrice * vendorData.discount / 100))
                                                                : 0}
                                                        </p>
                                                        {/* <p className="text-gray-500  border-r col-span-3 px-1 py-1 text-right">{vendorData.deliveryDate ? new Date(vendorData.deliveryDate).toLocaleDateString('en-GB') : "-"}</p> */}
                                                        <p className=" text-[10px] text-right  border-r col-span-4 px-1 py-1">{vendorData?.priceWithoutGst > 0 ? new Intl.NumberFormat('en-IN').format(vendorData?.priceWithoutGst?.toFixed(2)) : 0}</p>
                                                        <p className=' border-r text-[10px] px-1 py-1 text-right col-span-2'>{vendorData.gst || 0}%</p>
                                                    </>
                                                )}
                                                {rfqType === "labour" && (
                                                    <>
                                                        <p className='border-r text-[10px] px-2 py-1 text-right col-span-4'>{vendorData.labourRate || 0}</p>
                                                        <p className='border-r text-[10px] px-2 py-1 text-right col-span-3'>{vendorData.quantity || 0}</p>
                                                        <p className="text-right border-r text-[10px] col-span-4 px-1 py-1">{new Intl.NumberFormat('en-IN').format(vendorData?.totalAmount?.toFixed(2)) || ''}</p>
                                                        <p className='border-r px-2 py-1 text-[10px] text-right col-span-3'>{vendorData.gst || 0}%</p>
                                                        {/* <p className="text-gray-500 border-r col-span-3 px-1 py-1 text-right">{vendorData.workStartDate ? new Date(vendorData.workStartDate).toLocaleDateString('en-GB') : "-"}</p> */}
                                                    </>
                                                )}
                                                {rfqType === "contractor" && (
                                                    <>
                                                        <p className='border-r text-[10px] px-2 py-1 text-right col-span-5'>{vendorData.basePrice || 0}</p>
                                                        {/* <p className="text-gray-500 border-r col-span-3 px-1 py-1 text-right">{vendorData.workStartDate ? new Date(vendorData.workStartDate).toLocaleDateString('en-GB') : "-"}</p> */}
                                                        <p className="text-right text-[10px] border-r col-span-5 px-1 py-1">{new Intl.NumberFormat('en-IN').format(vendorData?.netPrice?.toFixed(2)) || 0}</p>
                                                        <p className='border-r text-[10px] px-2 py-1 text-right col-span-4'>{vendorData.gst || 0}%</p>
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}

                        {/* Sub Total Row */}
                        <tr>
                            <td className="sticky left-0 bg-white shadow-md pt-3 pl-4 min-w-[200px] text-[10px] font-semibold">Sub Total</td>
                            {sortedSuppliers?.map((supplier, idx) => {
                                const vendorData = getVendorQuotationData(supplier, 0);
                                return (
                                    <td key={idx} className={`w-full mt-3 mx-2 ${idx < sortedSuppliers.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}>
                                        {/* <div className="border-t border-t-[#004491] mx-[10px] mt-2"> */}
                                        <p className="font-bold mx-[10px] text-xs text-right mt-1 text-[#004491]">
                                            <span className='bg-[#E7EAEF] text-[10px] px-3 py-1'>Rs. {new Intl.NumberFormat('en-IN').format(vendorData.subTotal)}</span> {/* Adjusted padding */}
                                        </p>
                                        {/* </div> */}
                                    </td>


                                );
                            })}
                        </tr>

                        {/* Extra Charges Section */}
                        {extraChargesKeys && extraChargesKeys.length > 0 && (
                            <>
                                <tr>
                                    <td className="sticky border-t left-0 text-[#5D5D5D] font-semibold text-[10px] bg-white px-4 pt-2 ">Extra Charges</td>
                                    {sortedSuppliers?.map((_, idx) => (
                                        <td key={idx} className={`p-2 border-t ${idx < sortedSuppliers.length - 1 ? 'border-r-2 border-r-[#707070]' : ''}`}></td>
                                    ))}
                                </tr>

                                <tr>
                                    <td className="sticky left-0 bg-white px-4"></td>
                                    {sortedSuppliers?.map((_, idx) => (
                                        <td key={idx} className={`px-2 ${idx < sortedSuppliers?.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}>
                                            <div className="grid grid-cols-3 uppercase pl-2 text-[10px] mb-2 ml-auto text-right w-1/2 text-gray-500">
                                                <p className='col-span-2 text-right'>Basic Amt.</p>
                                                {/* <p>Total Amount (Rs.)</p> */}
                                                <p>GST</p>
                                            </div>
                                        </td>
                                    ))}
                                </tr>

                                {extraChargesKeys.map((chargeKey, index) => (
                                    <tr key={index}>
                                        <td className="sticky left-0 bg-white shadow-md min-w-[200px] px-4">
                                            <div className="flex items-center text-[10px] text-center">
                                                <p>{formatChargeKey(chargeKey)}</p>
                                            </div>
                                        </td>
                                        {sortedSuppliers?.map((supplier, vendorIdx) => {
                                            const vendorData = getVendorQuotationData(supplier, 0);
                                            return (
                                                <td key={vendorIdx} className={`px-2 ${vendorIdx < sortedSuppliers.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}>
                                                    <div className="text-[10px] grid grid-cols-3 text-right border border-gray-200 w-1/2 ml-auto">
                                                        <p className='col-span-2 border-r p-1'>{vendorData.otherCharges[chargeKey]?.mrp || 0}</p>
                                                        <p className='border-r p-1'>{vendorData.otherCharges[chargeKey]?.gst || 0}%</p>
                                                        {/* <p className="text-gray-500 border-r p-2">{vendorData.otherCharges[chargeKey]?.totalCost || 0}</p> */}
                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </>
                        )}


                        {/* Grand Total Row */}
                        {/* <tr>
                            <td className="sticky left-0 text-xs px-4 bg-white shadow-md min-w-[200px] font-semibold">Grand Total</td>
                            {sortedSuppliers?.map((supplier, idx) => {
                                const vendorData = getVendorQuotationData(supplier, 0);
                                return (
                                    <td key={idx} className={`w-full mt-3 mx-2 ${idx < sortedSuppliers.length - 1 ? 'border-r-2 border-[#707070]' : ''}`}>
                                        <div className="border-t border-t-[#004491] mx-[10px] mt-2">
                                            <p className="font-bold text-xs text-right mt-[7px] text-[#004491]">
                                                <span className='bg-[#E0F0EE] px-6 py-2'>Rs. {new Intl.NumberFormat('en-IN').format(vendorData.grandTotal)}</span>
                                            </p>
                                        </div>
                                    </td>
                                );
                            })}
                        </tr> */}

                        {/* Sub Total Row */}
                        <tr>
                            <td className="sticky left-0 bg-white border-b shadow-md pt-3 pl-4 min-w-[200px] text-[10px] font-semibold">Total Basic Amount</td>
                            {sortedSuppliers?.map((supplier, idx) => {
                                const vendorData = getVendorQuotationData(supplier, 0);
                                return (
                                    <td key={idx} className={`w-full border-b mt-3  ${idx < sortedSuppliers.length - 1 ? 'border-r-2 border-r-[#707070]' : ''}`}>
                                        {/* <div className="border-t border-t-[#004491] mx-[10px] mt-2"> */}
                                        <p className="font-bold mx-[8px] text-xs text-right mt-1 text-[#004491]">
                                            <span className='bg-[#E7EAEF] text-[10px] px-3 py-1'>Rs. {new Intl.NumberFormat('en-IN').format(vendorData.totalBaseAmount)}</span> {/* Adjusted padding */}
                                        </p>
                                        {/* </div> */}
                                    </td>


                                );
                            })}
                        </tr>

                        {/* GST Total Row */}
                        <tr>
                            <td className="sticky left-0 text-[10px] px-4 border-b bg-white shadow-md min-w-[200px]">GST Total</td>
                            {sortedSuppliers?.map((supplier, idx) => {
                                const vendorData = getVendorQuotationData(supplier, 0);
                                return (
                                    <td key={idx} className={`w-full border-b mx-2 ${idx < sortedSuppliers.length - 1 ? 'border-r-2 border-r-[#707070]' : ''}`}>
                                        {/* <div className="border-t border-t-[#004491] mx-[10px] mt-2"> */}
                                        <p className=" mx-2 text-[10px] text-right mt-[10px]">
                                            <span className=' px-3 py-1'>Rs. {new Intl.NumberFormat('en-IN').format(vendorData.gstTotal)}</span> {/* Adjusted padding */}
                                        </p>
                                        {/* </div> */}
                                    </td>
                                );
                            })}
                        </tr>

                        {/* Grand Total Row */}
                        <tr>
                            <td className="sticky left-0 text-[10px] border-b px-4 pb-8 bg-white shadow-md min-w-[200px] font-semibold">Total Quotation Value (inc. GST)</td>
                            {sortedSuppliers?.map((supplier, idx) => {
                                const vendorData = getVendorQuotationData(supplier, 0);
                                return (
                                    <td key={idx} className={`w-full pb-8 border-b mx-2 ${idx < sortedSuppliers.length - 1 ? 'border-r-2 border-r-[#707070]' : ''}`}>
                                        <div className="mx-[10px]">
                                            <p className="font-bold text-[10px] text-right mt-[7px] text-[#004491]">
                                                <span className='bg-[#E0F0EE] px-3 py-1'>Rs. {new Intl.NumberFormat('en-IN').format(vendorData.grandTotal)}</span> {/* Adjusted padding */}
                                            </p>
                                        </div>
                                    </td>
                                );
                            })}
                        </tr>

                        <tr>
                            <td className="sticky left-0 text-[10px] py-2 px-4 border-t bg-white shadow-md min-w-[200px] font-semibold">
                                Difference from Lowest
                            </td>
                            {sortedSuppliers.map((supplier, idx) => {
                                const vendorQuotation = supplier.vendorQuotations;

                                // Use the appropriate grand total based on withGst state
                                const grandTotal = vendorQuotation.grandTotalWithGst


                                // Calculate L1 supplier data
                                const l1Supplier = sortedSuppliers[0]; // Assuming sortedSuppliers is sorted by total
                                const l1Total = l1Supplier ? l1Supplier.vendorQuotations.grandTotalWithGst : 0;

                                // Calculate deviation
                                const deviation = l1Total ? grandTotal - l1Total : 0;
                                const deviationPercentage = l1Total ? ((deviation / l1Total) * 100).toFixed(2) : 0;
                                // map the data here
                                return (
                                    <td key={idx} className={`border-t ${idx < sortedSuppliers.length - 1 ? 'border-r-2 border-r-[#707070]' : ''}`}>
                                        {idx === 0 ? (
                                            <p className='text-[10px] text-right py-2 mr-2'>Lowest Quote</p>
                                        ) : (
                                            <p className='text-[10px] text-right py-2 mr-2'>
                                                Rs. {new Intl.NumberFormat('en-IN').format(deviation)} ({deviationPercentage}%)
                                            </p>
                                        )}
                                    </td>
                                )
                            })}
                        </tr>

                        <tr>
                            <td className="sticky left-0 border-t px-4 bg-white w-[300px] pb-4" style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)', }}>
                                <p className='w-full text-[10px] pt-[5px] font-bold text-[#2D2D11]'>Negotiation Outcome </p>
                                <p className='w-full text-[10px] text-[grey]'>Difference between the last & first quote</p>
                            </td>

                            {sortedSuppliers?.map((supplier, index) => {
                                const vendorQuotation = supplier?.vendorQuotations?.quotationSets;

                                // Use the appropriate grand total based on withGst state
                                const firstQuotation = vendorQuotation?.[0]?.totalWithoutGst + vendorQuotation?.[0]?.othersTotalWithoutGst || 0;

                                const lastQuotation = vendorQuotation?.slice(-1)[0]?.totalWithoutGst + vendorQuotation?.slice(-1)[0]?.othersTotalWithoutGst || 0;

                                // Calculate difference and percentage
                                const difference = firstQuotation - lastQuotation;
                                const negotiationPercentage =
                                    firstQuotation > 0 ? ((difference / firstQuotation) * 100).toFixed(2) : 0;

                                return (
                                    <td
                                        key={index}
                                        className={`w-[300px] border-t px-4 ${index < sortedSuppliers?.length - 1 ? "border-r-2 border-r-[#707070]" : ""
                                            }`}
                                    >
                                        {firstQuotation === lastQuotation ? (
                                            <p className="text-[10px] text-right pt-2 pb-4">Not Updated</p>
                                        ) : (
                                            <p className="text-[10px] text-right pt-2 pb-4">
                                                Rs. {new Intl.NumberFormat('en-IN').format(difference)} ({negotiationPercentage}%)
                                            </p>
                                        )}
                                    </td>
                                );
                            })}
                        </tr>

                        <tr>
                            <td className="sticky left-0 px-6 bg-white w-[300px] border-t" style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)', }}>
                                <p className='w-full text-[10px] py-[5px] font-bold text-[#2D2D11]'>Vendor Notes</p>
                                <hr />
                            </td>
                            {sortedSuppliers?.map((supplier, index) => {
                                const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
                                return (
                                    <td className={`w-[300px] px-5 py-2 border-t ${index < rfqData?.suppliers?.length - 1 ? 'border-r-2 border-r-[#707070]' : ''}`}>
                                        {/* <p className='text-xs py-2 font-bold'>Counter Offer: <span className='font-normal'> {vendor.paymentTerm}</span></p> */}
                                        <p className='text-[10px] text-right py-2 '> {vendorQuotation?.notes || 'N/A'}</p>

                                    </td>
                                );
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default FullComparison;
