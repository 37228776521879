import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Typography } from '@mui/material';
import { registerUserWithMobile, sendOneTimeLoginLink, signout } from 'actions/authActions'; // Add action for sending email link
import LeadsMobileVerification from 'views/Auth/SignInOTPVerify/LeadsMobileVerification';

function QuotationFormRouter() {
    const { rfqType, rfqId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const mobileNumber = searchParams.get('mobile');
    const userEmail = searchParams.get('email');  // Get email from URL params

    // Redux state
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const userProfile = auth.user;
    const leads = useSelector((state) => state.lead.leads);
    // Local state
    const [rfqData, setRfqData] = useState(null);
    const [openOtpVerifyModal, setOpenOtpVerifyModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loginMessage, setLoginMessage] = useState('');

    // User authentication and OTP verification logic
    useEffect(() => {
        if (userEmail) {
            // Case where we have userEmail from the URL
            if (userProfile) {
                // Check if the logged-in user's email matches the email from the URL
                if (userEmail !== userProfile.email) {
                    console.log("Email mismatch: Logging out current user and sending a login link to the new email");
                    // Logout the current user
                    dispatch(signout());

                    // Send login link to the new email
                    setLoading(true);
                    dispatch(sendOneTimeLoginLink(userEmail,rfqType,rfqId, () => {
                        setLoading(false);
                        setLoginMessage("We have sent a one-time login link to your email. Kindly check it to access the RFQ.");
                    }));
                } else {
                    // User is logged in with the same email, proceed normally
                    console.log("User is already logged in with the same email.");
                }
            } else {
                // No user is logged in, send login link to the new email
                console.log("Sending one-time login link to email:", userEmail);
                setLoading(true);
                dispatch(sendOneTimeLoginLink(userEmail,rfqType,rfqId, (response) => {
                    setLoading(false);
                    if (response.error) {
                        // Show error message if there was an issue sending the login link
                        setLoginMessage("Failed to send the one-time login link to your email. Please contact support at support@neevay.com or call us at +91-9503339814.");
                    } else {
                        setLoginMessage(`You are currently not logged in to Neevay Platform. Don’t worry ! </br> </br> We have sent you One - Time Login link to your registered email address <b>${userEmail}</b>.Kindly login using the link to view and quote your best price for this requirement.We have sent a one - time login link to your email.Kindly check it to access the RFQ.`);
                    }
                }));
            }
        } else if (!userProfile && mobileNumber) {
            // Case where we don't have an email and mobile number is provided
            dispatch(registerUserWithMobile("", mobileNumber, () => {
                setLoading(false);
                setOpenOtpVerifyModal(true); // Open OTP modal if mobile number provided
            }));
        }
    }, [userProfile, userEmail, mobileNumber, dispatch]);

    // Handle form navigation and rendering based on RFQ Type
    useEffect(() => {
        if (leads && leads.length > 0) {
            const foundRfqData = leads.find(rfq => rfq.rfqId === Number(rfqId));
            setRfqData(foundRfqData);
        }
    }, [leads, rfqId]);

    // Handle successful user login after clicking on the one-time login link
    useEffect(() => {
        if (userProfile && (mobileNumber || userEmail)) {
            const newUrl = location.pathname;
            navigate(newUrl, { replace: true });
        }
    }, [userProfile, mobileNumber, userEmail, navigate, location.pathname]);

    if (loading) {
        return (
            <Grid container alignItems="center" justifyContent="center" minHeight="calc(100vh - 66px)" direction="column">
                <CircularProgress style={{ marginBottom: '10px' }} />
                <Typography variant="h6">
                    We are processing your request. Please wait...
                </Typography>
            </Grid>
        );
    }

    if (openOtpVerifyModal) {
        return (
            <LeadsMobileVerification
                isWhatsappNo={false}
                setOtpPopUp={setOpenOtpVerifyModal}
                setOpenLogInModal={setOpenOtpVerifyModal}
                mobile={mobileNumber}
            />
        );
    }

    // Show message when login link is sent to email
    if (loginMessage) {
        return (
            <Grid container alignItems="center" justifyContent="center" minHeight="calc(100vh - 66px)" direction="column">
                <Typography align="center" dangerouslySetInnerHTML={{ __html: loginMessage }} />
            </Grid>
        );
    }

    if (!leads || leads.length === 0) {
        return (
            <div className="flex flex-col justify-center items-center h-screen">
                <div className="animate-spin rounded-full border-2 mb-2 border-t-2 border-orange-500 border-t-gray-200 h-8 w-8"></div>
                <p className="text-sm font-medium">Fetching Leads...</p>
            </div>
        );
    }

    if (!rfqData) {
        return <div className="flex flex-col justify-center items-center h-screen">You Don't have access to this RFQ, kindly contact us at support@neevay.com or call us at +91-9503339814.</div>;
    }

    // Render the appropriate form based on rfqType
    switch (rfqType) {
        case 'material':
            navigate(`/material-form?rfqId=${rfqData?.rfqId}&rfqType=${rfqData?.rfqType}`, { state: { rfqData: rfqData, isEdit: false, supplierId: rfqData?.suppliers[0]?.supplierId } })
            break;
        case 'labour':
            navigate(`/labour-form?rfqId=${rfqData?.rfqId}&rfqType=${rfqData?.rfqType}`, { state: { rfqData: rfqData, isEdit: false, supplierId: rfqData?.suppliers[0]?.supplierId } })
            break;
        case 'contractor':
            navigate(`/providing-form?rfqId=${rfqData?.rfqId}&rfqType=${rfqData?.rfqType}`, { state: { rfqData: rfqData, isEdit: false, supplierId: rfqData?.suppliers[0]?.supplierId } })
            break;
        default:
            return <div>Invalid RFQ Type</div>;
    }
}

export default QuotationFormRouter;
