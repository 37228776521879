import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";


// Define styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        padding: 50,
    },
    section: {
        // marginBottom: 20,
    },
    mainHeader: {
        fontSize: 12,
        fontWeight: "bold",
        marginBottom: "30px",
        textAlign: "center",
        textDecoration: "underline",
        fontFamily: "Helvetica-Bold"
    },
    overviewHeader: {
        fontSize: 12,
        marginBottom: 20,
        fontWeight: "bold",
        fontFamily: "Helvetica-Bold"
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        marginTop: 20,
        fontWeight: "bold",
        fontFamily: "Helvetica-Bold"
    },
    topVendorsHeader: {
        fontSize: 12,
        marginBottom: 10,
        marginTop: 10,
        fontWeight: "bold",
        // textAlign: "center",
        fontFamily: "Helvetica-Bold"
    },
    text: {
        fontSize: 10,
        marginTop: 5,
        marginBottom: 5,
        // marginBottom: 5,
    },
    table: {
        display: "table",
        width: "100%",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#808080",
        borderBottom: 0,
        marginBottom: 10,
    },
    tableRow: {
        flexDirection: "row",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderColor: "#808080"
    },
    tableCell: {
        padding: 5,
        fontSize: 10,
        // flex: 1, 
        borderRightWidth: 1,
        borderRightStyle: "solid",
        borderColor: "#808080"
    },
    tableCellLast: {
        borderRightWidth: 0, // Removes the border for the last cell
    },
    bold: {
        fontWeight: "bold",
    },
    horizontalLine: {
        height: 1,
        backgroundColor: "#07595f",
    },
});

const stripHtml = (html) => {
    if (!html) return "N/A";
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "N/A";
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    return date.toLocaleString('en-US', options);
};

const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const PDFDocument = ({ rfqData }) => {
    // Helper Functions
    const getAwardedSupplier = () =>
        rfqData?.rfq?.suppliers?.find((supplier) => supplier?.isAwarded);

    const topVendors = rfqData?.rfq?.suppliers
        ?.filter((supplier) => supplier?.vendorQuotations?.rank)
        ?.sort(
            (a, b) =>
                parseInt(a.vendorQuotations.rank.replace("L", "")) -
                parseInt(b.vendorQuotations.rank.replace("L", ""))
        )
        ?.slice(0, 3);

    return (
        <Document>
            {/* Page 1: Requirement Overview, Closure Details, Saving Details */}
            <Page size="A4" style={styles.page} orientation="landscape">
                <Text style={[styles.mainHeader]}>
                    Requirement Summary Document
                </Text>
                {/* Requirement Overview */}
                <View style={styles.section}>
                    <Text style={styles.overviewHeader}>Requirement Overview</Text>
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            flexWrap: "wrap", // Allow text to wrap inside each section
                            wordBreak: "break-word", // Handle long words that might overflow
                        }}
                    >
                        {/* Left section */}
                        <View style={{ flex: 6, marginRight: 10 }}>
                            <View style={{ flexDirection: "row", flexWrap: "wrap", wordBreak: "break-word" }}>
                                <Text style={[styles.text, { fontFamily: "Helvetica" }]}>Requirement: </Text>
                                <Text style={[styles.text, { fontFamily: "Helvetica-Bold", fontWeight: "bold" }]}>
                                    {rfqData?.rfq?.name || "N/A"}
                                </Text>
                            </View>

                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.text, { fontFamily: "Helvetica" }]}>Requirement Type: </Text>
                                <Text style={[styles.text, { fontFamily: "Helvetica-Bold", fontWeight: "bold" }]}>
                                    {capitalizeFirstLetter(rfqData?.rfq?.rfqType) || "N/A"}
                                </Text>
                            </View>

                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.text, { fontFamily: "Helvetica" }]}>Project Name: </Text>
                                <Text style={[styles.text, { fontFamily: "Helvetica-Bold", fontWeight: "bold" }]}>
                                    {rfqData?.rfq?.projectName || "N/A"}
                                </Text>
                            </View>

                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.text, { fontFamily: "Helvetica" }]}>Project Location: </Text>
                                <Text style={[styles.text, { fontFamily: "Helvetica-Bold", fontWeight: "bold" }]}>
                                    {rfqData?.rfq?.location || "N/A"}
                                </Text>
                            </View>

                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.text, { fontFamily: "Helvetica" }]}>Total Vendors Invited: </Text>
                                <Text style={[styles.text, { fontFamily: "Helvetica-Bold", fontWeight: "bold" }]}>
                                    {rfqData?.rfq?.suppliers?.length || 0}
                                </Text>
                            </View>

                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.text, { fontFamily: "Helvetica" }]}>Approved By: </Text>
                                <Text style={[styles.text, { fontFamily: "Helvetica-Bold", fontWeight: "bold" }]}>
                                    {rfqData?.rfq?.rfqClosingDetails?.name || "N/A"}
                                </Text>
                            </View>
                        </View>

                        {/* Right section */}
                        <View style={{ flex: 4, marginLeft: 10 }}>
                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.text, { fontFamily: "Helvetica" }]}>RFQ ID: </Text>
                                <Text style={[styles.text, { fontFamily: "Helvetica-Bold", fontWeight: "bold" }]}>
                                    {rfqData?.rfq?.rfqId || "N/A"}
                                </Text>
                            </View>

                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.text, { fontFamily: "Helvetica" }]}>Created By: </Text>
                                <Text style={[styles.text, { fontFamily: "Helvetica-Bold", fontWeight: "bold" }]}>
                                    {rfqData?.rfq?.requesterName || "N/A"}
                                </Text>
                            </View>

                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.text, { fontFamily: "Helvetica" }]}>Created At: </Text>
                                <Text style={[styles.text, { fontFamily: "Helvetica-Bold", fontWeight: "bold" }]}>
                                    {new Date(rfqData?.rfq?.createdAt).toLocaleDateString('en-GB') || "N/A"}
                                </Text>
                            </View>

                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.text, { fontFamily: "Helvetica" }]}>Status: </Text>
                                <Text style={[styles.text, { fontFamily: "Helvetica-Bold", fontWeight: "bold" }]}>
                                    {rfqData?.rfq?.status || "N/A"}
                                </Text>
                            </View>

                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.text, { fontFamily: "Helvetica" }]}>Closed At: </Text>
                                <Text style={[styles.text, { fontFamily: "Helvetica-Bold", fontWeight: "bold" }]}>
                                    {rfqData?.rfq?.rfqClosingDetails ? formatDate(rfqData?.rfq?.rfqClosingDetails?.date) : "N/A"}
                                </Text>
                            </View>

                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.text, { fontFamily: "Helvetica" }]}>Approved at: </Text>
                                <Text style={[styles.text, { fontFamily: "Helvetica-Bold", fontWeight: "bold" }]}>
                                    {rfqData?.rfq?.rfqClosingDetails ? formatDate(rfqData?.rfq?.rfqClosingDetails?.date) : "N/A"}
                                </Text>
                            </View>
                        </View>
                    </View>

                    <Text style={styles.text}>
                        Payment Terms:{" "}
                        <Text style={{ fontFamily: "Helvetica-Bold", fontWeight: "bold" }}>
                            {stripHtml(rfqData?.rfq?.paymentTerms) || "N/A"}
                        </Text>
                    </Text>
                </View>


                {/* Closure Details */}
                {rfqData?.rfq?.status === "CLOSED" && <View style={styles.section}>
                    <Text style={styles.header}>Closure Details</Text>
                    {getAwardedSupplier() ? (
                        <>
                            <Text style={styles.text}>
                                Vendor Name (Rank):{" "}
                                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                                    {getAwardedSupplier()?.businessName} (
                                    {getAwardedSupplier()?.vendorQuotations?.rank})

                                </Text>
                            </Text>
                            <Text style={styles.text}>
                                Amount (excl. GST):{" "}
                                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                                    Rs {new Intl.NumberFormat('en-IN').format(getAwardedSupplier()?.vendorQuotations?.grandTotalWithoutGst) || "N/A"}

                                </Text>
                            </Text>
                            <Text style={styles.text}>
                                Amount (incl. GST):{" "}
                                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                                    Rs {new Intl.NumberFormat('en-IN').format(getAwardedSupplier()?.vendorQuotations?.grandTotalWithGst) || "N/A"}

                                </Text>
                            </Text>
                        </>
                    ) : (
                        <Text style={styles.text}>{`No closure details available as RFQ is in the ${rfqData?.rfq?.status} state.`}</Text>
                    )}
                </View>}

                {/* Saving Details */}
                {rfqData?.rfq?.status === "CLOSED" && (
                    <View style={styles.section}>
                        <Text style={styles.header}>RFQ Saving Amount:</Text>
                        <Text style={{ fontSize: 8, fontStyle: "italic", color: "gray", marginTop: -17 }}>
                            Note: RFQ saving is the difference between the final closure amount and the lowest quotation received in the first round.
                        </Text>
                        <Text style={styles.text}>
                            {(() => {
                                const getMinimumQuotation = () => {
                                    if (!rfqData?.rfq?.suppliers) return 0;

                                    const suppliersWithQuotes = rfqData.rfq.suppliers.filter(
                                        (supplier) => supplier.quotationReceived
                                    );

                                    const supplierQuotations = suppliersWithQuotes.map((supplier) => {
                                        const firstQuotationSet = supplier.vendorQuotations?.quotationSets?.[0];
                                        if (firstQuotationSet) {
                                            const totalWithoutGst = firstQuotationSet.totalWithoutGst || 0;
                                            const othersTotalWithoutGst = firstQuotationSet.othersTotalWithoutGst || 0;
                                            return totalWithoutGst + othersTotalWithoutGst;
                                        }
                                        return Infinity;
                                    });

                                    const minQuotation = Math.min(...supplierQuotations);
                                    return minQuotation === Infinity ? 0 : minQuotation;
                                };

                                const calculateSavings = () => {
                                    const awardedSuppliers = rfqData?.rfq?.suppliers?.filter((supplier) => supplier.isAwarded);
                                    const totalAwardedAmount = awardedSuppliers.reduce((sum, supplier) => {
                                        const awardedAmount = Number(supplier.closingDetails?.[0]?.amount) || 0;
                                        return sum + awardedAmount;
                                    }, 0);

                                    const minQuotation = getMinimumQuotation();
                                    const savingsAmount = minQuotation -totalAwardedAmount;

                                    // Return 0 if savingsAmount is negative, otherwise return the actual savingsAmount
                                    return savingsAmount < 0 ? 0 : savingsAmount;
                                };

                                const savings = calculateSavings();
                                return savings > 0
                                    ? `Saved Amount (excl. GST): Rs. ${new Intl.NumberFormat("en-IN").format(savings)}`
                                    : "0";
                            })()}
                        </Text>
                    </View>
                )}


            </Page>

            {/* Page 2: Top Vendors */}
            <Page size="A4" style={styles.page} orientation="landscape">
                <View style={styles.section}>
                    <View style={styles.horizontalLine}></View>
                    <Text style={styles.topVendorsHeader}>Top 3 Vendors:</Text>
                    <View style={styles.horizontalLine}></View>
                    {topVendors?.map((vendor, index) => (
                        <View key={index} style={[styles.section, { paddingTop: "10px" }]}>
                            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Text style={[styles.text, styles.bold]}>
                                    Vendor Name:
                                    <Text style={[styles.text, { fontFamily: "Helvetica-Bold", fontWeight: "bold" }]}>
                                        {vendor.businessName}
                                    </Text>
                                </Text>
                                <Text style={[styles.text, { marginRight: "130px", fontFamily: "Helvetica-Bold" }]}>
                                    Rank {vendor?.vendorQuotations?.rank || "N/A"}
                                </Text>
                            </View>

                            <Text style={styles.text}>
                                Quotation Amount (excl. GST):{" "}
                                <Text style={{ fontFamily: "Helvetica-Bold", fontWeight: "bold" }}>
                                    Rs {new Intl.NumberFormat('en-IN').format(vendor?.vendorQuotations?.grandTotalWithoutGst) || "N/A"}
                                </Text>
                            </Text>

                            <Text style={styles.text}>
                                Payment Terms:{" "}
                                <Text style={{ fontFamily: "Helvetica-Bold", fontWeight: "bold" }}>
                                    {vendor?.vendorQuotations?.quotationSets?.slice(-1)[0]?.counterPaymentTerms || "N/A"}
                                </Text>
                            </Text>

                            <View style={{ paddingBottom: "10px" }} />

                            <View style={styles.horizontalLine}></View>
                        </View>
                    ))}
                </View>
            </Page>

            {/* Pages for Material List Table and Other Charges */}
            <Page size="A4" style={styles.page} orientation="landscape">
                <Text style={[styles.topVendorsHeader, { textAlign: "center", textDecoration: "underline" }]}>Detailed Vendor Quotations:</Text>
                {topVendors?.map((vendor, index) => (
                    <View style={styles.section}>
                        <Text style={styles.text}>
                            <Text style={{ fontFamily: "Helvetica" }}>Vendor Name: </Text>
                            <Text style={{ fontFamily: "Helvetica-Bold", fontWeight: "bold" }}>
                                {vendor.businessName} (Rank {vendor?.vendorQuotations?.rank || "N/A"})
                            </Text>
                        </Text>
                        <Text style={styles.text}>
                            <Text style={{ fontFamily: "Helvetica" }}>Quotation Amount (excl. GST): </Text>
                            <Text style={{ fontFamily: "Helvetica-Bold", fontWeight: "bold" }}>
                                Rs {new Intl.NumberFormat('en-IN').format(vendor?.vendorQuotations?.grandTotalWithoutGst) || "N/A"}
                            </Text>
                        </Text>
                        <Text style={styles.text}>
                            <Text style={{ fontFamily: "Helvetica" }}>Quotation Amount (incl. GST): </Text>
                            <Text style={{ fontFamily: "Helvetica-Bold", fontWeight: "bold" }}>
                                Rs {new Intl.NumberFormat('en-IN').format(vendor?.vendorQuotations?.grandTotalWithGst) || "N/A"}
                            </Text>
                        </Text>
                        <Text style={styles.text}>
                            <Text style={{ fontFamily: "Helvetica" }}>Payment Terms: </Text>
                            <Text style={{ fontFamily: "Helvetica-Bold", fontWeight: "bold" }}>
                                {vendor?.vendorQuotations?.quotationSets?.slice(-1)[0]?.counterPaymentTerms || "N/A"}
                            </Text>
                        </Text>
                        <Text style={[styles.text, { marginTop: "30px", marginBottom: "30px" }]}>
                            <Text style={{ fontFamily: "Helvetica" }}>Vendor Remarks: </Text>
                            <Text style={{ fontFamily: "Helvetica-Bold", fontWeight: "bold" }}>
                                {vendor?.vendorQuotations?.quotationSets?.slice(-1)[0]?.notes || "N/A"}
                            </Text>
                        </Text>


                        {/* Material List Table */}
                        <Text style={[styles.text]}>
                            {rfqData?.rfq?.rfqType === "material"
                                ? "Material List & Quote:"
                                : rfqData?.rfq?.rfqType === "labour"
                                    ? "Labour Details & Quote:"
                                    : rfqData?.rfq?.rfqType === "contractor"
                                        ? "Providing and Fixing Details & Quote:"
                                        : "Details & Quote:"}
                        </Text>
                        <View style={styles.table}>
                            {/* Table Header */}
                            {rfqData?.rfq?.rfqType === "material" && (
                                <View style={[styles.tableRow, { backgroundColor: "#F5F5F5" }]}>
                                    <Text style={[styles.tableCell, { fontWeight: "bolder", width: "5%" }]}>Sr #</Text>
                                    <Text style={[styles.tableCell, styles.bold, { width: "20%" }]}>Product Name</Text>
                                    <Text style={[styles.tableCell, styles.bold, { width: "20%" }]}>Specification</Text>
                                    <Text style={[styles.tableCell, styles.bold, { width: "8%" }]}>Quantity</Text>
                                    <Text style={[styles.tableCell, styles.bold, { width: "9%" }]}>Price</Text>
                                    <Text style={[styles.tableCell, styles.bold, { width: "7%" }]}>Discount (%)</Text>
                                    <Text style={[styles.tableCell, styles.bold, { width: "7%" }]}>GST (%)</Text>
                                    <Text style={[styles.tableCell, styles.bold, { width: "12%" }]}>Amount (excl. GST)</Text>
                                    <Text style={[styles.tableCell, styles.bold, { width: "12%" }]}>Amount (incl. GST)</Text>
                                </View>
                            )}

                            {rfqData?.rfq?.rfqType === "labour" && (
                                <View style={[styles.tableRow, { backgroundColor: "#F5F5F5" }]}>
                                    <Text style={[styles.tableCell, { fontWeight: "bolder", width: "5%" }]}>Sr #</Text>
                                    <Text style={[styles.tableCell, styles.bold, { width: "25%" }]}>Skill</Text>
                                    <Text style={[styles.tableCell, styles.bold, { width: "20%" }]}>Quantity</Text>
                                    <Text style={[styles.tableCell, styles.bold, { width: "25%" }]}>Labour Rate</Text>
                                    <Text style={[styles.tableCell, styles.bold, { width: "10%" }]}>GST (%)</Text>
                                    <Text style={[styles.tableCell, styles.bold, { width: "15%" }]}>Amount</Text>
                                </View>
                            )}

                            {rfqData?.rfq?.rfqType === "contractor" && (
                                <View style={[styles.tableRow, { backgroundColor: "#F5F5F5" }]}>
                                    <Text style={[styles.tableCell, { fontWeight: "bolder", width: "5%" }]}>Sr #</Text>
                                    <Text style={[styles.tableCell, styles.bold, { width: "30%" }]}>Description</Text>
                                    <Text style={[styles.tableCell, styles.bold, { width: "15%" }]}>Base Price</Text>
                                    <Text style={[styles.tableCell, styles.bold, { width: "15%" }]}>GST (%)</Text>
                                    <Text style={[styles.tableCell, styles.bold, { width: "15%" }]}>Remarks</Text>
                                    <Text style={[styles.tableCell, styles.bold, { width: "20%" }]}>Amount (incl. GST)</Text>
                                </View>
                            )}

                            {/* Table Rows */}
                            {rfqData?.rfq?.rfqType === "material" &&
                                vendor?.vendorQuotations?.quotationSets?.slice(-1)[0]?.materialDetails?.map(
                                    (item, idx) => (
                                        <View key={idx} style={styles.tableRow}>
                                            <Text style={[styles.tableCell, { width: "5%" }]}>{idx + 1}</Text>
                                            <Text style={[styles.tableCell, { width: "20%" }]}>{item.name}</Text>
                                            <Text style={[styles.tableCell, { width: "20%" }]}>{item.remark}</Text>
                                            <Text style={[styles.tableCell, { width: "8%" }]}>{item.quantity}</Text>
                                            <Text style={[styles.tableCell, { width: "9%" }]}>{item.basePrice}</Text>
                                            <Text style={[styles.tableCell, { width: "7%" }]}>{item.discount}</Text>
                                            <Text style={[styles.tableCell, { width: "7%" }]}>{item.gst}</Text>
                                            <Text style={[styles.tableCell, { width: "12%" }]}>{new Intl.NumberFormat('en-IN').format(item.priceWithoutGst)}</Text>
                                            <Text style={[styles.tableCell, { width: "12%" }]}>{new Intl.NumberFormat('en-IN').format(item.netPrice)}</Text>
                                        </View>
                                    )
                                )}

                            {rfqData?.rfq?.rfqType === "labour" &&
                                vendor?.vendorQuotations?.quotationSets?.slice(-1)[0]?.labourItems?.map(
                                    (item, idx) => (
                                        <View key={idx} style={styles.tableRow}>
                                            <Text style={[styles.tableCell, { width: "5%" }]}>{idx + 1}</Text>
                                            <Text style={[styles.tableCell, { width: "25%" }]}>{item.skill}</Text>
                                            <Text style={[styles.tableCell, { width: "20%" }]}>{item.quantity}</Text>
                                            <Text style={[styles.tableCell, { width: "25%" }]}>{new Intl.NumberFormat('en-IN').format(item.labourRate)}</Text>
                                            <Text style={[styles.tableCell, { width: "10%" }]}>{item.gst}</Text>
                                            <Text style={[styles.tableCell, { width: "15%" }]}>{new Intl.NumberFormat('en-IN').format(item.totalAmount)}</Text>
                                        </View>
                                    )
                                )}

                            {rfqData?.rfq?.rfqType === "contractor" && (
                                <View style={styles.tableRow}>
                                    <Text style={[styles.tableCell, { width: "5%" }]}>1</Text>
                                    <Text style={[styles.tableCell, { width: "30%" }]}>{stripHtml(vendor?.vendorQuotations?.quotationSets?.slice(-1)[0]?.contractorDetails?.description) || "N/A"}</Text>
                                    <Text style={[styles.tableCell, { width: "15%" }]}>{new Intl.NumberFormat('en-IN').format(vendor?.vendorQuotations?.quotationSets?.slice(-1)[0]?.contractorDetails?.basePrice) || "N/A"}</Text>
                                    <Text style={[styles.tableCell, { width: "15%" }]}>{vendor?.vendorQuotations?.quotationSets?.slice(-1)[0]?.contractorDetails?.gst || "N/A"}</Text>
                                    <Text style={[styles.tableCell, { width: "15%" }]}>{vendor?.vendorQuotations?.quotationSets?.slice(-1)[0]?.contractorDetails?.remarks || "N/A"}</Text>
                                    <Text style={[styles.tableCell, { width: "20%" }]}>{new Intl.NumberFormat('en-IN').format(vendor?.vendorQuotations?.quotationSets?.slice(-1)[0]?.contractorDetails?.netPrice) || "N/A"}</Text>
                                </View>
                            )}
                        </View>


                        {/* Other Charges Table */}
                        {Object.entries(
                            vendor?.vendorQuotations?.quotationSets?.slice(-1)[0]?.otherCharges || {}
                        ).some(([chargeName, details]) => details.mrp > 0) && (  // Check if there's any charge with mrp > 0
                                <>
                                    <Text style={styles.text}>Other Charges</Text>  {/* Display the heading only if there's a charge with mrp > 0 */}
                                    <View style={styles.table}>
                                        <View style={[styles.tableRow, { backgroundColor: "#F5F5F5" }]}>
                                            <Text style={[styles.tableCell, styles.bold, { width: "40%" }]}>Charge Name</Text>
                                            <Text style={[styles.tableCell, styles.bold, { width: "20%" }]}>Price</Text>
                                            <Text style={[styles.tableCell, styles.bold, { width: "20%" }]}>GST</Text>
                                            <Text
                                                style={[styles.tableCell, styles.bold, styles.tableCellLast, { width: "20%" }]}
                                            >
                                                Total
                                            </Text>
                                        </View>
                                        {Object.entries(
                                            vendor?.vendorQuotations?.quotationSets?.slice(-1)[0]?.otherCharges || {}
                                        ).filter(([chargeName, details]) => details.mrp > 0)  // Filter charges where mrp > 0
                                            .map(([chargeName, details], idx) => (
                                                <View key={idx} style={styles.tableRow}>
                                                    <Text style={[styles.tableCell, styles.bold, { width: "40%" }]}>{chargeName}</Text>
                                                    <Text style={[styles.tableCell, styles.bold, { width: "20%" }]}>{new Intl.NumberFormat('en-IN').format(details.mrp)}</Text>
                                                    <Text style={[styles.tableCell, styles.bold, { width: "20%" }]}>{details.gst}</Text>
                                                    <Text
                                                        style={[styles.tableCell, styles.tableCellLast, { width: "20%" }]}
                                                    >
                                                        {new Intl.NumberFormat('en-IN').format(details.totalCost)}
                                                    </Text>
                                                </View>
                                            ))}
                                    </View>
                                </>
                            )}
                        <View style={[styles.horizontalLine, { marginTop: "10px", marginBottom: "10px" }]}></View>

                    </View>
                ))}
            </Page>
        </Document>
    );
};

export default PDFDocument;
