import { isPast } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "utils/axios";
import Skeleton from "@mui/material/Skeleton"
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { submitMaterialQuotation } from "actions/vendorQuotationAction";
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from "react-router-dom";
import CustomGstDropdown from "./CustomGstDropdown";
import StaticPopup from "components/StaticPopup";
import FileUpload from "./FileUpload";
import { vendorQuotationService } from "services/vendorQuotationService";
import ExcelJS from "exceljs";
import LoadingModal from "views/Rfqs/LoadingModal";


const MaterialQuotationForm = () => {

    const { loading, error, errorMessage, quotation } = useSelector((state) => state.vendorQuotation);
    const { enqueueSnackbar } = useSnackbar();

    const auth = useSelector((state) => state.auth);
    const userProfile = auth.user;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const [rfqData, setRfqData] = useState(location.state?.rfqData || {});
    const { isEdit } = location.state;
    const { otherCharges } = rfqData || {};

    const supplierId = location.state?.supplierId || "";
    const quotationIndex = location.state?.quotationIndex;
    const isReadOnly = quotationIndex !== undefined || rfqData?.status === "CLOSED"

    useEffect(() => {
        const fetchVendorQuotation = async () => {
            if (supplierId) {
                try {
                    const payload = { rfqId: rfqData?.rfqId, supplierId };
                    const response = await vendorQuotationService.getVendorQuotation(payload);
                    // console.log("Fetched Vendor Quotation Data:", response);

                    // Update rfqData.suppliers[0] with fetched vendorQuotations
                    if (response?.vendorQuotations) {
                        setRfqData(prevData => ({
                            ...prevData,
                            suppliers: prevData.suppliers?.map((supplier, index) =>
                                index === 0 ? { ...supplier, vendorQuotations: response.vendorQuotations } : supplier
                            ) || []
                        }));
                    }
                } catch (error) {
                    console.error("Error fetching vendor quotation:", error);
                }
            }
        };

        fetchVendorQuotation();
    }, [supplierId, isEdit, rfqData?.rfqId]);

    useEffect(() => {
        if (rfqData?.suppliers?.[0]?.vendorQuotations) {

            const latestQuotationSet = quotationIndex !== undefined ? rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.[quotationIndex] : rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0];

            setTotalCosts(latestQuotationSet?.materialDetails?.map((material) => material?.netPrice) || rfqData?.materials.map(() => 0));
            setOverallTotal(quotationIndex !== undefined ? (latestQuotationSet?.othersTotalWithGst + latestQuotationSet?.totalWithGst) : rfqData.suppliers[0].vendorQuotations.grandTotalWithGst || 0);
            setSelectedFilesBase(latestQuotationSet?.attachments || []);
            setIsCounterTerm(latestQuotationSet?.agreedToPaymentTerms || false);


            const otherCharges = latestQuotationSet?.otherCharges || {};

            setMiscellaneousCosts({
                transport: otherCharges?.transport || { mrp: 0, gst: 0, totalCost: 0 },
                loading: otherCharges?.loading || { mrp: 0, gst: 0, totalCost: 0 },
                unLoading: otherCharges?.unLoading || { mrp: 0, gst: 0, totalCost: 0 },
                mathadeCharges: otherCharges?.mathadeCharges || { mrp: 0, gst: 0, totalCost: 0 },
                miscCharges: otherCharges?.miscCharges || { mrp: 0, gst: 0, totalCost: 0 }
            });
        } else {
            setMiscellaneousCosts({
                transport: { mrp: 0, gst: 0, totalCost: 0 },
                loading: { mrp: 0, gst: 0, totalCost: 0 },
                unLoading: { mrp: 0, gst: 0, totalCost: 0 },
                mathadeCharges: { mrp: 0, gst: 0, totalCost: 0 },
                miscCharges: { mrp: 0, gst: 0, totalCost: 0 }
            });
        }
    }, [rfqData, quotationIndex]);


    const miscellaneousItems = [
        { label: "Transport", name: "transport" },
        { label: "Loading", name: "loading" },
        { label: "Unloading", name: "unLoading" },
        { label: "Mathade Charges", name: "mathadeCharges" },
        { label: "Misc. Charges", name: "miscCharges" },
    ];

    const visibleMiscellaneousItems = miscellaneousItems.filter(item => otherCharges?.[item.name]);

    // Check if any items are visible
    const hasVisibleMiscellaneousItems = visibleMiscellaneousItems.length > 0;

    const currentRank = rfqData?.suppliers[0]?.vendorQuotations?.rank
    // console.log("aaaaaaaa", currentRank)

    const attachmentsData = rfqData?.selectedFilesBase
        ? rfqData.selectedFilesBase.map((item) => {
            const lastIndex = item.Name.lastIndexOf('.'); // Extract file extension
            const path = lastIndex !== -1 ? item.Name.substring(lastIndex + 1) : null;
            return { ...item, Path: path }; // Add the Path to determine file type
        })
        : [];

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [totalCosts, setTotalCosts] = useState(rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]?.materialDetails?.map((material) => material?.netPrice) || rfqData?.materials.map(() => 0));
    const [overallTotal, setOverallTotal] = useState(rfqData?.suppliers?.[0]?.vendorQuotations?.grandTotalWithGst || 0);
    const [miscellaneousCosts, setMiscellaneousCosts] = useState({
        transport: { mrp: 0, gst: 0, totalCost: 0 },
        loading: { mrp: 0, gst: 0, totalCost: 0 },
        unLoading: { mrp: 0, gst: 0, totalCost: 0 },
        mathadeCharges: { mrp: 0, gst: 0, totalCost: 0 },
        miscCharges: { mrp: 0, gst: 0, totalCost: 0 }
    });

    const [isCounterTerm, setIsCounterTerm] = useState(!rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]?.agreedToPaymentTerms || false);
    const [attachmentsSkeleton, setAttachmentsSkeleton] = useState([]);
    const [selectedFilesBase, setSelectedFilesBase] = useState(rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0]?.attachments || []);
    const [selectedFilesTotalSize, setSelectedFilesTotalSize] = useState([]);
    const [attachmentsUploadsErrors, setAttachmentsUploadsErrors] = useState("");
    const [openFilesSizeError, setOpenFilesSizeError] = useState(false);
    const [openAccordian, setOpenAccordian] = useState(null); // State to manage open accordion

    const [excelLoading, setExcelLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [excelError, setExcelError] = useState(null);


    const [materialFilesBase, setMaterialFilesBase] = useState([]);
    const [materialFilesSkeleton, setMaterialFilesSkeleton] = useState([]);
    const [materialFilesTotalSize, setMaterialFilesTotalSize] = useState([]);
    const [materialUploadsErrors, setMaterialUploadsErrors] = useState("");
    const [openMaterialFileSizeError, setOpenMaterialFileSizeError] = useState(false);
    const [openUploadFile, setOpenUploadFile] = useState(false);
    const [selectedMaterialIndex, setSelectedMaterialIndex] = useState(null);

    const getInitialValues = (rfqData, userProfile) => {
        const isEditing = Boolean(rfqData?.suppliers?.[0]?.vendorQuotations);
        const lastQuotationSet = isEditing
            ? (quotationIndex !== undefined
                ? rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.[quotationIndex]
                : rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0])
            : null;

        // Create a map of materials from lastQuotationSet for quick lookup by ID and name
        const quotationMaterialMap = (lastQuotationSet?.materialDetails || []).reduce((map, material) => {
            if (material.id) {
                map.byId[material.id.toString()] = material;
            }
            if (material.name) {
                map.byName[material.name.toLowerCase()] = material;
            }
            return map;
        }, { byId: {}, byName: {} });

        // Merge rfqData.materials with lastQuotationSet.materialDetails
        const mergedMaterials = (rfqData?.materials || []).map(material => {
            const matchedMaterial =
                quotationMaterialMap.byId[material._id?.toString()] || // Match by ID
                quotationMaterialMap.byName[material.name?.toLowerCase()]; // Fallback to name match

            return matchedMaterial
                ? {
                    name: matchedMaterial.name,
                    quantity: material.quantity, // Use updated quantity from rfqData
                    unit: matchedMaterial.unit,
                    id: matchedMaterial.id, // Retain ID from lastQuotationSet
                    specifications: matchedMaterial.specifications,
                    brand: matchedMaterial.brand,
                    basePrice: matchedMaterial.basePrice || 0,
                    discount: matchedMaterial.discount || 0,
                    gst: matchedMaterial.gst || 0,
                    totalCost: matchedMaterial.totalCost || 0,
                    remark: matchedMaterial.remark || "",
                    deliveryDate: matchedMaterial?.deliveryDate
                        ? new Date(matchedMaterial.deliveryDate).toISOString().split("T")[0]
                        : "",
                    attachments: matchedMaterial.attachments || []
                }
                : {
                    name: material.name,
                    quantity: material.quantity,
                    unit: material.unit,
                    id: material._id, // Use the ID from rfqData
                    specifications: material.specifications,
                    brand: material.brands?.[0] || "",
                    basePrice: "",
                    discount: 0,
                    gst: 0,
                    totalCost: 0,
                    remark: "",
                    deliveryDate: rfqData?.providingDate
                        ? new Date(rfqData.providingDate).toISOString().split("T")[0]
                        : "",
                    attachments: []
                };
        });

        return {
            materials: mergedMaterials,

            miscellaneousCosts: {
                transport: {
                    mrp: isEditing ? lastQuotationSet?.otherCharges?.transport?.mrp || 0 : 0,
                    gst: isEditing ? lastQuotationSet?.otherCharges?.transport?.gst || 0 : 0,
                    totalCost: isEditing ? lastQuotationSet?.otherCharges?.transport?.totalCost || 0 : 0
                },
                loading: {
                    mrp: isEditing ? lastQuotationSet?.otherCharges?.loading?.mrp || 0 : 0,
                    gst: isEditing ? lastQuotationSet?.otherCharges?.loading?.gst || 0 : 0,
                    totalCost: isEditing ? lastQuotationSet?.otherCharges?.loading?.totalCost || 0 : 0
                },
                unLoading: {
                    mrp: isEditing ? lastQuotationSet?.otherCharges?.unLoading?.mrp || 0 : 0,
                    gst: isEditing ? lastQuotationSet?.otherCharges?.unLoading?.gst || 0 : 0,
                    totalCost: isEditing ? lastQuotationSet?.otherCharges?.unLoading?.totalCost || 0 : 0
                },
                mathadeCharges: {
                    mrp: isEditing ? lastQuotationSet?.otherCharges?.mathadeCharges?.mrp || 0 : 0,
                    gst: isEditing ? lastQuotationSet?.otherCharges?.mathadeCharges?.gst || 0 : 0,
                    totalCost: isEditing ? lastQuotationSet?.otherCharges?.mathadeCharges?.totalCost || 0 : 0
                },
                miscCharges: {
                    mrp: isEditing ? lastQuotationSet?.otherCharges?.miscCharges?.mrp || 0 : 0,
                    gst: isEditing ? lastQuotationSet?.otherCharges?.miscCharges?.gst || 0 : 0,
                    totalCost: isEditing ? lastQuotationSet?.otherCharges?.miscCharges?.totalCost || 0 : 0
                }
            },

            agreedToPaymentTerms: isEditing ? lastQuotationSet?.agreedToPaymentTerms : true,
            counterPaymentTerms: isEditing ? lastQuotationSet?.counterPaymentTerms || "" : "",
            notes: isEditing ? lastQuotationSet?.notes : ""
        };
    };

    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

    // Utility function to validate file size
    const isFileSizeValid = (files, existingSize) => {
        const sizeOfSelectedFiles = Array.from(files).reduce((acc, file) => acc + file.size, 0);
        const totalSize = sizeOfSelectedFiles + existingSize;
        return totalSize < MAX_FILE_SIZE;
    };

    const capitalizeFirstLetter = (string) => {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    // Utility function to read files and convert them to base64
    const readAndConvertFiles = (files) => {
        return Array.from(files).map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const fileContent = e.target.result;
                    const encryptedContent = btoa(fileContent); // Encrypt in Base64
                    let contentType = file.type;
                    if (!contentType && file.name.endsWith(".dwg")) {
                        contentType = "application/acad"; // Handle .dwg files
                    }
                    resolve({
                        Name: file.name,
                        Content: encryptedContent,
                        ContentType: contentType,
                        FileSize: file.size,
                    });
                };
                reader.readAsBinaryString(file);
            });
        });
    };


    // Material-specific file upload click handler
    const handleMaterialFileUploadClick = (event) => {
        event.target.value = ""; // Clear file input on click
    };

    // Material-specific file upload handler
    const handleMaterialFileUpload = (event, materialIndex, setFieldValue, values) => {
        const files = event.target.files;
        const totalExistingSize = materialFilesTotalSize.reduce((acc, file) => acc + file.FileSize, 0);

        if (isFileSizeValid(files, totalExistingSize)) {
            setMaterialFilesSkeleton((prevVal) => [...prevVal, ...Array.from(files)]);

            const convertedFilesPromises = readAndConvertFiles(files);

            Promise.all(convertedFilesPromises)
                .then(async (convertedFiles) => {
                    try {
                        const response = await axios.post("/api/rfqs/upload/attachments", convertedFiles);

                        // Update the Formik state to include attachments for the specific material
                        const currentAttachments = values.materials[materialIndex]?.attachments || [];
                        const updatedAttachments = [...currentAttachments, ...response.data];

                        setFieldValue(`materials[${materialIndex}].attachments`, updatedAttachments);
                        setMaterialFilesSkeleton([]);
                    } catch (error) {
                        setMaterialFilesSkeleton([]);
                        setMaterialUploadsErrors(error.message);
                        setTimeout(() => setMaterialUploadsErrors(""), 5000);
                    }
                })
                .catch((error) => {
                    console.error("Error reading files", error);
                });
        } else {
            setOpenMaterialFileSizeError(true);
            setTimeout(() => setOpenMaterialFileSizeError(false), 6000);
        }

    };



    // Handle deleting material-specific files
    const handleDeleteMaterialFile = (name, event, setFieldValue, selectedMaterialIndex, values) => {
        event.preventDefault();
        setMaterialFilesBase((prevVal) => prevVal.filter((file) => file.Name !== name));
        setMaterialFilesTotalSize((prevVal) => prevVal.filter((file) => file.Name !== name));

        // Update Formik state to remove the file
        setFieldValue(`materials[${selectedMaterialIndex}].attachments`,
            values.materials[selectedMaterialIndex].attachments.filter(file => file.Name !== name));
    };


    // Handle closing the file size error for materials
    const handleCloseMaterialFileSizeError = () => {
        setOpenMaterialFileSizeError(false);
    };

    const toggleAccordion = (index) => {
        setOpenAccordian(openAccordian === index ? null : index); // Toggle the open state
    };

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const handleCloseForm = () => {
        if (supplierId) {
            navigate('/leads')
        } else {
            navigate(-1)
        }
    }

    // const handleBrandDropDown = (index) => {
    //     setIsBrandDropdownOpen((prev) => {
    //         const newDropdownState = [...prev];
    //         newDropdownState[index] = !newDropdownState[index];
    //         return newDropdownState;
    //     });
    // };

    // const handleRadioChange = (index, value) => {
    //     setselectedBrand((prev) => {
    //         const newPaintState = [...prev];
    //         newPaintState[index] = value;
    //         return newPaintState;
    //     });
    //     setIsBrandDropdownOpen((prev) => {
    //         const newDropdownState = [...prev];
    //         newDropdownState[index] = false; 
    //         return newDropdownState;
    //     });
    // };

    const calculateTotalCost = (basePrice, quantity, discount, gst) => {
        const cost = basePrice * quantity;
        const discountAmount = (cost * discount) / 100;
        const gstAmount = ((cost - discountAmount) * gst) / 100;
        return cost - discountAmount + gstAmount;
    };

    const calculateOverallTotal = (materialCosts, miscCosts) => {
        // Summing up all the material costs
        const totalMaterialCosts = materialCosts.reduce((acc, curr) => acc + curr, 0);

        // Summing up all the miscellaneous costs (like transport, loading, etc.)
        const totalMiscCosts = Object.values(miscCosts).reduce((acc, curr) => acc + (curr.totalCost || 0), 0);

        // Update the overall total (material costs + miscellaneous costs)
        const newOverallTotal = totalMaterialCosts + totalMiscCosts;
        setOverallTotal(newOverallTotal);
    };




    const handleFieldChange = (index, field, value, values) => {
        const updatedLineItems = [...values.materials];
        updatedLineItems[index][field] = value;

        const basePrice = parseFloat(updatedLineItems[index].basePrice) || 0;
        const quantity = parseFloat(updatedLineItems[index].quantity) || 0;
        const discount = parseFloat(updatedLineItems[index].discount) || 0;
        const gst = parseFloat(updatedLineItems[index].gst) || 0;

        const totalCost = calculateTotalCost(basePrice, quantity, discount, gst);
        updatedLineItems[index].totalCost = totalCost;

        const newTotalCosts = [...totalCosts];
        newTotalCosts[index] = totalCost;
        setTotalCosts(newTotalCosts);

        // Recalculate the overall total whenever material costs change
        calculateOverallTotal(newTotalCosts, miscellaneousCosts);
    };

    const calculateTransportationTotalCost = (mrp, gst) => {
        const gstValue = parseFloat(gst) || 0;
        const mrpValue = parseFloat(mrp) || 0;
        return mrpValue + (mrpValue * gstValue) / 100;
    };


    // Handler for miscellaneous cost inputs
    const handleMiscellaneousChange = (field, value, setFieldValue, values, type) => {
        // Parse the value or use an empty string if the input is empty
        const parsedValue = value === "" ? "" : parseFloat(value) || 0;

        // Clone the current miscellaneous costs from Formik values to work on
        const updatedMiscellaneousCosts = { ...values.miscellaneousCosts };

        // Update the specific field (transport, loading, etc.) with the new MRP or GST
        updatedMiscellaneousCosts[field][type] = parsedValue;

        // Calculate total cost if both MRP and GST are valid
        if (updatedMiscellaneousCosts[field].mrp !== "" && updatedMiscellaneousCosts[field].gst !== "") {
            updatedMiscellaneousCosts[field].totalCost = calculateTransportationTotalCost(
                updatedMiscellaneousCosts[field].mrp,
                updatedMiscellaneousCosts[field].gst
            );
        } else {
            updatedMiscellaneousCosts[field].totalCost = 0; // Reset total cost if inputs are incomplete
        }

        // Update Formik values and state
        setFieldValue(`miscellaneousCosts.${field}.${type}`, parsedValue);

        // Update the component state to reflect changes in the UI
        setMiscellaneousCosts(updatedMiscellaneousCosts);

        // Recalculate overall total cost
        calculateOverallTotal(totalCosts, updatedMiscellaneousCosts);
    };

    const handleGstChange = (field, value, setFieldValue, values) => {
        handleMiscellaneousChange(field, value, setFieldValue, values, 'gst');
    };


    // Yup validation schema
    const miscellaneousSchema = Yup.object().shape({
        mrp: Yup.number().typeError("Enter a valid MRP").required("MRP is required").min(0, "Invalid MRP"),
        gst: Yup.number().typeError("Enter a valid GST").required("GST is required"),
        // totalCost: Yup.number().typeError("Total cost should be a number").required(),
    });

    const createMiscellaneousValidationSchema = (otherCharges) => {
        const schema = {};

        if (otherCharges.transport) {
            schema.transport = miscellaneousSchema;
        }
        if (otherCharges.loadingOffloading) {
            schema.loading = miscellaneousSchema;
        }
        if (otherCharges.mathadeCharges) {
            schema.mathadeCharges = miscellaneousSchema;
        }
        if (otherCharges.miscCharges) {
            schema.miscCharges = miscellaneousSchema;
        }

        return Yup.object().shape(schema);
    };

    const validationSchema = Yup.object().shape({
        materials: Yup.array().of(
            Yup.object().shape({
                basePrice: Yup.number().typeError("Enter a valid unit cost").required("Required").min(0, "Invalid cost"),
                discount: Yup.number().typeError("Enter a valid discount"),
                gst: Yup.string().typeError("Enter a valid GST").required("Required").min(0, "Invalid GST"),
            })
        ),
        // miscellaneousCosts: createMiscellaneousValidationSchema(otherCharges),
    });


    // const MAX_FILE_SIZE = 10 * 1024 * 1024; 

    const handleFileUploadClick = (event) => {
        return (event.target.value = "");
    };

    const handleFileUpload = (event) => {
        const files = event.target.files;
        // Count of currently selected files
        const sizeOfSelectedFiles = Array.from(files).reduce((acc, obj) => {
            return acc + obj.size;
        }, 0);

        // Count of prev selected and current selected files
        const sizeOfAllSelectedFiles = selectedFilesTotalSize.reduce(
            (acc, obj) => acc + obj.FileSize,
            sizeOfSelectedFiles
        );

        // Validate file sizes and perform file upload as needed
        if (sizeOfAllSelectedFiles < MAX_FILE_SIZE) {
            setAttachmentsSkeleton((prevVal) => [...prevVal, ...Array.from(files)]);

            const convertedFilesPromises = Array.from(files).map((file) => {
                return new Promise((resolve, reject) => {
                    const formData = new FormData();
                    formData.append("file", file);

                    const reader = new FileReader();

                    reader.onload = (e) => {
                        const fileContent = e.target.result;
                        const encryptedContent = btoa(fileContent); // Encrypt file content in Base64

                        // Fallback mechanism for .dwg files
                        let contentType = file.type;
                        if (!contentType && file.name.endsWith(".dwg")) {
                            contentType = "application/acad"; // Common MIME type for DWG files
                        }

                        // Include fileType, fileName, and encryptedContent in your request payload
                        const payload = {
                            Name: file.name,
                            Content: encryptedContent,
                            ContentType: contentType,
                            FileSize: file.size,
                        };
                        resolve(payload); // Resolve the promise with the payload for demonstration purposes
                    };

                    reader.readAsBinaryString(file);
                });
            });

            const attachmentBlobLink = (convertedFiles) => {
                axios
                    .post("/api/rfqs/upload/attachments", convertedFiles)
                    .then((response) => {
                        setSelectedFilesBase((prevVal) => [...prevVal, ...response.data]);
                        setSelectedFilesTotalSize((prevVal) => [
                            ...prevVal,
                            ...convertedFiles,
                        ]);
                        setAttachmentsSkeleton([]);
                    })
                    .catch((error) => {
                        setAttachmentsSkeleton([]);
                        setAttachmentsUploadsErrors(error.message);
                        setTimeout(() => {
                            setAttachmentsUploadsErrors("");
                        }, 5000);
                        console.error(error);
                    });
            };

            Promise.all(convertedFilesPromises)
                .then((convertedFiles) => {
                    attachmentBlobLink(convertedFiles);
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            setOpenFilesSizeError(true);
            setTimeout(() => {
                setOpenFilesSizeError(false);
            }, 6000);
        }
    };

    const handleDeleteSelectedFile = (name, event) => {
        event.preventDefault(); // Prevent default link behavior
        setSelectedFilesBase((prevVal) => prevVal.filter((data) => data.Name !== name));
        setSelectedFilesTotalSize((prevVal) => prevVal.filter((data) => data.Name !== name));
    };

    const handleCloseFileSizeError = () => {
        setOpenFilesSizeError(false);
    };

    useEffect(() => {
        if (!loading && quotation && Object.keys(quotation).length > 0) {
            // Show success snackbar when quotation is successfully submitted
            enqueueSnackbar('Vendor quotation submitted successfully!', {
                variant: 'success',
                autoHideDuration: 3000,
            });
        }

        if (!loading && error) {
            // Show error snackbar when there's an error
            enqueueSnackbar(`Error: ${errorMessage}`, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    }, [loading, quotation, error, errorMessage, enqueueSnackbar]);

    const [currentStep, setCurrentStep] = useState(0);


    const steps = [
        "rfq info",
        "Input Fields",
        "Miscellaneous",
        "Supporting Documents",
        "Additional Information & Upload",
        "Completion"
    ];

    const handleNextStep = async (validateForm, setTouched, values) => {
        const errors = await validateForm(); // Trigger Formik validation for current step
        // Find the index of the first material with an error
        const errorIndex = errors.materials?.findIndex(error => error != null); // Check for the first non-null error object

        if (errorIndex !== -1) {
            // Set the accordion to open at the first error index
            setOpenAccordian(errorIndex);
            setTouched({
                materials: values.materials.map((material, index) =>
                    index === errorIndex ? { ...material, ...errors.materials[errorIndex] } : material
                ),
            });
        }
        const hasErrors = Object.keys(errors).length > 0; // Check if there are any errors
        if (!hasErrors) {
            // If no errors, move to the next step
            setCurrentStep((prevStep) => prevStep + 1);
        }
    };


    const handlePreviousStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const handleDownloadExcel = async () => {
        try {
            setExcelLoading(true);
            setLoadingMessage("Preparing the Excel file for download...");

            // Load the Excel template
            const templatePath = "/assets/general/bulk_template_material_rates_new.xlsx";
            const response = await fetch(templatePath);
            const buffer = await response.arrayBuffer();

            // Load workbook from the buffer
            const workbook = new ExcelJS.Workbook();
            await workbook.xlsx.load(buffer);

            // Access the specific sheet
            const sheet = workbook.getWorksheet("bulk_material_rates");

            // Use rfqData.materials and initial form values to prepare data
            const materials = getInitialValues(rfqData, userProfile).materials || [];

            // Populate the sheet starting from row 3
            materials.forEach((material, index) => {
                const row = sheet.getRow(index + 3); // Start from row 3

                // Populate the row with material data
                row.getCell(1).value = index + 1; // Sr
                row.getCell(2).value = material.id; // Neevay_ID
                row.getCell(3).value = material.name; // Material_Name
                row.getCell(4).value = material.specifications; // Specification
                row.getCell(5).value = material.brand; // Approved_brand
                row.getCell(6).value = material.quantity; // Qty
                row.getCell(7).value = material.unit; // Unit

                // Populate the editable fields based on material data
                row.getCell(8).value = material.basePrice || null; // MRP
                row.getCell(9).value = material.discount >0 && `${material.discount}%` || null; // Discount
                row.getCell(10).value = material.gst ? `${material.gst}%` : `18%`; // GST
                row.getCell(11).value = material.remark || null; // Vendor_Remarks

                // Set formulas for calculated fields
                row.getCell(12).value = { formula: `H${index + 3} - (H${index + 3} * I${index + 3})` } || null; // Corrected Net_Rate formula
                row.getCell(13).value = { formula: `L${index + 3} * F${index + 3}` } || null; // Basic_Amount formula remains unchanged
                row.getCell(14).value = { formula: `M${index + 3} * J${index + 3}` } || null; // Corrected GST_Amount formula
                row.getCell(15).value = { formula: `M${index + 3} + N${index + 3}` } || null; // Total_Amount_with_GST formula remains unchanged
            });


            // Ensure the sheet protection and styles are preserved
            sheet.protect("Neevay", {
                selectLockedCells: true,
                selectUnlockedCells: true,
            });

            // Generate and download the updated file
            const blob = await workbook.xlsx.writeBuffer();
            const url = URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.download = `RFQ-${rfqData?.rfqId}_material_upload.xlsx`;
            link.click();
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error processing Excel file:", error);
        } finally {
            setExcelLoading(false);
            setLoadingMessage("");
        }
    };


    const handleUploadExcel = async (event, setFieldValue, values) => {
        try {
            setExcelLoading(true);
            setLoadingMessage("Processing the uploaded Excel file...");
            const file = event.target.files[0];
            if (!file) return;

            const workbook = new ExcelJS.Workbook();
            await workbook.xlsx.load(await file.arrayBuffer());
            const sheet = workbook.getWorksheet("bulk_material_rates");

            const updatedMaterials = [...values.materials];
            const updatedTotalCosts = [...totalCosts];
            const errors = [];

            sheet.eachRow((row, rowNumber) => {
                if (rowNumber < 3) return; // Skip header rows

                const materialIndex = rowNumber - 3;
                const material = updatedMaterials[materialIndex];

                if (material) {
                    const uploadedNeevayId = row.getCell(2).value; // Neevay_ID from Excel

                    // **New Logic**: Validate if Neevay_ID matches any material._id in rfqData.materials
                    const isValidMaterial = rfqData?.materials.some((item) => item._id === uploadedNeevayId);
                    if (!isValidMaterial) {
                        errors.push(`Please upload correct sheet. Invalid material at row ${rowNumber}: Neevay_ID does not match.`);
                        return; // Skip processing this row
                    }

                    const mrp = row.getCell(8).value; // MRP
                    const discount = row.getCell(9).value; // Discount
                    let gst = row.getCell(10).value; // GST
                    const remarks = row.getCell(11).value; // Vendor_Remarks

                    // Convert raw discount value to percentage if needed
                    let discountValue = parseFloat(discount);
                    if (discountValue && discountValue <= 1) {
                        discountValue = discountValue * 100; // Convert to percentage
                    }

                    // Handle GST percentage conversion
                    if (gst && gst < 1) {
                        gst = gst * 100; // Convert to whole number percentage if needed
                    }

                    // Validation: MRP must not be empty
                    if (mrp === undefined || mrp === null || isNaN(mrp)) {
                        errors.push(`MRP is empty or invalid in row ${rowNumber}`);
                        return;
                    }

                    // Update material fields
                    material.basePrice = parseFloat(mrp) || 0;
                    material.discount = discountValue || 0;
                    material.gst = parseFloat(gst) || 0;
                    material.remark = remarks || "";

                    // Recalculate totalCost
                    const totalCost = calculateTotalCost(
                        material.basePrice,
                        material.quantity,
                        material.discount,
                        material.gst
                    );
                    material.totalCost = totalCost;

                    // Update totalCosts array
                    updatedTotalCosts[materialIndex] = totalCost;
                }
            });

            if (errors.length > 0) {
                enqueueSnackbar(errors.join(", "), { variant: "error" });
                return;
            }

            // Update form values and totalCosts state
            setFieldValue("materials", updatedMaterials);
            setTotalCosts(updatedTotalCosts);

            // Calculate the overall total after updating total costs
            calculateOverallTotal(updatedTotalCosts, values?.miscellaneousCosts);

            enqueueSnackbar("Excel uploaded and data updated successfully!", { variant: "success" });
        } catch (error) {
            console.error("Error processing Excel file:", error);
            enqueueSnackbar("Failed to upload Excel file.", { variant: "error" });
        } finally {
            setExcelLoading(false);
            setLoadingMessage("");
        }
    };



    return (
        <div className="max-w-7xl lg:mx-auto h-full bg-white shadow-md" style={{ fontFamily: "goldman_sans" }}>

            <Formik
                enableReinitialize={true}
                initialValues={useMemo(() => getInitialValues(rfqData, userProfile), [rfqData, userProfile])}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    const materialDetails = values.materials.map((item, index) => ({
                        id: item.id,
                        name: item.name,
                        brand: item.brand,
                        quantity: parseFloat(item.quantity),
                        basePrice: item.basePrice ? parseFloat(item.basePrice.toString().replace('Rs. ', '')) : 0,
                        discount: item.discount,
                        gst: item.gst,
                        totalCost: totalCosts[index],
                        remark: item.remark, // Add remark to submission
                        deliveryDate: item.deliveryDate, // Add deliveryDate to submission
                        attachments: item.attachments, // Add attachments to
                    }));
                    // Include miscellaneous costs in the submission
                    const submissionData = {
                        materialDetails,
                        otherCharges: values.miscellaneousCosts,
                        agreedToPaymentTerms: values.agreedToPaymentTerms,
                        counterPaymentTerms: values.counterPaymentTerms,
                        notes: values.notes,
                        selectedFilesBase: selectedFilesBase,
                        rfqId: rfqData?.rfqId,
                        rfqType: rfqData?.rfqType,
                        supplierId: rfqData?.suppliers?.[0]?.supplierId,
                        submittedBy: userProfile?.name,
                        userId: userProfile?.userId,
                    };

                    try {
                        await dispatch(submitMaterialQuotation({ values: submissionData }));
                        if (supplierId) {
                            navigate('/leads')
                        } else {
                            navigate(-1)
                        }
                        // if (isEdit) {
                        //     navigate(-1)
                        // } else {
                        //     navigate('/submitted-quotation')
                        // }
                    } catch (error) {
                        console.log("SUBMIT Error", error)
                    }

                }}
            >
                {({ values, errors, touched, setFieldValue, validateForm, setTouched }) => (
                    <Form onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault(); }}>
                        {/*Mobile View */}
                        <div className="block lg:hidden h-screen flex-grow overflow-auto">
                            {/* Stepper */}
                            {currentStep === 0 ? " " : (
                                <div className="sticky top-0 z-20 shadow-md bg-white flex justify-center items-center px-10 py-6 w-full lg:hidden">
                                    {/* Step 1 */}
                                    <div className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 0 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'}`}>
                                        <p className={`text-xs font-medium ${currentStep > 0 ? 'text-white' : '#313119'}`}>1</p>
                                    </div>
                                    <div className={`flex-grow h-[2px] ${currentStep > 1 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'} mx-2`}></div>

                                    {/* Step 2 */}
                                    <div className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 1 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'}`}>
                                        <p className={`text-xs font-medium ${currentStep > 1 ? 'text-white' : '#313119'}`}>2</p>
                                    </div>
                                    <div className={`flex-grow h-[2px] ${currentStep > 2 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'} mx-2`}></div>

                                    {/* Step 3 */}
                                    <div className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 2 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'}`}>
                                        <p className={`text-xs font-medium ${currentStep > 2 ? 'text-white' : '#313119'}`}>3</p>
                                    </div>
                                    <div className={`flex-grow h-[2px] ${currentStep > 3 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'} mx-2`}></div>

                                    {/* Step 4 */}
                                    <div className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 3 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'}`}>
                                        <p className={`text-xs font-medium ${currentStep > 3 ? 'text-white' : '#313119'}`}>4</p>
                                    </div>
                                </div>
                            )}

                            {/* stepper content */}
                            <div className="h-auto flex-grow overflow-y-auto">
                                <div className="flex justify-between">
                                    {steps.map((step, index) => (
                                        <div key={index} className={`step ${currentStep === index ? 'active' : ''}`}>
                                            {/* {step} */}
                                        </div>
                                    ))}
                                </div>

                                <div className="relative step-content">
                                    {currentStep === 0 && (
                                        <div>
                                            {/* Header Section */}
                                            <div className=" lg:grid lg:grid-cols-2 flex items-center flex-col-reverse lg:px-8 lg:py-4 shadow-md sticky top-0 bg-white z-10">
                                                <div >
                                                    <div className="lg:flex items-center hidden  ">
                                                        <h1 className="text-[28px] text-[#434343] font-semibold ">Add Proposal: Rs. {overallTotal.toFixed(2)}</h1>
                                                        <span className="text-sm bg-[#E9E9E9] ml-4 px-4 py-2 text-[#434343]">Draft</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-4 sticky top-0 flex items-center justify-center shadow-md">
                                                <div >
                                                    <div className="flex items-center space-x-2">
                                                        <span class="relative flex items-center justify-center h-4 w-4 ">
                                                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-lime-500 opacity-40"></span>
                                                            <span class="relative inline-flex rounded-full h-2 w-2 bg-lime-500"></span>
                                                        </span>
                                                        <h3 className="text-sm lg:mt-1 text-center text-[#737373]">
                                                            Quotation Rank:{" "}
                                                            <span className="font-bold text-black">
                                                                {currentRank === "L1"
                                                                    ? "L2"
                                                                    : currentRank === undefined || currentRank === null
                                                                        ? "Quotation not submitted"
                                                                        : currentRank}
                                                            </span>
                                                        </h3>
                                                        {/* <p className="text-[#9F9F9F] lg:mt-1 text-xs italic hidden lg:block">Your rank will update as soon as the buyer receives atleast 3 ratings</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* rfq information */}
                                            <div className="px-4 lg:py-[24px] lg:px-8">
                                                <div className=" mt-4 mb-1 lg:hidden flex justify-between space-x-10">
                                                    {/* <h3 className="text-xl font-semibold text-[#3B3B3B]">{rfqData?.name}</h3> */}
                                                    <h3 className="text-sm font-semibold text-[#3B3B3B]"><span className="text-[#787878]">Requirement Name:</span> {rfqData?.name}</h3>
                                                    {/* <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Bookmark.svg" alt="save" className="self-start" /> */}
                                                </div>
                                                <p className="text-sm font-normal text-[#787878] lg:hidden">Create Date: {new Date(rfqData?.createdAt).toLocaleDateString('en-GB')}</p>
                                                <p className="text-sm font-medium text-[#787878] mb-2 mt-6 lg:hidden">RFQ Information</p>
                                                <div className="border sm:grid sm:grid-cols-2 space-y-4 sm:space-y-0 shadow-lg bg-white p-5 lg:py-[28px] lg:px-[20px]">
                                                    <div className="space-y-4">
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">Status</p>
                                                            <div className="flex items-center space-x-2">
                                                                <div className="w-[15px] h-[15px] rounded-full bg-[#BFFFBA]"></div>
                                                                <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.status}</p>
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <p className="text-[#6B7280] text-sm font-normal">RFQ Name</p>
                                                            <h3 className="text-sm font-semibold text-[#3B3B3B]">{rfqData?.name}</h3>
                                                        </div>
                                                        {/* <div className=" mt-4 mb-1 lg:hidden">
                                                              <p className="text-[#6B7280] text-sm font-normal">RFQ Type</p>
                                                              <h3 className="text-xl font-semibold text-[#3B3B3B]">{rfqData?.rfqType}</h3>
                                                              <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Bookmark.svg" alt="save" className="self-start" />
                                                             </div> */}
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">RFQ Type</p>
                                                            <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.rfqType}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">Client</p>
                                                            <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.userBusinessName}</p>
                                                        </div>
                                                    </div>
                                                    <div className="space-y-4">
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">Expected Delivery Date</p>
                                                            <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.providingDate ? new Date(rfqData?.providingDate).toLocaleDateString('en-GB') : "-"}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">Project Name</p>
                                                            <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.projectName}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">Delivery Location</p>
                                                            <div className="flex items-center gap-2">
                                                                <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/map_pin_vendor_quotation.svg" alt="" className="mb-1" />
                                                                <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.location}</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal  ">Contact</p>
                                                            <p className="text-[#4F4F42] text-sm font-bold ">{rfqData?.requesterName} ({rfqData?.requesterMobile})</p>
                                                        </div>
                                                        {/* <div>
                                                        <p className="text-[#6B7280] text-sm font-normal">Payment Terms</p>
                                                        <p className="text-[#4F4F42] text-sm word-break whitespace-normal max-h-[80px] overflow-y-auto font-bold bg-[#ECECEC] lg:bg-white mt-1 p-4 lg:m-0 lg:p-0">{rfqData?.paymentTerms}</p>
                                                    </div> */}
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                <div className="flex flex-col items-end m-6">
                                                    <div className="flex space-x-4">
                                                        <button
                                                            type="button"
                                                            onClick={handleDownloadExcel}
                                                            className="bg-blue-500 text-white px-3 py-1.5 text-xs"
                                                        >
                                                            Download Excel
                                                        </button>

                                                        <label className="border border-gray-800 px-3 py-1.5 cursor-pointer text-xs">
                                                            Upload Excel
                                                            <input
                                                                type="file"
                                                                accept=".xlsx"
                                                                onChange={(e) => handleUploadExcel(e, setFieldValue, values)}
                                                                className="hidden"
                                                            />
                                                        </label>
                                                    </div>
                                                    <p className="text-xs text-gray-500 mt-2">Note: Download Excel to upload material list</p>
                                                </div>
                                            }


                                        </div>
                                    )}
                                    <div className="px-4 py-6">
                                        {currentStep === 1 && (
                                            <div>
                                                {/* input fields */}
                                                <div>
                                                    <h3 className="text-sm font-semibold text-[#3B3B3B] lg:hidden">Material List</h3>
                                                    <p className="text-xs font-normal text-[#9D9D9D] lg:hidden">Add quotations for the Material List below</p>

                                                    <div className="space-y-2 mt-4 mb-8">
                                                        {rfqData?.materials?.map((row, index) => (
                                                            <div key={index}>
                                                                {/* Mobile Accordion Header */}
                                                                <div className={`block lg:hidden border shadow-md py-4 px-4 cursor-pointer ${openAccordian === index ? 'shadow-none' : ''}`} onClick={() => toggleAccordion(index)}>
                                                                    <div className="flex justify-between items-center">
                                                                        <div className="flex justify-start items-center space-x-4">
                                                                            <div className="w-[24px] h-[24px] bg-[#EFEFEB] rounded-full flex justify-center items-center">
                                                                                <p className="text-xs text-[#6A6A6A]">{index + 1}</p>
                                                                            </div>
                                                                            <h2 className="text-sm font-semibold">{row?.name}</h2>
                                                                        </div>
                                                                        <div className="flex justify-end space-x-4">
                                                                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/tick_vendor_quotation.svg" alt="tick" />
                                                                            <img
                                                                                src="https://storagereponeevaydevcdn.blob.core.windows.net/business/dropdown_arrow.svg"
                                                                                alt=""
                                                                                className={`ml-2 transition-transform ${openAccordian === index ? 'rotate-180' : ''}`}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* Accordion Content */}
                                                                {openAccordian === index && (
                                                                    <div className="space-y-4 py-4 px-4 shadow-md border border-t-transparent">

                                                                        {/* quantity */}
                                                                        <div className="self-end">
                                                                            <label htmlFor="" className="text-sm pb-2 font-semibold lg:hidden">Brand</label>
                                                                            <p className="text-sm col-span-1">{row.brands.join(", ")}</p>
                                                                        </div>

                                                                        {/* quantity */}
                                                                        <div className="self-end">
                                                                            <label htmlFor="" className="text-sm pb-2 font-semibold lg:hidden">Quantity</label>
                                                                            <p className="text-sm col-span-1">{row.quantity}</p>
                                                                        </div>

                                                                        {/* specifications */}
                                                                        {row?.specifications?.length > 0 && <div className="self-end col-span-2">
                                                                            <label htmlFor="" className="text-sm font-semibold pb-2 lg:hidden">Specifications</label>
                                                                            <p className="text-sm self-end  whitespace-pre-line text-[#626262] lg:text-[#434343]">{row.specifications}</p>
                                                                        </div>}

                                                                        {/* mrp */}
                                                                        <div className="col-span-2 flex flex-col">
                                                                            <label className="font-semibold text-sm pb-2 col-span-2 flex items-center relative">
                                                                                MRP
                                                                                <span className="bg-[#cac9c9] text-white text-xs h-[15px] w-[15px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group hover:cursor-pointer">
                                                                                    i
                                                                                    <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] z-10 transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                                        Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                                                    </span>
                                                                                </span>
                                                                            </label>

                                                                            <Field
                                                                                name={`materials[${index}].basePrice`}
                                                                                type="text"
                                                                                className="lg:w-full w-[220px] text-sm h-[40px] border-[#DCDCD0] border px-2 py-1"
                                                                                onChange={(e) => handleFieldChange(index, 'basePrice', e.target.value, values)}
                                                                            />
                                                                            <ErrorMessage name={`materials[${index}].basePrice`} component="div" className="text-red-500 text-sm" />
                                                                        </div>
                                                                        <div className="flex justify-between space-x-4 items-center">
                                                                            {/* discount */}
                                                                            <div className="col-span-2 self-start flex w-1/2 flex-col">
                                                                                <label className="font-semibold text-sm pb-1 col-span-2 flex items-center relative">
                                                                                    Discount (%)
                                                                                    <span className="bg-[#cac9c9] text-white text-xs h-[15px] w-[15px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group hover:cursor-pointer">
                                                                                        i
                                                                                        <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] z-10 transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                                            Specify the discount percentage or amount offered on this material. This discount will be applied to the unit cost and reflected in the final pricing
                                                                                        </span>
                                                                                    </span>
                                                                                </label>
                                                                                <Field
                                                                                    name={`materials[${index}].discount`}
                                                                                    type="text"
                                                                                    className=" h-[40px] border-[#DCDCD0] border px-2 py-1"
                                                                                    onChange={(e) => handleFieldChange(index, 'discount', e.target.value, values)}
                                                                                />
                                                                                <ErrorMessage name={`materials[${index}].discount`} component="div" className="text-red-500 text-sm" />
                                                                            </div>

                                                                            {/* gst */}
                                                                            <div className="col-span-1 self-start w-1/2">
                                                                                <label htmlFor="" className="text-sm  pb-2 font-semibold lg:hidden">GST (%)</label>
                                                                                <CustomGstDropdown
                                                                                    options={[0, 5, 12, 18, 28]}
                                                                                    value={values.materials[index].gst}  // Display the current value
                                                                                    onChange={(value) => handleFieldChange(index, 'gst', value, values)}
                                                                                    className=" h-[40px] text-sm"
                                                                                />
                                                                                <ErrorMessage name={`materials[${index}].gst`} component="div" className="text-red-500 text-sm h-fit" />
                                                                            </div>
                                                                        </div>
                                                                        {/* delivery date */}
                                                                        <div className="col-span-2 self-end">
                                                                            <label htmlFor="" className="text-sm pb-2 font-semibold lg:hidden">Delivery Date</label>
                                                                            <Field
                                                                                type="date"
                                                                                id={`deliveryDate-${index}`}
                                                                                name={`materials[${index}].deliveryDate`}
                                                                                className="shadow-sm block h-[40px] lg:w-full w-[220px] text-sm px-4 py-2 border border-gray-300 focus:outline-none focus:ring-sky-500 focus:ring-width-1"
                                                                                onChange={(e) => handleFieldChange(index, 'deliveryDate', e.target.value, values)} // Ensure you have this function defined
                                                                            />
                                                                            <ErrorMessage
                                                                                name={`materials[${index}].deliveryDate`}
                                                                                component="div"
                                                                                className="text-red-500 text-xs"
                                                                            />
                                                                        </div>

                                                                        <div className=" flex justify-start space-x-4 items-center text-center col-span-1 mt-2">
                                                                            <p className="text-sm pb-2 font-semibold ">Add Attachment</p>
                                                                            <div className="relative">
                                                                                <AttachFileIcon
                                                                                    className="hover:cursor-pointer"
                                                                                    sx={{
                                                                                        width: "0.7em", ml: "2px", mb: "8px",
                                                                                        transform: 'rotate(45deg)',
                                                                                        transition: 'transform 0.3s ease'
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setSelectedMaterialIndex(index); // Set the labor index
                                                                                        setOpenUploadFile(true); // Open the file upload popup
                                                                                    }}
                                                                                />

                                                                                {values.materials[index]?.attachments?.length > 0 && (
                                                                                    <span
                                                                                        className="absolute top-0 right-2 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center"
                                                                                        style={{ transform: 'translate(50%, -50%)' }}
                                                                                    >
                                                                                        {values.materials[index].attachments.length}
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <label htmlFor="" className="text-sm pb-2 font-semibold lg:hidden">Remarks</label>
                                                                            <Field
                                                                                name={`materials[${index}].remark`}
                                                                                type="text"
                                                                                className="w-full h-[40px] text-xs border-[#DCDCD0] border px-2 py-1"
                                                                                placeholder=""
                                                                                onChange={e => handleFieldChange(index, 'remark', e.target.value, values)}
                                                                            />
                                                                            <ErrorMessage name={`materials[${index}].remark`} component="div" className="text-red-500 text-xs absolute" />
                                                                        </div>

                                                                        {/* amount */}
                                                                        <div className="w-full p-4 flex justify-between items-center bg-[#E7EAEF] border-t-[#004491] lg:bg-white lg:border-none">
                                                                            <label htmlFor="" className="text-base text-[#004491] font-semibold lg:hidden">Amount</label>
                                                                            <p className="text-right text-sm self-end text-[#004491] lg:text-black col-span-1">Rs. {totalCosts[index]?.toFixed(2)}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {currentStep === 2 && (
                                            <div>
                                                {/* miscellaneous */}
                                                <div>
                                                    <h3 className="text-sm font-semibold text-[#3B3B3B] lg:hidden">Other Charges</h3>
                                                    <p className="text-xs font-normal text-[#9D9D9D] pb-4 lg:hidden">Please indicate any miscellaneous charges that will be incurred</p>

                                                    <div className="border lg:border-none shadow-md px-3 pb-3 pt-4 space-y-4 lg:space-y-2">
                                                        {/* Mapping miscellaneousItems instead of charges */}
                                                        {visibleMiscellaneousItems.map((item, index) => (
                                                            <div key={index}>
                                                                {/* MRP and GST inputs for mobile */}
                                                                <div className="flex justify-between items-center lg:hidden pb-2">
                                                                    <div >
                                                                        <p className=" text-sm">{item.label}</p>
                                                                        <Field
                                                                            name={`miscellaneousCosts.${item.name}.mrp`}
                                                                            type="text"
                                                                            className="h-[40px] w-[120px] border border-gray-300"
                                                                            placeholder="Enter MRP"
                                                                            onChange={(e) => handleMiscellaneousChange(item.name, e.target.value, setFieldValue, values, 'mrp')}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <p className=" text-sm">GST</p>
                                                                        <CustomGstDropdown
                                                                            options={[0, 5, 12, 18, 28]}
                                                                            value={values.miscellaneousCosts[item.name]?.gst}
                                                                            name={`miscellaneousCosts.${item.name}.gst`}
                                                                            className="col-span-1 w-[64px] h-[40px]"
                                                                            onChange={(value) => handleGstChange(item.name, value, setFieldValue, values)}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                {/* Subtotal display */}
                                                                <div className="flex justify-between lg:justify-end items-center py-2 px-3 bg-[#E7EAEF] border border-t-[#004491] lg:border-none lg:bg-white">
                                                                    <p className="text-[#004491] text-sm font-semibold lg:hidden">Sub Total</p>
                                                                    <p className="text-[#004491] font-semibold lg:font-normal col-span-1 lg:text-black lg:text-right">
                                                                        Rs. {values.miscellaneousCosts[item.name].totalCost || 0}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ))}

                                                        {/* Total amount for mobile */}
                                                        <div className="lg:hidden">
                                                            <div className="flex px-4 py-2 lg:p-0 justify-between items-center border lg:border-none border-t-[#007366] bg-[#E0F0EE] lg:bg-white">
                                                                <p className="lg:font-bold text-[#007366] lg:text-black font-semibold">Total Amount</p>
                                                                <p className="bg-[#E0F0EE] text-[#007366] font-semibold text-end lg:font-normal lg:text-black w-fit min-w-[164px] lg:px-4 py-2">
                                                                    Rs. {overallTotal || 0}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {currentStep === 3 && (
                                            <div>
                                                {/* Supporting Documents Section */}
                                                <div className="mb-8 lg:mb-[40px]">
                                                    <h3 className="text-sm font-semibold text-[#3B3B3B] lg:hidden">Supporting Documents</h3>
                                                    <p className="text-xs font-normal text-[#9D9D9D] pb-4 lg:hidden">Below are the supporting documents uploaded by the buyer. Please review them.</p>
                                                    {attachmentsData.length > 0 ? (
                                                        <>
                                                            <div className="lg:grid lg:grid-cols-4 lg:gap-4 space-y-4 lg:space-y-0 lg:mt-4">
                                                                {attachmentsData.map((doc, index) => (
                                                                    <div key={index} className="flex justify-between items-center">
                                                                        <div className="flex w-fit items-center justify-center border border-[#8E8E85] rounded-full py-2 px-2 space-x-2 text-[#434343]">
                                                                            {/* Dynamic icon based on file type */}
                                                                            <img src={`/assets/icons/${doc.Path}.png`} alt={doc.Path} className="h-5 w-5" />
                                                                            {/* Document name clickable */}
                                                                            <a
                                                                                href={doc.Content}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                download={doc.Name}
                                                                                className="text-xs text-[#434343] hover:underline"
                                                                            >
                                                                                {doc.Name}
                                                                            </a>
                                                                        </div>
                                                                        <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/download_solid.svg" alt="" className="lg:hidden" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                    ) :

                                                        <>
                                                            <div className="text-gray-400 italic">No Supporting Documents provided by Buyer, move to next step </div>
                                                        </>

                                                    }
                                                </div>
                                            </div>
                                        )}
                                        {currentStep === 4 && (
                                            <div>
                                                {/* Additional Information Section */}
                                                <div className="mb-8">

                                                    <h3 className="text-xl lg:mx-0 font-semibold text-[#3B3B3B] lg:hidden">Additional Information</h3>
                                                    <p className="text-sm font-normal lg:mx-0 py-4 lg:hidden">Below are our payment Terms. </p>

                                                    <div className="lg:flex justify-between items-center">
                                                        <div>
                                                            <div className="bg-[#F6F6F4] py-2 px-4 lg:w-[390px] lg:h-[182px] lg:mx-0 " >
                                                                <p
                                                                    className="w-full border-none p-0 bg-[#F6F6F4] text-[#3D3D3D] text-sm"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            rfqData?.paymentTerms.length > 210
                                                                                ? rfqData.paymentTerms.slice(0, 210) + "..."
                                                                                : rfqData.paymentTerms,
                                                                    }}
                                                                ></p>

                                                                {rfqData?.paymentTerms.length > 210 && <button
                                                                    onClick={togglePopup}
                                                                    type="button"
                                                                    className="text-[#8E8E85] text-sm underline pt-2"
                                                                >
                                                                    Read More
                                                                </button>}

                                                                {/* Popup Modal */}
                                                                {isPopupOpen && (
                                                                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">

                                                                        <div className="bg-white border-[25px] mx-4 border-white rounded-lg shadow-lg max-w-md w-full h-[320px] relative" style={{ overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                                                            <style>{` .hide-scrollbar {-ms-overflow-style: none; /* Internet Explorer 10+ */
                                                                          scrollbar-width: none; /* Firefox */
                                                                             }
                                                                          .hide-scrollbar::-webkit-scrollbar {
                                                                           display: none; /* Safari and Chrome */
                                                                           }
                                                                         `}
                                                                            </style>
                                                                            <div className="sticky top-0 bg-white pb-4">
                                                                                {/* Close Button */}
                                                                                <button
                                                                                    onClick={togglePopup}
                                                                                    className="absolute top-0 right-0 text-gray-400 hover:text-gray-600"
                                                                                >
                                                                                    <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                                                    </svg>
                                                                                </button>
                                                                                <h2 className="text-lg font-semibold">Payment Details</h2>
                                                                                <hr />
                                                                            </div>
                                                                            <p className="text-sm text-[#3D3D3D] overflow-y-auto " dangerouslySetInnerHTML={{ __html: rfqData?.paymentTerms }}>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="lg:hidden mx-4 justify-between items-center py-4">
                                                            <p >
                                                                Do you agree ?
                                                            </p>
                                                            <div className="flex space-x-12 mt-2">
                                                                <label className="inline-flex items-center">
                                                                    <input type="radio" name="paymentTerms" className="form-radio" />
                                                                    <span className="ml-2">Yes</span>
                                                                </label>
                                                                <label className="inline-flex items-center">
                                                                    <input type="radio" name="paymentTerms" className="form-radio" />
                                                                    <span className="ml-2">Counter Offer</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className="font-semibold text-[#4F4F42]">Counter Payment Terms</p>
                                                            <Field
                                                                as="textarea"
                                                                name="counterPaymentTerms"
                                                                className="lg:w-[390px] w-full h-[144px] my-2 border border-gray-300 placeholder:italic"
                                                                placeholder="Please write your counter payment terms Rich Text "
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className=" lg:flex mt-4 lg:mt-8  mx-4 lg:mx-0">
                                                        <p className="text-[#434343] mb-2 lg:mb-0">Notes</p>
                                                        <Field
                                                            as="textarea"
                                                            name="notes"
                                                            placeholder="Add Notes (Optional) "
                                                            className="border-[#CDCDB8] w-full lg:ml-5 lg:w-full h-[125px] placeholder:italic placeholder-[#8C8C8C]"
                                                        />
                                                    </div>
                                                </div>

                                                {/* Upload Supporting Documents */}
                                                <div className="mb-8  mx-4 lg:mx-0">
                                                    <h3 className="whitespace-nowrap text-[#434343] text-xl font-bold pb-4 lg:pb-0 lg:mt-[60px] lg:mb-[30px]">Upload Supporting Documents</h3>

                                                    {attachmentsSkeleton.length > 0 && (
                                                        <p className="text-xs pl-3">
                                                            Your file is being scanned for upload, be patient and continue to fill
                                                            the rest of the form.
                                                        </p>
                                                    )}

                                                    <div className="">
                                                        {/* Display the selected file names */}
                                                        <ul style={{ display: "flex", flexWrap: "wrap" }}>
                                                            {selectedFilesBase.map((file, index) => (
                                                                <a
                                                                    href={file.Content}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="border border-gray-300 px-3 py-1 rounded-full flex mb-4 ml-10"
                                                                    key={file.Name}
                                                                >
                                                                    <span className="text-sm overflow-hidden whitespace-nowrap w-36 truncate">
                                                                        {file.Name}
                                                                    </span>
                                                                    <span
                                                                        className="ml-1 cursor-pointer"
                                                                        onClick={(event) => handleDeleteSelectedFile(file.Name, event)}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="18"
                                                                            height="18"
                                                                            viewBox="0 0 48 48"
                                                                        >
                                                                            <circle cx="24" cy="24" r="22" fill="gray" />
                                                                            <line
                                                                                x1="15"
                                                                                y1="15"
                                                                                x2="33"
                                                                                y2="33"
                                                                                stroke="white"
                                                                                strokeWidth="2"
                                                                            />
                                                                            <line
                                                                                x1="33"
                                                                                y1="15"
                                                                                x2="15"
                                                                                y2="33"
                                                                                stroke="white"
                                                                                strokeWidth="2"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                </a>
                                                            ))}

                                                            {/* Skeleton loaders for files being processed */}
                                                            {attachmentsSkeleton.map((_, index) => (
                                                                <Skeleton
                                                                    key={index}
                                                                    variant="rounded"
                                                                    sx={{ borderRadius: "20px", margin: "4px" }}
                                                                    width={150}
                                                                    height={32}
                                                                />
                                                            ))}
                                                        </ul>
                                                    </div>

                                                    <div>
                                                        {attachmentsUploadsErrors && (
                                                            <div className="mt-3">
                                                                <p className="text-red-500 font-semibold text-sm mt-14">
                                                                    {attachmentsUploadsErrors}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>

                                                    {/* Error handling for file size */}
                                                    <div
                                                        className={`fixed bottom-0 left-2  p-4 ${openFilesSizeError ? "block" : "hidden"
                                                            }`}
                                                    >
                                                        <div className="bg-orange-100 w-[440px] flex items-center justify-between  px-4 py-1 text-yellow-900 rounded-lg shadow-md p-4">
                                                            <p>Total size of attachments is not more than 10 MB</p>
                                                            <button
                                                                onClick={handleCloseFileSizeError}
                                                                className="px-4 py-2 focus:outline-none"
                                                            >
                                                                <svg
                                                                    className="h-6 w-6"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="2"
                                                                        d="M6 18L18 6M6 6l12 12"
                                                                    ></path>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>


                                                    {/* drag and drop file */}
                                                    <div className="lg:ml-14 lg:mr-0 flex items-center justify-center h-[175px] lg:h-[301px] bg-[#F1F7FF] lg:bg-white border-2 border-dashed border-[#004491] lg:border-[#7D7D7D] relative">
                                                        <div className="lg:hidden flex flex-col items-center justify-center">
                                                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_upload.svg" alt="" className="mx-auto" />
                                                            <p className="text-center mt-4">Upload your files here</p>
                                                            <label htmlFor="file" className="text-[#1D28FA] underline text-center cursor-pointer">
                                                                Browse
                                                            </label>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.xls,.csv,.dwg" // Specify allowed file types
                                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                                            onClick={handleFileUploadClick}
                                                            onChange={handleFileUpload}
                                                            multiple // Allow multiple files to be selected
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {currentStep === 5 &&

                                            (<div className="text-gray-400 italic">No Supporting Documents provided by Buyer, move to next step </div>)

                                        }
                                    </div>
                                </div>


                            </div>
                        </div>

                        {/* desktop view */}
                        <div className="hidden lg:block">
                            {/* Header Section */}
                            <div className="grid grid-cols-3 w-full items-center lg:px-8 lg:py-1 shadow-md lg:sticky lg:top-0 bg-white z-20">
                                <div >
                                    <div className="lg:flex items-center hidden">
                                        {/* <h1 className="text-[24px] text-[#434343] font-semibold ">Add Proposal: Rs. {overallTotal.toFixed(2)}</h1> */}
                                        {/* <span className="text-sm bg-[#E9E9E9] ml-4 px-4 py-2 text-[#434343]">Draft</span> */}
                                        <button type="button" onClick={(() => isEdit ? navigate(-1) : (supplierId ? navigate("/leads") : navigate(-1)))} className="text-sm bg-[#E9E9E9] cursor:pointer px-4 py-1 my-1 text-[#434343]">Back</button>
                                    </div>
                                </div>
                                {/* <div className="flex justify-center items-center lg:items-start lg:justify-start my-4">
                                    <span class="relative flex items-center justify-center h-9 w-9 ">
                                     <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-lime-500 opacity-75"></span>
                                     <span class="relative inline-flex rounded-full h-[18px] w-[18px] bg-lime-500"></span>
                                     </span>

                                    <div className="lg:w-9 lg:h-9 w-6 h-6 mx-3 flex justify-center items-center rounded-full bg-[#DFF1C8] opacity-95">
                                        <div className="lg:w-[18px] lg:h-[18px] w-3 h-3 rounded-full bg-lime-500"></div>
                                    </div>
                                    </div> */}
                                <div className="flex items-center justify-center space-x-2">
                                    <span class="relative flex items-center justify-center h-4 w-4 ">
                                        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-lime-500 opacity-40"></span>
                                        <span class="relative inline-flex rounded-full h-2 w-2 bg-lime-500"></span>
                                    </span>
                                    <h3 className="text-sm lg:mt-1 text-center text-[#737373]">
                                        Quotation Rank:{" "}
                                        <span className="font-bold text-black">
                                            {currentRank === "L1"
                                                ? "L2"
                                                : currentRank === undefined || currentRank === null
                                                    ? "Quotation not submitted"
                                                    : currentRank}
                                        </span>
                                    </h3>
                                    {/* <p className="text-[#9F9F9F] lg:mt-1 text-xs italic hidden lg:block">Your rank will update as soon as the buyer receives atleast 3 ratings</p> */}
                                </div>
                            </div>

                            <div className="lg:px-8 lg:space-y-6 bg-[#F6F6F4] pb-8">
                                {/* rfq information */}
                                <div className="hidden lg:block lg:pt-[15px]">
                                    <div className=" mt-4 mb-1 lg:hidden flex justify-between space-x-10">
                                        <h3 className="text-xl font-semibold text-[#3B3B3B]">{rfqData?.name}</h3>
                                        <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Bookmark.svg" alt="save" className="self-start" />
                                    </div>
                                    <div className="shadow-md bg-white border px-8 lg:py-[20px]">
                                        <h3 className="text-sm pb-2 font-semibold hidden lg:block">RFQ Information</h3>
                                        <p className="text-sm font-medium text-[#787878] mb-2 mt-6 lg:hidden">RFQ Summary</p>
                                        <div className="text-xs flex flex-col-reverse lg:grid lg:grid-cols-3 lg:gap-x-8 lg:gap-y-4">
                                            <div className="lg:space-y-5 space-y-4">
                                                <div>
                                                    <p className="text-[#6B7280] font-normal hidden lg:block">RFQ Name</p>
                                                    <p className="text-[#4F4F42] font-bold hidden lg:block">{rfqData?.name}</p>
                                                </div>

                                                <div>
                                                    <p className="text-[#6B7280] font-normal hidden lg:block">Created Date</p>
                                                    <p className="text-[#4F4F42] font-bold hidden lg:block">{new Date(rfqData?.createdAt).toLocaleDateString('en-GB')}</p>
                                                </div>

                                                <div>
                                                    <p className="text-[#6B7280] font-normal  ">Client Name</p>
                                                    <p className="text-[#4F4F42] font-bold ">{rfqData?.userBusinessName}</p>
                                                </div>
                                                {/* <div>
                                                    <p className="text-[#6B7280] font-normal">Payment Terms</p>
                                                    <p
                                                        className="text-[#4F4F42] font-bold bg-[#ECECEC] lg:bg-white mt-1 p-4 lg:m-0 lg:p-0"
                                                        dangerouslySetInnerHTML={{ __html: rfqData?.paymentTerms }}
                                                    ></p>

                                                </div> */}

                                            </div>
                                            <div className="lg:space-y-5 space-y-4">
                                                <div>
                                                    <p className="text-[#6B7280] font-normal  hidden lg:block">Requirement Type</p>
                                                    <p className="text-[#4F4F42] font-bold  hidden lg:block">{capitalizeFirstLetter(rfqData?.rfqType)}</p>
                                                </div>
                                                <div>
                                                    <p className="text-[#6B7280] font-normal">Project Name</p>
                                                    <p className="text-[#4F4F42] font-bold">{rfqData?.projectName}</p>
                                                </div>
                                                <div>
                                                    <p className="text-[#6B7280] font-normal  hidden lg:block">Expected Delivery Date</p>
                                                    <p className="text-[#4F4F42] font-bold hidden lg:block">{rfqData?.providingDate ? new Date(rfqData?.providingDate).toLocaleDateString('en-GB') : "-"}</p>
                                                </div>
                                            </div>
                                            <div className="lg:space-y-5 space-y-4">
                                                <div>
                                                    <p className="text-[#6B7280] font-normal">Status</p>
                                                    <div className="flex items-center space-x-2">
                                                        <div className="w-[15px] h-[15px] rounded-full bg-[#BFFFBA]"></div>
                                                        <p className="text-[#4F4F42] font-bold">{rfqData?.status}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="text-[#6B7280] font-normal  ">Contact</p>
                                                    <p className="text-[#4F4F42] font-bold ">{rfqData?.requesterName} ({rfqData?.requesterMobile})</p>
                                                </div>
                                                <div>
                                                    <p className="text-[#6B7280] font-normal">Delivery Location</p>
                                                    <div className="flex items-center gap-2">
                                                        <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/map_pin_vendor_quotation.svg" alt="" className="mb-1" />
                                                        <p className="text-[#4F4F42] font-bold">{rfqData?.location}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Material Section */}
                                <div>
                                    {/* line items label */}
                                    {/* <h3 className="text-xl pb-2 font-semibold text-[#434343] hidden lg:block">Line Items</h3> */}
                                    {/* material */}
                                    <div className="lg:flex items-center hidden">
                                        <h3 className="whitespace-nowrap text-[#434343] uppercase text-sm font-semibold">Material List</h3>
                                        <hr className="flex-grow border-t border-gray-300 ml-4" />
                                    </div>
                                    <div className="flex justify-between items-center my-1">
                                        <p className="text-xs font-medium text-[#434343] hidden lg:block">
                                            Your bid value is the sum of these line items
                                        </p>
                                        {
                                            <div className="flex flex-col items-end space-y-2">
                                                <div className="flex space-x-4">
                                                    <button
                                                        type="button"
                                                        onClick={handleDownloadExcel}
                                                        className="bg-blue-500 text-white px-3 py-1 text-xs"
                                                    >
                                                        Download Excel
                                                    </button>

                                                    <label className="border border-gray-800 px-3 py-1 cursor-pointer text-xs">
                                                        Upload Excel
                                                        <input
                                                            type="file"
                                                            accept=".xlsx"
                                                            onChange={(e) => handleUploadExcel(e, setFieldValue, values)}
                                                            className="hidden"
                                                        />
                                                    </label>
                                                </div>
                                                <p className="text-[10px] italic text-gray-500">Note: Download Excel to upload material list</p>
                                            </div>
                                        }
                                    </div>

                                    <div className="hidden lg:grid lg:grid-cols-17 items-center gap-2 text-[#777777] text-sm font-normal uppercase border-y py-2">
                                        <label className="block font-medium col-span-1 text-xs">Sr.No.</label>
                                        <label className="block font-medium col-span-1 text-xs">Product</label>
                                        <label className="block font-medium col-span-1 text-xs">Brand</label>
                                        <label className="block font-medium col-span-1 text-xs">Qty.</label>
                                        <label className="block font-medium col-span-2 text-xs">Specifications</label>
                                        <label className="font-medium text-xs text-start col-span-2 flex justify-center items-center relative">
                                            MRP (Rs.)
                                            <span className="bg-[#cac9c9] text-white text-xs h-[12px] w-[12px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group hover:cursor-pointer">
                                                i
                                                <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                    Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                </span>
                                            </span>
                                        </label>

                                        <label className="font-medium text-xs text-right justify-center items-center col-span-1 flex relative">
                                            Disc (%)
                                            <span className="bg-[#cac9c9] text-white text-xs h-[12px] w-[12px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group hover:cursor-pointer">
                                                i
                                                <span className="absolute bottom-[130%] text-xs left-1/2 w-[220px] transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                    Specify the discount percentage or amount offered on this material. This discount will be applied to the unit cost and reflected in the final pricing
                                                </span>
                                            </span>
                                        </label>

                                        <label className="block font-medium text-end col-span-1 text-xs">Gst (%)</label>
                                        <label className="block text-center font-medium col-span-2 text-xs">Delivery Date</label>

                                        <label className="block font-medium col-span-2 text-right text-xs">Total Cost (Rs.)</label>
                                        <label className="block font-medium col-span-2 text-xs pl-2">Remarks</label>
                                        <label className="block font-medium text-center col-span-1 text-xs">Attach.</label>
                                    </div>
                                    <div className="lg:space-y-6 space-y-6 pt-4 my-4 lg:my-0">
                                        {rfqData?.materials.map((row, index) => (
                                            <div key={index}>
                                                {/* desktop */}
                                                <div className="hidden lg:grid lg:grid-cols-17 gap-4 text-[#373737]">

                                                    <p className="text-xs col-span-1 text-center whitespace-pre-line">{index + 1}</p>

                                                    <p className="text-xs col-span-1 whitespace-pre-line">{row.name}</p>

                                                    <p className="text-xs col-span-1 break-words whitespace-pre-line">{row?.brands.join(", ")}</p>
                                                    <div className="relative group">
                                                        <p className="text-xs col-span-1 cursor-pointer mr-1 break-words">
                                                            {row.quantity} {row.unit}
                                                        </p>
                                                    </div>

                                                    <div className=" col-span-2">
                                                        <p className="text-xs font-light whitespace-pre-line text-[#626262] lg:text-[#686767]">{row.specifications}</p>
                                                    </div>



                                                    <div className="col-span-2 flex flex-col">
                                                        <Field
                                                            name={`materials[${index}].basePrice`}
                                                            type="text"
                                                            className={`lg:w-full w-[220px] h-[40px] text-right text-xs border-[#DCDCD0] border px-2 py-1 ${isReadOnly && "bg-gray-100"}`}
                                                            onChange={(e) => handleFieldChange(index, 'basePrice', e.target.value, values)}
                                                            disabled={isReadOnly && true}
                                                        />
                                                        <ErrorMessage name={`materials[${index}].basePrice`} component="div" className="text-red-500 text-xs " />
                                                    </div>

                                                    <div className="col-span-1  justify-self-center flex flex-col">
                                                        <Field
                                                            name={`materials[${index}].discount`}
                                                            type="text"
                                                            className={`lg:w-full w-[220px] text-xs text-right h-[40px] border-[#DCDCD0] border px-2 py-1 ${isReadOnly && "bg-gray-100"}`}
                                                            onChange={(e) => handleFieldChange(index, 'discount', e.target.value, values)}
                                                            disabled={isReadOnly && true}
                                                        />
                                                        <ErrorMessage name={`materials[${index}].discount`} component="div" className="text-red-500 text-xs" />
                                                    </div>

                                                    <div className="col-span-1  lg:w-full w-[64px]">
                                                        <CustomGstDropdown
                                                            options={[0, 5, 12, 18, 28]}
                                                            value={values?.materials[index]?.gst}  // Display the current value
                                                            onChange={(value) => handleFieldChange(index, 'gst', value, values)}
                                                            className={`w-full lg:w-[64px] text-xs h-[40px]`}
                                                            disabled={isReadOnly && true}
                                                        />
                                                        <ErrorMessage name={`materials[${index}].gst`} component="div" className="text-red-500 text-xs h-fit" />
                                                    </div>

                                                    <div className="col-span-2 ">
                                                        <Field
                                                            type="date"
                                                            id={`deliveryDate-${index}`}
                                                            name={`materials[${index}].deliveryDate`}
                                                            className={`shadow-sm block text-xs h-[40px] lg:w-full w-[220px] px-4 py-2 border border-gray-300 focus:outline-none focus:ring-sky-500 focus:ring-width-1 ${isReadOnly && "bg-gray-100"}`}
                                                            onChange={(e) => handleFieldChange(index, 'deliveryDate', e.target.value, values)} // Ensure you have this function defined
                                                            disabled={isReadOnly && true}
                                                        />
                                                        <ErrorMessage
                                                            name={`materials[${index}].deliveryDate`}
                                                            component="div"
                                                            className="text-red-500 text-xs"
                                                        />
                                                    </div>

                                                    <p className="text-right mt-3  text-xs col-span-2">{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalCosts[index]?.toFixed(0) || 0)}</p>

                                                    <div className="col-span-2 flex flex-col">
                                                        <Field
                                                            as="textarea"
                                                            name={`materials[${index}].remark`}
                                                            rows={1}
                                                            className={`lg:w-full min-h-[40px] text-xs border-[#DCDCD0] border px-2 py-1 resize-none overflow-hidden h-auto ${isReadOnly && "bg-gray-100"}`}
                                                            onChange={(e) => {
                                                                handleFieldChange(index, 'remark', e.target.value, values);

                                                                // Adjust the textarea height
                                                                e.target.style.height = "auto"; // Reset height first
                                                                e.target.style.height = `${e.target.scrollHeight}px`; // Set height based on content
                                                            }}
                                                            disabled={isReadOnly && true}
                                                        />
                                                        <ErrorMessage name={`materials[${index}].remark`} component="div" className="text-red-500 text-xs" />
                                                    </div>
                                                    <div className="relative text-center col-span-1 mt-2">
                                                        <AttachFileIcon
                                                            className="hover:cursor-pointer"
                                                            sx={{
                                                                width: "0.7em", ml: "2px",
                                                                transform: 'rotate(45deg)',
                                                                transition: 'transform 0.3s ease'
                                                            }}
                                                            onClick={() => {
                                                                setSelectedMaterialIndex(index);
                                                                setOpenUploadFile(true);
                                                            }}
                                                        />

                                                        {values.materials[index]?.attachments?.length > 0 && (
                                                            <span
                                                                className="absolute top-0 right-5 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center"
                                                                style={{ transform: 'translate(50%, -50%)' }}
                                                            >
                                                                {values.materials[index].attachments.length}
                                                            </span>
                                                        )}
                                                    </div>

                                                </div>

                                                {index < rfqData?.materials?.length - 1 && <hr className="hidden lg:block  lg:mt-4" />}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Miscellaneous Section */}
                                <div className="p-8 bg-white shadow-md">
                                    {hasVisibleMiscellaneousItems && (
                                        <div className="pb-6">
                                            <div className="hidden lg:block">
                                                <h3 className="text-sm font-semibold text-[#434343] hidden lg:block mb-3">Other Charges</h3>

                                                <div className="text-xs bg-white border py-4 shadow-md lg:border-none lg:mx-0 lg:shadow-none lg:py-0">
                                                    <div className="mb-3 hidden lg:grid lg:grid-cols-6  items-center gap-2 text-[#777777] font-normal uppercase border-y py-2">
                                                        <label className="block font-medium col-span-3">Charges</label>
                                                        <label className="font-medium text-start col-span-1 flex justify-center items-center relative">
                                                            MRP
                                                            <span className="bg-[#cac9c9] text-white text-xs h-[15px] w-[15px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group hover:cursor-default">
                                                                i
                                                                <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                    Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                                </span>
                                                            </span>
                                                        </label>
                                                        <label className="block font-medium text-center col-span-1">GST (%)</label>
                                                        <label className="block font-medium text-right col-span-1">Total Cost</label>
                                                    </div>

                                                    {/* Render visible miscellaneous items */}
                                                    {visibleMiscellaneousItems.map((item) => (
                                                        <div key={item.name} className="lg:grid lg:grid-cols-6 mb-8 lg:mb-0 items-center">
                                                            <label className="block col-span-3 font-semibold lg:font-medium ">
                                                                {item.label}
                                                            </label>

                                                            {/* MRP Input Field */}
                                                            <div className="col-span-1 w-full">
                                                                <Field
                                                                    name={`miscellaneousCosts.${item.name}.mrp`}
                                                                    type="text"
                                                                    className={`w-fit hidden lg:block text-right lg:w-full h-[30px] text-xs placeholder-[#373737] p-2 border border-gray-300 ${isReadOnly && "bg-gray-100"}`}
                                                                    placeholder="Rs."
                                                                    onChange={(e) => handleMiscellaneousChange(item.name, e.target.value, setFieldValue, values, 'mrp')}
                                                                    disabled={isReadOnly && true}
                                                                />
                                                                <ErrorMessage
                                                                    name={`miscellaneousCosts.${item.name}.mrp`}
                                                                    component="div"
                                                                    className="text-red-500 text-xs mt-1"
                                                                />
                                                            </div>

                                                            {/* GST Dropdown */}
                                                            <div className="col-span-1 lg:flex hidden justify-center h-fit lg:w-full w-[262px]">
                                                                <CustomGstDropdown
                                                                    options={[0, 5, 12, 18, 28]}
                                                                    value={values.miscellaneousCosts[item.name]?.gst}
                                                                    onChange={(value) => handleGstChange(item.name, value, setFieldValue, values)}
                                                                    className="lg:w-[64px] w-[262px] justify-between h-[30px]"
                                                                    disabled={isReadOnly && true}
                                                                />
                                                                <ErrorMessage
                                                                    name={`miscellaneousCosts.${item.name}.gst`}
                                                                    component="div"
                                                                    className="text-red-500 text-xs mt-1"
                                                                />
                                                            </div>

                                                            {/* Total Cost */}
                                                            <div className="flex justify-between lg:justify-end items-center border border-t-[#004491] py-3 px-4 bg-[#E7EAEF] lg:border-none lg:bg-white">
                                                                <p className="text-[#004491] font-bold lg:font-normal col-span-1 lg:text-black lg:text-right">
                                                                    Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(values.miscellaneousCosts[item.name]?.totalCost?.toFixed(2)) || '0.00'}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}


                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* Total Amount Section */}
                                    <div className="">
                                        <hr className="hidden lg:block" />
                                        <div className="flex px-4 py-5 lg:p-0 justify-between items-center border lg:border-none border-t-[#007366] bg-[#E0F0EE] lg:bg-white">
                                            <p className="text-[#007366] lg:text-black text-sm">Total Amount</p>
                                            <p className="bg-[#E0F0EE] text-[#007366] text-xs text-end lg:font-normal lg:text-black w-fit min-w-[164px] lg:px-4 py-2">Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(overallTotal.toFixed(2))}</p>
                                        </div>
                                    </div>
                                </div>



                                {/* Supporting Documents Section */}
                                {attachmentsData.length > 0 && (
                                    <div className="mb-8 hidden lg:block bg-white p-8 shadow-md">
                                        <>
                                            <h3 className="text-sm font-semibold text-[#434343] hidden lg:block">Supporting Documents</h3>
                                            <p className="text-xs font-normal text-[#9D9D9D] "> <span className="font-bold text-gray-500">Note: </span>  Below are the supporting documents uploaded by the buyer. Please review them.</p>
                                            <div className="lg:grid lg:grid-cols-4 lg:gap-4 space-y-4 lg:space-y-0 lg:mt-4 mx-auto">
                                                {attachmentsData.map((doc, index) => (
                                                    <div key={index} className="flex justify-between items-center mx-2">
                                                        <div className="px-2 flex w-fit items-center justify-center border border-[#8E8E85] rounded-full py-1 space-x-2 text-[#434343]">
                                                            {/* Dynamic icon based on file type */}
                                                            <img src={`/assets/icons/${doc.Path}.png`} alt={doc.Path} className="h-5 w-5" />
                                                            {/* Document name now clickable */}
                                                            <a
                                                                href={doc.Content}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                download={doc.Name}
                                                                className="text-xs text-[#434343] hover:underline"
                                                            >
                                                                {doc.Name}
                                                            </a>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    </div>
                                )}



                                {/* Additional Information Section */}
                                <div className="mb-8 text-sm shadow-md bg-white p-8">

                                    <h3 className="text-sm font-semibold text-[#434343] hidden lg:block ">Additional Information</h3>

                                    {rfqData?.acceptCounterTerms && <div className="hidden lg:flex justify-between items-center w-full">
                                        <p className="mb-2 text-sm ">
                                            Below are our payment terms. Do you agree?
                                        </p>
                                        <div className="flex space-x-8 mb-4">
                                            <label className="inline-flex items-center">
                                                <Field
                                                    type="radio"
                                                    name="agreedToPaymentTerms"
                                                    value="true"
                                                    checked={values.agreedToPaymentTerms === true} // Controlled check status
                                                    onChange={() => {
                                                        setFieldValue('agreedToPaymentTerms', true);
                                                        setIsCounterTerm(false); // Update counter term
                                                    }}
                                                    className="form-radio"
                                                />
                                                <span className="ml-2 text-sm">Yes</span>
                                            </label>

                                            <label className="inline-flex items-center">
                                                <Field
                                                    type="radio"
                                                    name="agreedToPaymentTerms"
                                                    value="false"
                                                    checked={values.agreedToPaymentTerms === false} // Controlled check status
                                                    onChange={() => {
                                                        setFieldValue('agreedToPaymentTerms', false);
                                                        setIsCounterTerm(true); // Update counter term
                                                    }}
                                                    disabled={isReadOnly}
                                                    className="form-radio"
                                                />
                                                <span className="ml-2 text-sm">Counter Offer</span>
                                            </label>
                                        </div>
                                        <ErrorMessage name="agreedToPaymentTerms" component="div" className="text-red-500 text-xs" />
                                    </div>}

                                    {rfqData?.acceptCounterTerms && <div className="lg:flex justify-between space-x-6 w-full items-center">
                                        <div>
                                            {/* <p className="font-semibold text-[#4F4F42] text-sm pb-2 hidden lg:block">Payment Terms</p> */}

                                            <div className="bg-[#F6F6F4] px-4 py-2 lg:w-[390px] min-h-fit lg:mx-0 mx-auto my-3" >
                                                <p
                                                    className="w-full border-none text-xs bg-[#F6F6F4] text-[#3D3D3D]"
                                                    dangerouslySetInnerHTML={{
                                                        __html: rfqData?.paymentTerms.length > 180
                                                            ? `${rfqData.paymentTerms.slice(0, 180)}...`
                                                            : rfqData.paymentTerms,
                                                    }}
                                                ></p>
                                                {rfqData?.paymentTerms.length > 120 && (
                                                    <button
                                                        onClick={togglePopup}
                                                        type="button"
                                                        className="text-[#8E8E85] text-xs underline pt-2"
                                                    >
                                                        Read More
                                                    </button>
                                                )}


                                                {/* Popup Modal */}
                                                {isPopupOpen && (
                                                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">

                                                        <div className="bg-white border-[25px] mx-4 border-white rounded-lg shadow-lg max-w-md w-full h-[320px] relative" style={{ overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                                            <style>{` .hide-scrollbar {-ms-overflow-style: none; /* Internet Explorer 10+ */
                                                                scrollbar-width: none; /* Firefox */
                                                                }
                                                                .hide-scrollbar::-webkit-scrollbar {
                                                                 display: none; /* Safari and Chrome */
                                                                }
                                                            `}
                                                            </style>
                                                            <div className="sticky top-0 bg-white pb-2">
                                                                {/* Close Button */}
                                                                <button
                                                                    onClick={togglePopup}
                                                                    className="absolute top-0 right-0 text-gray-400 hover:text-gray-600"
                                                                >
                                                                    <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                                    </svg>
                                                                </button>
                                                                <h2 className="text-lg font-semibold mb-2">Payment Details</h2>
                                                                <hr />
                                                            </div>
                                                            <p className=" text-[#3D3D3D] text-sm overflow-y-auto " dangerouslySetInnerHTML={{ __html: rfqData?.paymentTerms }}>
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>


                                        {isCounterTerm && <div className=" mx-4 lg:mx-0">
                                            <p className=" text-sm mb-1 text-[#4F4F42]">Counter Payment Terms</p>
                                            <Field
                                                as="textarea"
                                                name="counterPaymentTerms"
                                                className={`lg:w-[390px] w-full h-[90px] text-xs placeholder:text-xs border border-gray-300 placeholder:italic 
                                                    ${values.agreedToPaymentTerms === true || isReadOnly ? 'bg-gray-200 text-gray-500 ' : 'bg-white'}`}
                                                placeholder="Please write your counter payment terms Rich Text"
                                                disabled={values.agreedToPaymentTerms === true || isReadOnly && true}
                                            />
                                        </div>}
                                    </div>}

                                    <div className=" lg:flex mt-6 mx-4 lg:mx-0">
                                        <p className="text-[#434343] mb-2 lg:mb-0 ">Notes</p>
                                        <Field
                                            as="textarea"
                                            name="notes"
                                            placeholder="Add Notes (Optional) "
                                            className={`border-[#CDCDB8] w-full placeholder:text-xs text-xs lg:ml-5 lg:w-full h-[120px] placeholder:italic placeholder-[#8C8C8C] ${isReadOnly && "bg-gray-100"}`}
                                            disabled={isReadOnly && true}
                                        />
                                    </div>
                                </div>

                                {/* Upload Supporting Documents */}
                                <div className="hidden lg:block bg-white p-8 shadow-md">
                                    <h3 className="whitespace-nowrap  text-[#434343] text-sm font-semibold lg:mb-[15px]">Upload Supporting Documents</h3>

                                    {attachmentsSkeleton.length > 0 && (
                                        <p className="text-xs pl-3 ">
                                            Your file is being scanned for upload, be patient and continue to fill
                                            the rest of the form.
                                        </p>
                                    )}

                                    <div className="px-3">
                                        {/* Display the selected file names */}
                                        <ul style={{ display: "flex", flexWrap: "wrap" }}>
                                            {selectedFilesBase.map((file, index) => (
                                                <a
                                                    href={file.Content}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="border border-gray-300 px-3 py-1 rounded-full flex mb-4 ml-10"
                                                    key={file.Name}
                                                >
                                                    <span className="text-sm overflow-hidden whitespace-nowrap w-36 truncate">
                                                        {file.Name}
                                                    </span>
                                                    <span
                                                        className="ml-1 cursor-pointer"
                                                        onClick={(event) => handleDeleteSelectedFile(file.Name, event)}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="18"
                                                            height="18"
                                                            viewBox="0 0 48 48"
                                                        >
                                                            <circle cx="24" cy="24" r="22" fill="gray" />
                                                            <line
                                                                x1="15"
                                                                y1="15"
                                                                x2="33"
                                                                y2="33"
                                                                stroke="white"
                                                                strokeWidth="2"
                                                            />
                                                            <line
                                                                x1="33"
                                                                y1="15"
                                                                x2="15"
                                                                y2="33"
                                                                stroke="white"
                                                                strokeWidth="2"
                                                            />
                                                        </svg>
                                                    </span>
                                                </a>
                                            ))}

                                            {/* Skeleton loaders for files being processed */}
                                            {attachmentsSkeleton.map((_, index) => (
                                                <Skeleton
                                                    key={index}
                                                    variant="rounded"
                                                    sx={{ borderRadius: "20px", margin: "4px" }}
                                                    width={150}
                                                    height={32}
                                                />
                                            ))}
                                        </ul>
                                    </div>

                                    <div>
                                        {attachmentsUploadsErrors && (
                                            <div className="mt-3">
                                                <p className="text-red-500 font-semibold text-sm mt-14">
                                                    {attachmentsUploadsErrors}
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    {/* Error handling for file size */}
                                    <div
                                        className={`fixed bottom-0 left-2  p-4 ${openFilesSizeError ? "block" : "hidden"
                                            }`}
                                    >
                                        <div className="bg-orange-100 w-[440px] flex items-center justify-between  px-4 py-1 text-yellow-900 rounded-lg shadow-md p-4">
                                            <p>Total size of attachments is not more than 10 MB</p>
                                            <button
                                                onClick={handleCloseFileSizeError}
                                                className="px-4 py-2 focus:outline-none"
                                            >
                                                <svg
                                                    className="h-6 w-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M6 18L18 6M6 6l12 12"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>

                                    {/* drag and drop file */}
                                    {!isReadOnly && <div className="lg:ml-14 lg:mr-0 hidden mx-4 lg:flex items-center justify-center h-[175px] lg:h-[180px] bg-[#F1F7FF] lg:bg-white border-2 border-dashed border-[#004491] lg:border-[#7D7D7D] relative">
                                        <input
                                            type="file"
                                            accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.xls,.csv,.dwg" // Specify allowed file types
                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                            onClick={handleFileUploadClick}
                                            onChange={handleFileUpload}
                                            multiple // Allow multiple files to be selected
                                        />
                                        <div className="absolute inset-0 flex flex-col items-center justify-center text-gray-500 pointer-events-none">
                                            <div className="relative mx-auto hidden lg:block">
                                                {/* Overlapping rectangles */}
                                                <div className="absolute top-10 -left-8 h-[80px] w-[65px] bg-white border border-[#878787]"></div>
                                                <div className="absolute top-2 left-0 h-[80px] w-[65px] bg-white border border-[#878787]"></div>
                                            </div>
                                            {/* Centered text */}
                                            <p className="mt-32 text-center text-sm hidden lg:block">Drag & Drop or Click to upload your proposal instead</p>
                                        </div>
                                    </div>}

                                </div>
                            </div>
                        </div>

                        {/* Action Buttons */}
                        <div className="sticky bottom-0 z-20">
                            {/* action buttons for mobile */}
                            <div
                                style={{ boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1)' }}
                                className="lg:hidden flex justify-end p-4 space-x-8 bg-white "
                            >
                                {currentStep === 0 ? (
                                    <div className="space-x-4">
                                        {/* {currentStep === 0 && (<div className="lg:flex items-center sticky top-0 shadow-md px-4 py-2 "> */}
                                        {/* <h1 className="text-[24px] text-[#434343] font-semibold ">Add Proposal: Rs. {overallTotal.toFixed(2)}</h1> */}
                                        {/* <span className="text-sm bg-[#E9E9E9] ml-4 px-4 py-2 text-[#434343]">Draft</span> */}
                                        <button type="button" onClick={(() => isEdit ? navigate(-1) : (supplierId ? navigate("/leads") : navigate(-1)))} className="text-xs bg-[#E9E9E9] cursor:pointer px-4 py-2 text-[#434343]">Back</button>

                                        <button
                                            type="button"
                                            className="bg-[#1E1E08] px-4 py-2 text-xs text-white "
                                            onClick={() => setCurrentStep(currentStep + 1)}
                                        >
                                            Start Quotation
                                        </button>
                                    </div>
                                ) : (
                                    currentStep > 0 && (
                                        <button
                                            type="button"
                                            className="underline text-xs"
                                            onClick={handlePreviousStep}
                                        >
                                            Back
                                        </button>
                                    )
                                )}

                                {/* Show "Next" button for all steps except "Additional Information & Upload" and "Completion" */}
                                {currentStep > 0 && currentStep < steps.length - 2 && (
                                    <button
                                        type="button"
                                        className="bg-[#1E1E08] px-4 py-2 text-xs text-white "
                                        onClick={() => handleNextStep(validateForm, setTouched, values)}
                                    >
                                        Next
                                    </button>
                                )}

                                {/* Show "Submit" button on "Additional Information & Upload" step */}
                                {currentStep === steps.length - 2 && (
                                    <button
                                        type="submit"
                                        className="bg-[#1E1E08] px-4 py-2 text-xs text-white "
                                        disabled={loading}
                                    >
                                        {loading ? "Submitting..." : "Submit"}
                                    </button>
                                )}

                                {/* No buttons displayed on "Completion" step */}
                            </div>
                            {/* action buttons for desktop */}
                            {!isReadOnly && <div style={{ boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1)' }} className="bg-white hidden space-x-4 lg:flex justify-end items-center py-3 lg:px-8 px-4">
                                <button type="button" onClick={handleCloseForm} className="border border-[#1E1E08] px-3 py-2 w-[120px] text-sm text-[#434343] font-bold">
                                    Cancel
                                </button>
                                <button type="submit" disabled={loading} className="bg-black text-sm text-white px-3 py-2 flex justify-center items-center">
                                    {loading ? "Submitting..." : isEdit ? "Update Quotation" : "Submit Quotation"}
                                </button>
                            </div>}
                        </div>



                        <StaticPopup
                            openModal={openUploadFile}
                            handleClose={() => setOpenUploadFile(false)}
                            size={"sm"}
                            width={"xl"}
                            bodyStyle="pb-0 pt-0"
                            className="px-0"
                            header={"Upload Supporting Documents"}
                        >
                            <FileUpload
                                handleFileUploadClick={handleMaterialFileUploadClick}
                                handleFileUpload={(event) => handleMaterialFileUpload(event, selectedMaterialIndex, setFieldValue, values)}
                                handleDeleteSelectedFile={handleDeleteMaterialFile}
                                selectedFilesBase={values.materials[selectedMaterialIndex]?.attachments || []} // Get current attachments
                                attachmentsSkeleton={materialFilesSkeleton}
                                attachmentsUploadsErrors={materialUploadsErrors}
                                openFilesSizeError={openMaterialFileSizeError}
                                handleCloseFileSizeError={handleCloseMaterialFileSizeError}
                                selectedMaterialIndex={selectedMaterialIndex}
                                isReadOnly={isReadOnly}
                            />
                        </StaticPopup>
                    </Form>
                )}
            </Formik>


            <LoadingModal
                isOpen={excelLoading || excelError !== null}
                onClose={() => setExcelError(null)} // Close the modal on click
            >
                {excelLoading ? (
                    <div className="flex flex-col items-center justify-center">
                        <div className="animate-spin rounded-full border-4 border-t-4 border-orange-500 border-t-gray-200 h-8 w-8 mb-4"></div>
                        <p className="text-xl font-medium text-gray-600 italic">{loadingMessage}</p>
                    </div>
                ) : (
                    <div>
                        <p className="text-red-500 font-medium">{excelError || "Operation completed successfully!"}</p>
                    </div>
                )}
            </LoadingModal>



        </div>
    )
}

export default MaterialQuotationForm
