import { type } from "@testing-library/user-event/dist/type";
import newRfqIf, { SendEditRfqInfo, SendRfqInfo } from "interface/newRfqIf";
import rfqService from "services/rfqService";

// Action types
export const SAVE_RFQ = "@rfqs/save";
export const SAVE_RFQ_SUCCESS = "@rfq/save/success";
export const SAVE_RFQ_FAILURE = "@rfq/save/failure";

export const ADD_VENDORS_RFQ = "@rfqs/add_vendors";
export const ADD_VENDORS_RFQ_SUCCESS = "@rfq/add_vendors/success";
export const ADD_VENDORS_RFQ_FAILURE = "@rfq/add_vendors/failure";

export const SEND_RFQ = "@rfq/send";
export const SEND_RFQ_SUCCESS = "@rfq/send/success";
export const SEND_RFQ_FAILURE = "@rfq/send/failure";

export const EDIT_RFQ = "@rfq/edit";
export const EDIT_RFQ_SUCCESS = "@rfq/edit/success";
export const EDIT_RFQ_FAILURE = "@rfq/edit/failure";

export const TRASH_RFQ = "@rfq/trash";
export const TRASH_RFQ_SUCCESS = "@rfq/trash/success";
export const TRASH_RFQ_FAILURE = "@rfq/trash/failure";

export const DELETE_RFQ = "@rfq/delete";
export const DELETE_RFQ_SUCCESS = "@rfq/delete/success";
export const DELETE_RFQ_FAILURE = "@rfq/delete/failure";

export const RESTORE_RFQ = "@rfq/restore";
export const RESTORE_RFQ_SUCCESS = "@rfq/restore/success";
export const RESTORE_RFQ_FAILURE = "@rfq/restore/failure";

export const FETCH_RFQS = "@rfq/get";
export const FETCH_RFQS_SUCCESS = "@rfq/get/success";
export const FETCH_RFQS_FAILURE = "@rfq/get/failure";

export const FETCH_RFQ = "@rfq/fetch";
export const FETCH_RFQ_SUCCESS = "@rfq/fetch/success";
export const FETCH_RFQ_FAILURE = "@rfq/fetch/failure";

export const INVITE_VENDORS_RFQ = "@rfq/invite-vendors";
export const INVITE_VENDORS_RFQ_SUCCESS = "@rfq/invite-vendors/success";
export const INVITE_VENDORS_RFQ_FAILURE = "@rfq/invite-vendors/failure";

export const RESEND_RFQ = "@rfq/resend";
export const RESEND_RFQ_SUCCESS = "@rfq/resend/success";
export const RESEND_RFQ_FAILURE = "@rfq/resend/failure";

export const REMOVE_VENDORS_FROM_RFQ = "@rfq/remove-vendors";
export const REMOVE_VENDORS_FROM_RFQ_SUCCESS = "@rfq/remove-vendors/success";
export const REMOVE_VENDORS_FROM_RFQ_FAILURE = "@rfq/remove-vendors/failure";

export const CLOSE_RFQ = "@rfq/close";
export const CLOSE_RFQ_SUCCESS = "@rfq/close/success";
export const CLOSE_RFQ_FAILURE = "@rfq/close/failure";

export const ADD_NEW_PROJECT = "@new-project/create";
export const ADD_NEW_PROJECT_SUCCESS = "@new-project/create-success";
export const ADD_NEW_PROJECT_FAILURE = "@new-project/create-failure";

export const FETCH_RFQ_PROJECTS = "@new-project";
export const FETCH_RFQ_PROJECTS_SUCCESS = "@new-project/success";
export const FETCH_RFQ_PROJECTS_FAILURE = "@new-project/failure";

export const EDIT_RFQ_PROJECT = "@edit-rfq-project";
export const EDIT_RFQ_PROJECT_SUCCESS = "@edit-rfq-project/success";
export const EDIT_RFQ_PROJECT_FAILURE = "@edit-rfq-project/failure";

export const ADD_EMAILS_TO_SUPPLIER = "@supplier/add-emails";
export const ADD_EMAILS_TO_SUPPLIER_SUCCESS = "@supplier/add-emails/success";
export const ADD_EMAILS_TO_SUPPLIER_FAILURE = "@supplier/add-emails/failure";

export const QUOTATION_RECEIVED_DETAILS = "@quotation";
export const QUOTATION_RECEIVED_DETAILS_SUCCESS = "@quotation/success";
export const QUOTATION_RECEIVED_DETAILS_FAILURE = "@quotation/failure";

export const SEARCH_RFQ = "@search/rfq";
export const SEARCH_RFQ_SUCCESS = "@search/rfq/success";
export const SEARCH_RFQ_FAILURE = "@search/rfq/failure";

export const SINGLE_RQ_DELETE = "@rfq/single/delete/";
export const SINGLE_RQ_DELETE_SUCCESS = "@rfq/single/delete/success";
export const SINGLE_RQ_DELETE_FAILURE = "@rfq/single/delete/failure";

export const CHANGE_STATUS = "@rfq/change/status/";
export const CHANGE_STATUS_SUCCESS = "@rfq/change/status/success";
export const CHANGE_STATUS_FAILURE = "@rfq/change/status/failure";

export const MEMBER_LIST_SUCCESS = "@rfq/cc/member/list/success";
export const MEMBER_LIST_FAILURE = "@rfq/cc/member/list/failure";

export const RESEND_BOUNCE_EMAIL = "@vendor/bounce/resend-email";
export const RESEND_BOUNCE_EMAIL_SUCCESS =
  "@vendor/bounce/resend-email/success";
export const RESEND_BOUNCE_EMAIL_FAILURE =
  "@vendor/bounce/resend-email/failure";

// Save RFQ Action creator
export function saveRfq({ entityId, values }) {
  return async (dispatch) => {
    try {
      // Dispatch action to indicate saving is in progress
      dispatch({ type: SAVE_RFQ });
      const newReqInfo = new SendRfqInfo({
        entityId,
        values,
      });
      const response = await rfqService.saveRfq(newReqInfo);
      // Dispatch action to indicate successful saving
      dispatch({
        type: SAVE_RFQ_SUCCESS,
        payload: { rfq: response?.payload },
      });
    } catch (error) {
      // Dispatch action to indicate saving failed
      dispatch({
        type: SAVE_RFQ_FAILURE,
        payload: {
          error: "Failed to save RFQ",
        },
      });
      throw error;
    }
  };
}

export async function saveRfqNew({ entityId, values }) {
  try {
    const newReqInfo = new SendRfqInfo({
      entityId,
      values,
    });

    // Call the service to save RFQ
    const response = await rfqService.saveRfq(newReqInfo);

    // Return the response directly (just like in getVendorQuotation)
    return response; 
  } catch (error) {
    console.error("Failed to save RFQ:", error);
    throw error; // Re-throw error to be handled by the caller
  }
}

// Add New Project Action Creator
export const addNewProject = (values) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_NEW_PROJECT });
      const response = await rfqService.createNewProject(values);
      dispatch({ type: ADD_NEW_PROJECT_SUCCESS, payload: response.project });
    } catch (error) {
      dispatch({ type: ADD_NEW_PROJECT_FAILURE });
      throw error;
    }
  };
};

// search rfq action here
export const searchRfq = ({ entityId, searchParam, status }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SEARCH_RFQ });
      const response = await rfqService.searchRfq({
        entityId,
        searchParam,
        status,
      });
      dispatch({ type: SEARCH_RFQ_SUCCESS, payload: response });
    } catch (error) {
      dispatch({ type: SEARCH_RFQ_FAILURE });
      throw error;
    }
  };
};

// Edit Rfq Project Action here

export const EditRfqProject = (values, projectId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: EDIT_RFQ_PROJECT });
      const response = await rfqService.editRfqProject(values, projectId);
      dispatch({ type: EDIT_RFQ_PROJECT_SUCCESS, payload: response.project });
    } catch (error) {
      dispatch({ type: EDIT_RFQ_PROJECT_FAILURE });
      throw error;
    }
  };
};

// Add vendors in RFQ Action creator
export function addVendorsToRfq({
  rfqId,
  suppliers,
  requesterName,
  requestedBy,
  senderEmail
}) {
  return async (dispatch) => {
    try {
      // Dispatch action to indicate adding vendors is in progress
      dispatch({ type: ADD_VENDORS_RFQ });
      const response = await rfqService.addVendorsToRfq({
        rfqId,
        suppliers,
        requesterName,
        requestedBy,
        senderEmail
      });
      dispatch({
        type: ADD_VENDORS_RFQ_SUCCESS,
        payload: { rfq: response.payload.updatedRFQ },
      });
    } catch (error) {
      // Dispatch action to indicate adding vendors failed
      dispatch({
        type: ADD_VENDORS_RFQ_FAILURE,
      });
      throw error;
    }
  };
}

// Add emails in suppliers
export function addEmailsToSupplier({ entityId, rfqId, supplierId, emails }) {
  return async (dispatch) => {
    try {
      // Dispatch action to indicate adding vendors is in progress
      dispatch({ type: ADD_EMAILS_TO_SUPPLIER });
      const response = await rfqService.addEmailsToSupplier({
        entityId,
        rfqId,
        supplierId,
        emails,
      });
      dispatch({
        type: ADD_VENDORS_RFQ_SUCCESS,
        payload: { rfq: response?.updatedRfq },
      });
    } catch (error) {
      // Dispatch action to indicate adding vendors failed
      dispatch({
        type: ADD_EMAILS_TO_SUPPLIER_FAILURE,
      });
      throw error;
    }
  };
}

// Send RFQ Action creator
export function sendRfq(requirementData) {
  return async (dispatch) => {
    try {
      dispatch({ type: SEND_RFQ });

      // Assuming requirementData is an object containing the requirement to send to the API
      const response = await rfqService.sendRfq(requirementData);

      // Dispatch success action with any response data you might need
      dispatch({
        type: SEND_RFQ_SUCCESS,
        payload: {
          responseData: response.data,
        },
      });
    } catch (error) {
      // Dispatch failure action if an error occurs
      dispatch({ type: SEND_RFQ_FAILURE });
      throw error;
    }
  };
}

// Fetch RFQ Action creator
export function fetchRfqs({ entityId, userIds, status = "", page = 1 }) {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_RFQS });
      let stringArray = userIds.map((number) => String(number));
      // Assuming requirementData is an object containing the requirement to send to the API
      const response = await rfqService.getRfqs({
        entityId,
        userIds: stringArray,
        status,
        page,
      });

      // Dispatch success action with any response data you might need
      dispatch({
        type: FETCH_RFQS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      // Dispatch failure action if an error occurs
      dispatch({ type: FETCH_RFQS_FAILURE });
      throw error;
    }
  };
}

export function fetchSearchRfqs({ entityId, searchParam, status, userIds }) {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_RFQS, payload: { searchParam } });
      let stringArray = userIds.map((number) => String(number));
      const response = await rfqService.searchRfq({
        entityId,
        searchParam,
        status,
        userIds: stringArray,
      });
      dispatch({
        type: FETCH_RFQS_SUCCESS,
        payload: response?.payload,
      });
    } catch (error) {
      // Dispatch failure action if an error occurs
      dispatch({ type: FETCH_RFQS_FAILURE });
      throw error;
    }
  };
}

export function searchRfqWithDate({
  projectId = "",
  entityId,
  searchParam,
  status,
  userIds,
  startDate = "",
  endDate = "",
  startDateOfClosing = "",
  endDateOfClosing = "",
  dateFilterIsActive,
  closeDateFilterIsActive,
}) {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_RFQS,
        payload: {
          searchParam,
          startDate,
          endDate,
          dateFilterIsActive,
          closeDateFilterIsActive,
          startDateOfClosing,
          endDateOfClosing,
          filterStatus: status,
          filterUserIds: userIds,
        },
      });
      // Assuming requirementData is an object containing the requirement to send to the API
      let stringArray = userIds.map((number) => String(number));
      const response = await rfqService.searchRfqByDateFilter({
        projectId,
        entityId,
        searchParam,
        status,
        userIds: stringArray,
        startDate,
        endDate,
        startDateOfClosing,
        endDateOfClosing,
      });
      // Dispatch success action with any response data you might need
      dispatch({
        type: FETCH_RFQS_SUCCESS,
        payload: response?.payload,
      });
    } catch (error) {
      // Dispatch failure action if an error occurs
      dispatch({ type: FETCH_RFQS_FAILURE });
      throw error;
    }
  };
}

// Filter RFQS by date
export function FilterRfqByDate(payload) {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_RFQS });
      // Assuming requirementData is an object containing the requirement to send to the API
      const response = await rfqService.rfqFilterDate({
        payload,
      });
      // Dispatch success action with any response data you might need
      dispatch({
        type: FETCH_RFQS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({ type: FETCH_RFQS_FAILURE });
      throw error;
    }
  };
}

// Save RFQ Action creator
export function editRfq({ selectedFilesBase, values }) {
  return async (dispatch) => {
    try {
      // Dispatch action to indicate saving is in progress
      dispatch({ type: EDIT_RFQ });
      const newReqInfo = new SendEditRfqInfo({
        selectedFilesBase,
        values,
      });
      const response = await rfqService.editRfq(newReqInfo);
      // Dispatch action to indicate successful saving
      dispatch({
        type: EDIT_RFQ_SUCCESS,
        payload: { rfq: response?.payload },
      });
    } catch (error) {
      // Dispatch action to indicate saving failed
      dispatch({
        type: EDIT_RFQ_FAILURE,
        payload: {
          error: "Failed to edit RFQ",
        },
      });
      throw error;
    }
  };
}

// Delete rfq Action Creator
export function trashedRfq({
  rfqId,
  entityId,
  userIds,
  currentStatus,
  actionBy,
  startDate,
  endDate,
}) {
  return async (dispatch) => {
    try {
      dispatch({
        type: TRASH_RFQ,
      });
      const response = await rfqService.trashedRfq({
        rfqId,
        entityId,
        userIds,
        currentStatus,
        actionBy,
        startDate,
        endDate,
      });
      // Dispatch action to indicate successful saving
      dispatch({
        type: TRASH_RFQ_SUCCESS,
        payload: response,
      });
    } catch (error) {
      // Dispatch action to indicate saving failed
      dispatch({
        type: TRASH_RFQ_FAILURE,
        errorMessage: "Failed to delete RFQ",
      });
      throw error;
    }
  };
}

// permanent delete rfq
export function deleteRfq({
  rfqId,
  entityId,
  userIds,
  currentStatus,
  startDate,
  endDate,
  actionBy,
}) {
  return async (dispatch) => {
    try {
      dispatch({
        type: DELETE_RFQ,
      });
      const response = await rfqService.deleteRfq({
        rfqId,
        entityId,
        userIds,
        currentStatus,
        actionBy,
        startDate,
        endDate,
      });
      // Dispatch action to indicate successful saving
      dispatch({
        type: DELETE_RFQ_SUCCESS,
        payload: response,
      });
    } catch (error) {
      // Dispatch action to indicate saving failed
      dispatch({
        type: DELETE_RFQ_FAILURE,
        errorMessage: "Failed to delete RFQ",
      });
      throw error;
    }
  };
}

// restore rfq Action Creator
export function restoreRfq({
  rfqId,
  entityId,
  userIds,
  currentStatus,
  actionBy,
  startDate,
  endDate,
}) {
  return async (dispatch) => {
    try {
      dispatch({
        type: RESTORE_RFQ,
      });
      const response = await rfqService.restoreRfq({
        rfqId,
        entityId,
        userIds,
        currentStatus,
        actionBy,
        startDate,
        endDate,
      });
      // Dispatch action to indicate successful saving
      dispatch({
        type: RESTORE_RFQ_SUCCESS,
        payload: response,
      });
    } catch (error) {
      // Dispatch action to indicate saving failed
      dispatch({
        type: RESTORE_RFQ_FAILURE,
        errorMessage: "Failed to restore the RFQ",
      });
      throw error;
    }
  };
}

// Invite vendors to RFQ Action creator
export function inviteVendors({ rfqId, supplierIds }) {
  return async (dispatch) => {
    try {
      // Dispatch action to indicate saving is in progress
      dispatch({ type: INVITE_VENDORS_RFQ });

      const response = await rfqService.inviteVendors({ rfqId, supplierIds });
      // Dispatch action to indicate successful saving
      dispatch({
        type: INVITE_VENDORS_RFQ_SUCCESS,
        payload: { rfq: response?.rfq },
      });
    } catch (error) {
      // Dispatch action to indicate saving failed
      dispatch({
        type: INVITE_VENDORS_RFQ_FAILURE,
        payload: {
          error: "Failed to edit RFQ",
        },
      });
      throw error;
    }
  };
}

// remove vendors from RFQ Action creator
export function removeVendorsFromRfq({ rfqId, supplierId }) {
  return async (dispatch) => {
    try {
      // Dispatch action to indicate saving is in progress
      dispatch({ type: REMOVE_VENDORS_FROM_RFQ });
      const response = await rfqService.removeVendorsFromRfq(rfqId, supplierId);
      // Dispatch action to indicate successful saving
      dispatch({
        type: REMOVE_VENDORS_FROM_RFQ_SUCCESS,
        payload: { rfq: response?.payload },
      });
    } catch (error) {
      // Dispatch action to indicate saving failed
      dispatch({
        type: REMOVE_VENDORS_FROM_RFQ_FAILURE,
        payload: {
          error: "Failed to edit RFQ",
        },
      });
      throw error;
    }
  };
}

// Resend bounce email
export function resendBounceEmail(payload) {
  return async (dispatch) => {
    try {
      // Dispatch action to indicate saving is in progress
      dispatch({ type: RESEND_BOUNCE_EMAIL });
      const response = await rfqService.resendBounceEmail(payload);
      // Dispatch action to indicate successful saving
      dispatch({
        type: RESEND_BOUNCE_EMAIL_SUCCESS,
        payload: { rfq: response.payload.updatedRFQ },
      });
    } catch (error) {
      // Dispatch action to indicate saving failed
      dispatch({
        type: RESEND_BOUNCE_EMAIL_FAILURE,
        payload: {
          error: "Failed to edit RFQ",
        },
      });
      throw error;
    }
  };
}

// Resend RFQ Action creator
export function resendRfq({ payload }) {
  return async (dispatch) => {
    try {
      // Dispatch action to indicate saving is in progress
      dispatch({ type: RESEND_RFQ });

      const response = await rfqService.resendRfq(payload);
      // Dispatch action to indicate successful saving
      dispatch({
        type: RESEND_RFQ_SUCCESS,
        payload: { rfq: response?.payload?.updatedRfq },
      });
    } catch (error) {
      // Dispatch action to indicate saving failed
      dispatch({
        type: RESEND_RFQ_FAILURE,
        payload: {
          error: "Failed to edit RFQ",
        },
      });
      throw error;
    }
  };
}

// CC member list
export function CcMemberList(entityId) {
  return async (dispatch) => {
    try {
      const response = await rfqService.getMemberList(entityId);
      dispatch({
        type: MEMBER_LIST_SUCCESS,
        ccMemberList: response?.memberEmails,
      });
    } catch (error) {
      console.log("this is an error CcMemberList: ", error);
      dispatch({ type: MEMBER_LIST_FAILURE });
    }
  };
}

// Resend the RFQ send in multiple
export function mulResendRfq({ payload }) {
  return async (dispatch) => {
    try {
      // Dispatch action to indicate saving is in progress
      dispatch({ type: RESEND_RFQ });
      const response = await rfqService.mulResendRfq(payload);
      // console.log("response 777777777", response);
      // Dispatch action to indicate successful saving
      dispatch({
        type: RESEND_RFQ_SUCCESS,
        payload: { rfq: response?.payload?.updatedRfq },
      });
    } catch (error) {
      // Dispatch action to indicate saving failed
      dispatch({
        type: RESEND_RFQ_FAILURE,
        payload: {
          error: "Failed to resend RFQ",
        },
      });
      throw error;
    }
  };
}

// Fetch single RFQ Action creator
export function fetchRfq({ entityId, rfqId }) {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_RFQ });

      // Assuming requirementData is an object containing the requirement to send to the API
      const response = await rfqService.getRfq({ entityId, rfqId });
      // Dispatch success action with any response data you might need
      dispatch({
        type: FETCH_RFQ_SUCCESS,
        payload: response,
      });
    } catch (error) {
      // Dispatch failure action if an error occurs
      dispatch({ type: FETCH_RFQ_FAILURE });
      throw error;
    }
  };
}

// Close Rfqs Action creator
export function closeRfq({ payload }) {
  return async (dispatch) => {
    try {
      dispatch({ type: CLOSE_RFQ });

      // Assuming requirementData is an object containing the requirement to send to the API
      const response = await rfqService.closeRfq({ payload });
      // Dispatch success action with any response data you might need
      dispatch({
        type: CLOSE_RFQ_SUCCESS,
        payload: { rfq: response.updatedRfq },
      });
    } catch (error) {
      // Dispatch failure action if an error occurs
      dispatch({
        type: CLOSE_RFQ_FAILURE,
        message: error?.response?.data?.message,
      });
      throw error;
    }
  };
}

export function rejectRfqApproval({ payload }) {
  return async (dispatch) => {
    try {
      dispatch({ type: CLOSE_RFQ });

      // Assuming requirementData is an object containing the requirement to send to the API
      const response = await rfqService.rejectRfqApproval({ payload });
      // Dispatch success action with any response data you might need
      dispatch({
        type: CLOSE_RFQ_SUCCESS,
        payload: { rfq: response.updatedRfq },
      });
    } catch (error) {
      // Dispatch failure action if an error occurs
      dispatch({
        type: CLOSE_RFQ_FAILURE,
        message: error?.response?.data?.message,
      });
      throw error;
    }
  };
}

// Close Rfq without selecting vendor
export function closeWithoutSelectingVendor(rfqId) {
  return async (dispatch) => {
    try {
      dispatch({ type: CLOSE_RFQ });

      // Assuming requirementData is an object containing the requirement to send to the API
      const response = await rfqService.closeRfqWithoutVendor({ rfqId });
      // Dispatch success action with any response data you might need
      dispatch({
        type: CLOSE_RFQ_SUCCESS,
        payload: { rfq: response.updatedRfq },
      });
    } catch (error) {
      // Dispatch failure action if an error occurs
      dispatch({ type: CLOSE_RFQ_FAILURE });
      throw error;
    }
  };
}

// delete single rfq action creator
export function deleteSingleRfq({ rfqId, userId, actionBy }) {
  return async (dispatch) => {
    try {
      dispatch({ type: SINGLE_RQ_DELETE });
      const response = await rfqService.deleteSingleRfq({
        rfqId,
        userId,
        actionBy,
      });
      // console.log("this is a quotation received", response);
      dispatch({
        type: SINGLE_RQ_DELETE_SUCCESS,
        payload: { rfq: response?.rfq },
      });
    } catch (error) {
      // Dispatch failure action if an error occurs
      dispatch({ type: SINGLE_RQ_DELETE_FAILURE });
      throw error;
    }
  };
}

// change status of rfq
export function ChangeStatus({ payload }) {
  return async (dispatch) => {
    try {
      dispatch({ type: CHANGE_STATUS });
      const response = await rfqService.changeStatus({ payload });
      // console.log("this is a quotation received", response);
      dispatch({
        type: CHANGE_STATUS_SUCCESS,
        payload: { rfq: response?.updatedRfq },
      });
    } catch (error) {
      // Dispatch failure action if an error occurs
      dispatch({ type: CHANGE_STATUS_FAILURE });
      throw error;
    }
  };
}

// Quotation received Action Creator
export function quotationReceived(payload) {
  return async (dispatch) => {
    try {
      dispatch({ type: QUOTATION_RECEIVED_DETAILS });
      const response = await rfqService.quotationReceived({ payload });
      // console.log("this is a quotation received", response);
      dispatch({
        type: QUOTATION_RECEIVED_DETAILS_SUCCESS,
        payload: { rfq: response?.rfq },
      });
    } catch (error) {
      // Dispatch failure action if an error occurs
      dispatch({ type: QUOTATION_RECEIVED_DETAILS_FAILURE });
      throw error;
    }
  };
}
export function fetchRfqProjects(entityId) {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_RFQ_PROJECTS });
      const response = await rfqService.getRfqProjects(entityId);
      dispatch({
        type: FETCH_RFQ_PROJECTS_SUCCESS,
        payload: response?.projects,
      });
    } catch (error) {
      // Dispatch failure action if an error occurs
      dispatch({ type: FETCH_RFQ_PROJECTS_FAILURE });
      throw error;
    }
  };
}
