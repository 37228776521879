import { useEffect, useRef, useState } from "react";

const CustomGstDropdown = ({ options, value, onChange, className, disabled }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleToggle = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };
    const handleOptionClick = (option) => {
        onChange(option);
        setIsOpen(false);  // Close the dropdown after selecting an option
    };

     // Close dropdown when clicking outside
     useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <div className={`relative ${className}`} ref={dropdownRef}>
            <div
                className={`border border-gray-300 text-xs h-full z-0 flex items-center justify-between px-2 cursor-pointer ${disabled ? "bg-gray-100" : "bg-white"}`}
                onClick={handleToggle}
            >
                {/* Show selected value or placeholder if no value is selected */}
                <span className="w-full xl:text-xs text-left text-[#434343]">
                    {value !== undefined && value !== null ? `${value}%` : "GST"}
                </span>
                {/* Arrow icon */}
                <img
                    src="https://storagereponeevaydevcdn.blob.core.windows.net/business/dropdown_arrow.svg"
                    alt="Dropdown arrow"
                    className={`transition-transform w-3 h-2 duration-300 ${isOpen && !disabled ? 'rotate-180' : 'rotate-0'}`}
                />
            </div>
            {isOpen && !disabled && (
                <ul className="absolute bg-white border border-gray-300 mt-1 w-full overflow-auto z-10">
                    {options.map((option) => (
                        <li
                            key={option}
                            className="px-2 py-1 xl:text-xs bg-white hover:bg-gray-200 cursor-pointer"
                            onClick={() => handleOptionClick(option)}
                        >
                            {option}%
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomGstDropdown;
