import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from "utils/axios";
import { useSnackbar } from 'notistack';
import LoadingModal from 'views/Rfqs/LoadingModal';

const VendorBulkUpload = ({ userProfile, handlCloseBulkUpload }) => {

    const { enqueueSnackbar } = useSnackbar();


    const [fileData, setFileData] = useState(null);
    const [validationErrors, setValidationErrors] = useState([]);
    const [loading, setLoading] = useState(false); // State for loader
    const [error, setError] = useState(null); // State for error handling
    const downloatTemplate = "https://storagereponeevaydevcdn.blob.core.windows.net/business/vendors_bulk_upload_neevay_template.xlsx"

    const validateFileData = (rowData) => {
        const errors = [];
        const mandatoryFields = ['Vendor_Name', 'Approved', 'Primary_Contact_Mobile', 'Primary_Contact_Email', 'Catalogue', 'Serviceable_Locations', 'Vendor_Type'];
        console.log("Mandatory Fields:", mandatoryFields);

        const headers = rowData[0];
        console.log("Headers:", headers);

        // Check if mandatory headers are present
        const missingHeaders = mandatoryFields.filter(field => !headers.includes(field));
        if (missingHeaders.length > 0) {
            errors.push(`Missing mandatory field(s) in header row: ${missingHeaders.join(', ')}`);
            return errors;
        }

        // Check if all rows are empty
        const dataRows = rowData.slice(1); // Exclude the header row
        const nonEmptyRows = dataRows.filter(row => row.some(cell => cell !== null && cell !== undefined && cell !== ''));

        if (nonEmptyRows.length === 0) {
            errors.push("The uploaded sheet is empty. Please provide data rows.");
            return errors;
        }

        // Validate non-empty rows only
        for (let i = 0; i < nonEmptyRows.length; i++) {
            const row = nonEmptyRows[i];
            for (let j = 0; j < mandatoryFields.length; j++) {
                const mandatoryField = mandatoryFields[j];
                const mandatoryFieldIndex = rowData[0].indexOf(mandatoryField);
                if (
                    mandatoryFieldIndex === -1 ||
                    row[mandatoryFieldIndex] === null ||
                    row[mandatoryFieldIndex] === undefined ||
                    row[mandatoryFieldIndex] === ''
                ) {
                    const rowIndex = rowData.findIndex(r => r === row) + 1; // Get the actual row index in the original data
                    console.error(`Error: Mandatory field '${mandatoryField}' is missing or empty in row ${rowIndex}`);
                    errors.push(`Error: Mandatory field '${mandatoryField}' is missing or null in row ${rowIndex}`);
                }
            }
        }

        return errors;
    };




    const uploadToAzureBlobStorage = (fileToUpload) => {

        const entityId = userProfile?.userEntity?.entityId
        const userId = userProfile?.userId
        const userName = userProfile?.name
        const userEmail = userProfile?.email

        const axiosConfig = {
            timeout: 300000 // 5 minutes in milliseconds
        };
        setLoading(true); // Show loader when upload starts
        setError(null); // Reset previous errors if any
        axios.post('/api/directory/bulk/upload', { fileToUpload, entityId, userId, userName, userEmail }, axiosConfig)
            .then(response => {
                // Handle response
                if (response.data?.error === false) {
                    // Show success message
                    handlCloseBulkUpload()
                    enqueueSnackbar('Vendors from file added successfully, check email for upload details.', { variant: 'success', autoHideDuration: 6000 });
                } else {
                    // Handle errors or failed upload
                    handlCloseBulkUpload()
                    enqueueSnackbar('There was an issue with the file upload. Please try again.', { variant: 'error', autoHideDuration: 4000 });
                }
            })
            .catch(error => {
                console.error('Error uploading file:', error);
                setError('Error: Unable to upload the file. Please try again later.');
            })
            .finally(() => {
                setLoading(false); 
            });
    };


    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            const base64Promise = new Promise((resolve) => {
                reader.onloadend = () => {
                    const base64Content = reader.result.split(',')[1];
                    resolve(base64Content);
                };
            });

            base64Promise.then((base64Content) => {
                const workbook = XLSX.read(base64Content, { type: 'base64' });

                // Check if "Vendor List" sheet exists
                const vendorListSheet = workbook.Sheets["Vendor List"];
                if (!vendorListSheet) {
                    setValidationErrors(['Error: "Vendor List" sheet not found in the file.']);
                    return;
                }

                // Process the data from the "Vendor List" sheet
                const data = XLSX.utils.sheet_to_json(vendorListSheet, { header: 1 });

                const errors = validateFileData(data);

                if (errors.length === 0) {
                    setFileData(data);
                    setValidationErrors([]);
                    const fileData = {
                        Name: file.name,
                        Content: base64Content,
                        ContentType: file.type,
                        FileSize: file.size,
                    };
                    uploadToAzureBlobStorage(fileData);
                } else {
                    setValidationErrors(errors);
                }
            });
        };

        reader.readAsDataURL(file);
    };




    return (
        <>
            <div className="flex justify-center flex-col items-center h-[300px]">
                <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-md border border-[2px]">
                    <h2 className="text-sm font-semibold mb-4">Upload Excel/CSV File</h2>
                    <label htmlFor="file" className="block bg-blue-500 text-white rounded-md py-2 px-4 mb-4 cursor-pointer transition duration-300 hover:bg-blue-600">
                        Choose File
                    </label>
                    <input id="file" type="file" accept=".csv, .xlsx, .xls" className="hidden" onChange={handleFileUpload} />
                    {validationErrors.length > 0 && (
                        <div className="mt-4">
                            <h3 className="text-sm font-semibold mb-2">Validation Errors:</h3>
                            <ul className="list-disc pl-6">
                                {validationErrors.map((error, index) => (
                                    <li key={index} className="text-red-600">{error}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                <div>
                    <p className='text-sm text-center mt-4 w-full font-normal text-[#777777]'>Don't have the template? </p>
                    <div className='flex items-center justify-center gap-2'>
                        <a href={downloatTemplate} download className='text-sm text-center text-[#1D28FA] font-normal'>
                            Download Here
                        </a>
                        <svg
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.5625 1.5625C9.5625 0.974805 9.0877 0.5 8.5 0.5C7.9123 0.5 7.4375 0.974805 7.4375 1.5625V9.6209L5.00039 7.18379C4.58535 6.76875 3.91133 6.76875 3.49629 7.18379C3.08125 7.59883 3.08125 8.27285 3.49629 8.68789L7.74629 12.9379C8.16133 13.3529 8.83535 13.3529 9.25039 12.9379L13.5004 8.68789C13.9154 8.27285 13.9154 7.59883 13.5004 7.18379C13.0854 6.76875 12.4113 6.76875 11.9963 7.18379L9.5625 9.6209V1.5625ZM2.125 12.1875C0.95293 12.1875 0 13.1404 0 14.3125V15.375C0 16.5471 0.95293 17.5 2.125 17.5H14.875C16.0471 17.5 17 16.5471 17 15.375V14.3125C17 13.1404 16.0471 12.1875 14.875 12.1875H11.5049L10.0008 13.6916C9.1707 14.5217 7.82598 14.5217 6.9959 13.6916L5.49512 12.1875H2.125ZM14.3438 14.0469C14.5551 14.0469 14.7578 14.1308 14.9072 14.2803C15.0567 14.4297 15.1406 14.6324 15.1406 14.8438C15.1406 15.0551 15.0567 15.2578 14.9072 15.4072C14.7578 15.5567 14.5551 15.6406 14.3438 15.6406C14.1324 15.6406 13.9297 15.5567 13.7803 15.4072C13.6308 15.2578 13.5469 15.0551 13.5469 14.8438C13.5469 14.6324 13.6308 14.4297 13.7803 14.2803C13.9297 14.1308 14.1324 14.0469 14.3438 14.0469Z"
                                fill="blue"
                            />
                        </svg>
                    </div>
                </div>
            </div>



            <LoadingModal
                isOpen={loading || error !== null}
                onClose={() => setError(null)} 
            >
                {loading ? (
                    <div className="flex flex-col items-center justify-center">
                        <div className="animate-spin rounded-full border-4 border-t-4 border-orange-500 border-t-gray-200 h-8 w-8 mb-4"></div>
                        <p className="text-xl font-medium text-gray-600 italic">Sit tight, we are adding vendors</p>
                    </div>
                ) : (
                    <div>
                        <p>{error || ""}</p>
                    </div>
                )}
            </LoadingModal>
        </>


    );
};

export default VendorBulkUpload;
