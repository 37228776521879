import React from 'react';

const ContractorRFQTable = ({ rfqData, sortedSuppliers, withGst, openDropdown, handleMaterialDropdown }) => (
    <tr className='bg-white'>
        <td className="bg-white text-xs z-10 w-fit sticky left-0 border border-gray-300 px-6" style={{ boxShadow: '4px 0 4px rgba(0, 0, 0, 0.1)' }}>
            <div className='space-x-2 flex text-[#777777]'>
                <p>1.</p>
                <p className="text-xs text-[#777777] bg-white" dangerouslySetInnerHTML={{ __html: rfqData?.providingFixingDetails }} />
            </div>
        </td>
        {sortedSuppliers?.map((supplier, vendorIndex) => {
            const vendorQuotation = supplier.vendorQuotations?.quotationSets?.slice(-1)[0];
            const vendorItem = vendorQuotation?.contractorDetails || {};

            return (
                <td key={vendorIndex} className={`min-w-[300px] px-4 z-0 border border-gray-300 ${vendorIndex < rfqData?.suppliers?.length - 1 ? 'border-r' : ''}`}>
                    <div onClick={() => handleMaterialDropdown(0, vendorIndex)} className='relative cursor-pointer flex justify-between items-end py-1'>
                        <div>
                            {vendorItem.workStartDate && rfqData.providingDate && (
                                new Date(vendorItem.workStartDate).getTime() !== new Date(rfqData.providingDate).getTime() && (
                                    <p className='text-xs text-[#DC7604]'>
                                        Proposed: {new Date(vendorItem.workStartDate).toLocaleDateString('en-GB')}
                                    </p>
                                )
                            )}
                            <p className='text-xs text-[#777777]' dangerouslySetInnerHTML={{ __html: vendorItem.description }} />
                            <div className='flex justify-start gap-4'>
                                <p className='text-xs text-[#777777]'>
                                    Base Price: Rs. {new Intl.NumberFormat('en-IN').format(vendorItem?.basePrice ?? 0)}
                                </p>                                {withGst && <p className='text-xs text-[#777777]'>GST: {vendorItem.gst} %</p>}
                                {/* <p className='text-base font-normal text-[#2D2D11] ml-auto'>Rs. {withGst ? vendorItem.netPrice?.toFixed(2) : vendorItem?.priceWithoutGst?.toFixed(2)}</p> */}
                            </div>
                        </div>
                    </div>
                    {/* Dropdown for contractor item */}
                    {(openDropdown.rowIndex === 0 && openDropdown.vendorIndex === vendorIndex) && (
                        <div className='absolute z-10 w-[270px] bg-white border border-[#FFA653] p-0 h-fit whitespace-nowrap'>
                            <div className='p-2 space-y-2 text-xs'>
                                <label className='flex items-center justify-between w-full'>
                                    <span>Base Price (Rs.)</span>
                                    <p type="text" className='  border-none text-xs px-2 py-1 bg-[#F0F0F0] text-[#404040] text-right'>{vendorItem.basePrice}  </p>
                                </label>
                                <label className='flex items-center justify-between w-full'>
                                    <span>Total (Rs.)</span>
                                    <p type="text" className='border-none text-xs px-2 py-1 bg-[#F0F0F0] text-[#404040] text-right'>{vendorItem.netPrice}  </p>
                                </label>
                                <label className='flex items-center justify-between w-full'>
                                    <span>Description</span>
                                    <p
                                        className="max-w-[180px] border-none px-2 py-1 max-h-[100px] text-xs bg-[#F0F0F0] text-[#404040] overflow-y-auto overflow-x-hidden break-words whitespace-normal"
                                        dangerouslySetInnerHTML={{ __html: vendorItem.description }}
                                    ></p>
                                </label>
                            </div>
                            {/* <p className="w-full border-none text-xs bg-[#F6F6F4] text-[#3D3D3D] " dangerouslySetInnerHTML={{ __html: rfqData?.paymentTerms }}>
                                                </p>
                                                {rfqData?.paymentTerms.length > 120 && <button
                                                    onClick={togglePopup}
                                                    type="button"
                                                    className="text-[#8E8E85] text-xs underline pt-2 "
                                                >
                                                    Read More
                                                </button>} */}
                            <div className=' p-2'>
                                <p className='text-[#434343] font-semibold text-xs'>Remarks:</p>
                                <p
                                    placeholder='Note'
                                    className='placeholder:italic break-words whitespace-normal max-h-[60px] overflow-y-auto text-xs cursor-default resize-none border-[#FFA653] border-dashed'
                                >{vendorItem.remarks || "NA"}
                                </p>
                            </div>

                            {/* 
                                <div className=' p-2'>
                                    <p className='text-[#434343] text-xs'>Remarks:</p>
                                    <p
                                        placeholder='Note'
                                        className='placeholder:italic break-words whitespace-normal max-h-[60px] overflow-y-auto text-xs cursor-default resize-none border-[#FFA653] border-dashed'
                                        dangerouslySetInnerHTML={{ __html: vendorItem.remarks || "NA" }}>abnsjdavgghdyu ajbsxdhvahs hjbashx bashx hvbas
                                    </p>
                                </div> */}

                            {/* <textarea placeholder='Note' className='placeholder:italic resize-none w-full border-t-[#FFA653] border-transparent border-dashed'>{vendorItem.remarks}</textarea> */}

                            {vendorItem.attachments && vendorItem.attachments.length > 0 && (
                                <div className="p-4">
                                    <h3 className="text-xs font-semibold text-[#434343]">Attachments</h3>
                                    <div className="space-y-2">
                                        {vendorItem.attachments.map((attachment, index) => {
                                            const lastIndex = attachment.Name.lastIndexOf('.'); // Extract file extension
                                            const path = lastIndex !== -1 ? attachment.Name.substring(lastIndex + 1) : null;
                                            return (
                                                <div key={index} className="flex justify-between items-center mt-2">
                                                    <div className="px-1 flex w-fit items-center justify-center border border-[#8E8E85] rounded-full py-1 space-x-1 text-[#434343]">
                                                        {/* Dynamic icon based on file type */}
                                                        <img src={`/assets/icons/${path}.png`} alt={path} className="h-5 w-5" />
                                                        {/* Document name now clickable */}
                                                        <a
                                                            href={attachment.Content}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            download={attachment.Name}
                                                            className="text-xs truncate text-[#434343] hover:underline"
                                                        >
                                                            {attachment.Name}
                                                        </a>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </td>
            );
        })}
    </tr>
);

export default ContractorRFQTable;
