import { closeRfq } from 'actions/rfqActions';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

const CloseRfqOpt = ({ closeModal, selectedSupplier, allSuppliers, rfqId, entityId, isApprovalRequired }) => {
  const dispatch = useDispatch();
  const rfqData = useSelector((state) => state.rfq);
  const { enqueueSnackbar } = useSnackbar();

  const { loading, rfq, errorMassage } = rfqData;
  const auth = useSelector((state) => state.auth);
  const user = auth?.user;

  const [selectedVendors, setSelectedVendors] = useState(
    selectedSupplier ? [selectedSupplier.businessName] : []
  );
  const [rejectionReason, setRejectionReason] = useState('');
  // const [loading, setLoading] = useState(false);

  const additionalVendors = allSuppliers
    .filter(supplier => supplier.businessName !== selectedSupplier?.businessName)
    .map(supplier => supplier.businessName);

  const handleCheckboxChange = (vendor) => {
    setSelectedVendors((prevSelected) =>
      prevSelected.includes(vendor)
        ? prevSelected.filter((v) => v !== vendor)
        : [...prevSelected, vendor]
    );
  };

  const removeVendorChip = (vendor) => {
    // Prevent removal if it's the selected supplier
    if (vendor !== selectedSupplier?.businessName) {
      setSelectedVendors((prevSelected) => prevSelected.filter((v) => v !== vendor));
    }
  };

  const handleCloseRfq = async () => {
    // setLoading(true); 

    if (selectedVendors.length === 0) {
      enqueueSnackbar("Select at least one vendor", { variant: 'warning', autoHideDuration: 3000 });
      return; // Prevent further execution
    }
    const selectedSupplierData = allSuppliers
      .filter((supplier) => selectedVendors.includes(supplier.businessName))
      .map((supplier) => ({
        supplierId: supplier.supplierId, // Get the supplierId
        closingAmount: supplier.vendorQuotations?.grandTotalWithoutGst || 0, // Use grandTotalWithGst or default to 0
      }));


    const payload = {
      rfqId: rfqId,
      entityId: entityId,
      supplierIds: selectedSupplierData,
      rejectionReason,
      managers: user?.managers,
      isApprovalRequired
    };

    try {
      // Dispatch the closeRfq action with the requestData
      const response = await dispatch(closeRfq({ payload }));

      // Handle success logic, if necessary
      enqueueSnackbar(isApprovalRequired ? "RFQ send for approval" : "RFQ closed successfully", { variant: 'success', autoHideDuration: 3000 });

      closeModal(); // Close the modal on success
    } catch (error) {
      // Handle error logic
      enqueueSnackbar("Error closing RFQ", { variant: 'error', autoHideDuration: 3000 });
      console.error('Error closing RFQ:', error);
    } finally {
      // End loading state regardless of success/failure
      // setLoading(false);
    }
  };

  return (
    <div className="bg-white bg-opacity-100 shadow-lg">
      <hr />
      <div className="mx-6 pt-2 overflow-y-auto">
        <div className="mb-4">
          <label className="block text-sm text-left font-semibold text-[#4F4F42] mb-2">
            Awarding Order to :
          </label>
          <div className="border min-h-[65px] bg-[#DCDCD0] bg-opacity-50 max-h-[85px] overflow-y-auto p-2 shadow-md">
            {selectedVendors.length === 0 ? (
              <p className="text-[#4F4F42] text-xs">Select Vendors from below list</p>
            ) : (
              selectedVendors.map((vendor, index) => (
                <span
                  key={index}
                  className="inline-block items-start bg-white font-normal px-3 py-1 rounded-full text-sm mr-2 mb-2"
                >
                  {vendor}
                  <button
                    onClick={() => removeVendorChip(vendor)}
                    className="ml-2 text-black"
                  >
                    &times; {/* Cross button */}
                  </button>
                </span>
              ))
            )}
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-left text-sm font-semibold text-[#4F4F42]">
            Select additional vendors for this Order
          </label>
          <p className="mb-2 text-xs font-normal text-left">
            (You can also award the vendor from their individual bid comparison card.)
          </p>
          <div className="bg-[#DCDCD0] bg-opacity-50 p-3 border shadow-md">
            <div className="max-h-[70px] overflow-y-auto p-1">
              {additionalVendors.map((vendor, index) => (
                <div key={index} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    id={`vendor-${index}`}
                    value={vendor}
                    checked={selectedVendors.includes(vendor)}
                    onChange={() => handleCheckboxChange(vendor)}
                    className="mr-2 focus:ring-1 rounded-sm"
                  />
                  <label htmlFor={`vendor-${index}`} className="text-sm font-normal text-[#4F4F42]">
                    {vendor}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-sm text-left font-semibold mb-2 text-[#4F4F42]">Reason for Rejection</label>
          <textarea
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
            rows="5"
            className="w-full border p-2 text-sm text-[#4F4F42] placeholder:text-[#4F4F42] placeholder:font-normal placeholder:text-xs"
            placeholder="Enter the reason for rejection here..."
          />
        </div>
      </div>
      <div
        style={{ boxShadow: '0 -4px 6px rgba(0, 0, 0, 0.1)' }}
        className="z-5 flex justify-end sticky bottom-0 bg-white space-x-2 py-4 px-6"
      >
        <button
          onClick={closeModal}
          className="px-4 py-2 text-xs font-normal text-gray-700 border hover:bg-gray-100"
        >
          Cancel
        </button>
        <button
          disabled={loading}
          onClick={handleCloseRfq}
          className="px-4 py-2 text-xs font-normal text-white bg-black hover:bg-gray-800"
        >
          {loading ? "Closing.." : `${isApprovalRequired ? 'Send for Approval' : 'Close RFQ'}`}
        </button>
      </div>
    </div>
  );
};

export default CloseRfqOpt;
