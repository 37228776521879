import authService from "services/authService";

import { fetchFavourites } from "./favouritesAction";
import { fetchRequirements } from "./requirementsAction";
import { fetchLeads } from "./leadsAction";
import { fetchOrders } from "./ordersAction";
import { fetchBusinessById, fetchBusinessByUserId } from "./businessActions";
import { updateCRMLeadById } from "./crmAction";

export const SIGNIN = "@auth/signin";
export const SIGNIN_SUCCESS = "@auth/signin-success";
export const SIGNIN_FAILURE = "@auth/signin-failure";

export const SILENT_LOGIN = "@auth/silent-login";
export const SIGNOUT = "@auth/signout";

export const SIGNIN_SEND_MOBILE_OTP = "@auth/signinsendmobileotp";
export const SIGNIN_SEND_MOBILE_OTP_SUCCESS =
  "@auth/signinsendmobileotp-success";
export const SIGNIN_SEND_MOBILE_OTP_FAILURE =
  "@auth/signinsendmobileotp-failure";

export const SIGNIN_WITH_MOBILE_OTP = "@auth/signinwithmobileotp";
export const SIGNIN_WITH_MOBILE_OTP_SUCCESS =
  "@auth/signinwithmobileotp-success";
export const SIGNIN_WITH_MOBILE_OTP_FAILURE =
  "@auth/signinwithmobileotp-failure";

export const REGISTER_USER_MOBILE = "@auth/register";
export const REGISTER_USER_MOBILE_SUCCESS = "@auth/register-success";
export const REGISTER_USER_MOBILE_FAILURE = "@auth/register-failure";

export const WHATSAPP_MOBILE_OTP_SEND = "@auth/whatsapp-otp";
export const WHATSAPP_MOBILE_OTP_SEND_SUCCESS = "@auth/whatsapp-otp-success";
export const WHATSAPP_MOBILE_LOGIN_OTP_SEND_SUCCESS = "@auth/whatsapp-login-otp-success";
export const WHATSAPP_MOBILE_OTP_SEND_FAILURE = "@auth/whatsapp-otp-failure";

export const VERIFY_REGISTER_OTP = "@auth/verify-register-otp";
export const VERIFY_REGISTER_OTP_SUCCESS = "@auth/verify-register-otp-success";
export const VERIFY_REGISTER_OTP_FAILURE = "@auth/verify-register-otp-failure";

export const LINK_LOGIN_START = "@auth/link-login-start";
export const LINK_LOGIN_SUCCESS = "@auth/link-login-success";



export const VERIFY_WHATSAPP_OTP = "@auth/verify-whtatsapp-otp";
export const VERIFY_WHATSAPP_OTP_SUCCESS = "@auth/verify-whatsapp-otp-success";
export const VERIFY_WHATSAPP_OTP_FAILURE = "@auth/verify-whatsapp-otp-failure";

// add new actions for swap user type
export const SWITCH_USER_TYPE = "@auth/switch-user-type";

export function signin(email, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: SIGNIN });
      // console.log("AuthAction.SignIn:", email, password);
      const user = await authService.signinWithEmailAndPassword(
        email,
        password
      );

      // console.log("AuthAction.SignIn: Resp:" , user);

      dispatch({
        type: SIGNIN_SUCCESS,
        payload: {
          user,
        },
      });

      // Initialize User Information
      dispatch(fetchFavourites());
      // dispatch(fetchRequirements());
      dispatch(fetchLeads(user?.vendorsIn, user?.userId));
      dispatch(fetchOrders());
      // dispatch(fetchBusinessById(user?.supplierId));
      dispatch(fetchBusinessByUserId(user?.userId))

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (error) {
      // console.log("AuthAction.SignIn: Error", error);
      dispatch({ type: SIGNIN_FAILURE });
      throw error;
    }
  };
}

export function signInSendMobileOTP(mobile) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SIGNIN_SEND_MOBILE_OTP,
        payload: {
          mobile,
        },
      });
      // console.log("AuthAction.signInSendMobileOTP:", mobile);
      const resp = await authService.signInSendMobileOTP(mobile);

      // console.log("AuthAction.signInSendMobileOTP: Resp:", resp);

      dispatch({
        type: SIGNIN_SEND_MOBILE_OTP_SUCCESS,
        payload: {
          mobile,
        },
      });

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (err) {
      // console.log("AuthAction.signInSendMobileOTP: Error", err);

      let errMsg = err?.response?.data?.message;
      const error = { message: errMsg };

      dispatch({ type: SIGNIN_SEND_MOBILE_OTP_FAILURE });
      throw error;
    }
  };
}

export function signInWithMobileOTP(mobile, otp) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SIGNIN_WITH_MOBILE_OTP,
        payload: {
          mobile,
        },
      });
      // console.log("AuthAction.signInWithMobileOTP:", mobile, otp);
      const user = await authService.signInWithMobileOTP(mobile, otp);

      // console.log("AuthAction.signInWithMobileOTP: User:", user);
      // const payload = resp?.payload;

      dispatch({
        type: SIGNIN_WITH_MOBILE_OTP_SUCCESS,
        payload: {
          user,
        },
      });

      // Initialize User Information
      dispatch(fetchFavourites());
      // dispatch(fetchRequirements());
      dispatch(fetchLeads(user?.vendorsIn, user?.userId));
      dispatch(fetchOrders());
      // dispatch(fetchBusinessById(user?.supplierId));
      dispatch(fetchBusinessByUserId(user?.userId))

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (error) {
      // console.log("AuthAction.signInWithMobileOTP: Error", error);

      // let errMsg = err?.response?.data?.message;
      // const error = { message: errMsg };

      dispatch({ type: SIGNIN_WITH_MOBILE_OTP_FAILURE });
      throw error;
    }
  };
}

export function signout() {
  return async (dispatch) => {
    authService.signout();

    dispatch({
      type: SIGNOUT,
    });
  };
}

export function setUserData(user) {
  return (dispatch) =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user,
      },
    });
}

export function registerUserWithMobile(name, mobile, onSubmitSuccess) {
  return async (dispatch) => {
    try {
      dispatch({
        type: REGISTER_USER_MOBILE,
        payload: {
          name,
          mobile,
        },
      });
      // console.log("AuthAction.SignIn:", email, password);
      const user = await authService.signUpMobile(name, mobile);
      if (user) {
        onSubmitSuccess()
      }

      // console.log("AuthAction.SignIn: Resp:" , user);

      dispatch({
        type: REGISTER_USER_MOBILE_SUCCESS,
        payload: {
          user,
        },
      });

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (error) {
      // console.log("AuthAction.SignIn: Error", error);
      dispatch({ type: REGISTER_USER_MOBILE_FAILURE });
      throw error;
    }
  };
}

export const sendOneTimeLoginLink = (email,rfqType,rfqId, callback) => async (dispatch) => {
  try {
      const response = await authService.sendOneTimeLoginLink(email,rfqType,rfqId,); // Assuming the service sends the request and returns a response

      // Assuming the response contains a success flag or relevant data
      callback({ error: false, message: 'Login link sent successfully!' }); // Pass success response to callback
  } catch (error) {
      console.error('Error sending login link:', error);
      
      // Handle error: you can send more details here if needed
      callback({ error: true, message: 'Failed to send the login link. Please try again later.' }); // Pass error response to callback
  }
};



export function sendwhatsAppOTP(whatsapp, onSubmitSuccess) {
  return async (dispatch) => {
    try {
      dispatch({
        type: WHATSAPP_MOBILE_OTP_SEND,
        payload: {
          whatsapp
        },
      });
      // console.log("AuthAction.SignIn:", email, password);
      const whatsAppOtpMsg = await authService.whatsAppMobileOTP(whatsapp);
      if (whatsAppOtpMsg) {
        onSubmitSuccess()
      }

      dispatch({
        type: WHATSAPP_MOBILE_OTP_SEND_SUCCESS,
        type: WHATSAPP_MOBILE_LOGIN_OTP_SEND_SUCCESS,
        payload: whatsAppOtpMsg,
      });

      // TODO SP Fetch Rest of the information for the user to initialize the application
    } catch (error) {
      dispatch({ type: WHATSAPP_MOBILE_OTP_SEND_FAILURE, payload: error.response.data });
      throw error;
    }
  };
}

export function verifyWhatsappOTP(whatsapp, otp) {
  return async (dispatch) => {
    try {
      dispatch({ type: VERIFY_WHATSAPP_OTP });
      const whatsAppOtpMsg = await authService.verifyWhatsappOTP(whatsapp, otp);
      dispatch({
        type: VERIFY_WHATSAPP_OTP_SUCCESS,
        payload: whatsAppOtpMsg,

      });
      console.log(whatsAppOtpMsg)

    } catch (error) {
      dispatch({
        type: VERIFY_WHATSAPP_OTP_FAILURE,
        payload: error.response.data
      });
      throw error;
    }

  }
}

export function updateCrmId(userId, crmLeadId) {
  return async (dispatch) => {
    try {
      const updatedCrmUserData = await authService.updatedCrmUserData(userId, crmLeadId);
      // console.log("CRM LEAD ID UPDATED IN USER DATA",updatedCrmUserData)
    } catch (error) {
      throw error;
    }
  };
}

export function verifyRegisterOTP(mobile, name, otp, crmLeadId, sourceName, isWhatsappNo, onSubmitSuccess, isVerified) {
  return async (dispatch) => {
    try {
      dispatch({ type: VERIFY_REGISTER_OTP });
      const payload = await authService.verifyRegisterOTP(mobile, name, otp, crmLeadId, sourceName, isWhatsappNo, isVerified);
      if (payload?.user) {
        onSubmitSuccess()
      }

      const user = payload?.user
      const userEntity = payload?.userEntity

      dispatch({
        type: VERIFY_REGISTER_OTP_SUCCESS,
        payload: {
          user:{...user,userEntity:userEntity},
          isWhatsappNo,
        },
      });

      // Initialize User Information
      dispatch(fetchFavourites());
      dispatch(fetchRequirements(user?.userId));
      dispatch(fetchLeads(user?.vendorsIn, user?.userId));
      dispatch(fetchOrders());
      // dispatch(fetchBusinessById(user?.supplierId));
      dispatch(fetchBusinessByUserId(user?.userId))

    } catch (error) {
      dispatch({ type: VERIFY_REGISTER_OTP_FAILURE });
      throw error;
    }
  };
}

export const loginWithToken = (token) => {
  return async (dispatch) => {
    try {
      // Dispatching LOGIN_START action if needed
      dispatch({ type: 'LINK_LOGIN_START' });

      // Call your backend API to verify the login token
      const response = await authService.loginWithLinkToken(token);

      // Extract user data from the response
      const user = response.user;
      const userEntity = response?.userEntity;

      // Dispatch the user login success action
      // dispatch({
      //   type: 'LINK_LOGIN_SUCCESS',
      //   payload: {
      //     user: { ...user, userEntity: userEntity },
      //   },
      // });

      dispatch({
        type: VERIFY_REGISTER_OTP_SUCCESS,
        payload: {
          user:{...user,userEntity:userEntity},
          
        },
      });

      // Initialize user-related data
      dispatch(fetchFavourites());
      dispatch(fetchRequirements(user?.userId));
      dispatch(fetchLeads(user?.vendorsIn, user?.userId));
      dispatch(fetchOrders());
      dispatch(fetchBusinessByUserId(user?.userId));

      // Return the response data for further use
      return response;

    } catch (error) {
      console.error("Error logging in with token:", error);
      throw error; // Throw the error to be handled in the component
    }
  };
};



export function swapUserType(userType) {
  return async (dispatch) => {
    dispatch({
      type: SWITCH_USER_TYPE,
      userType: userType,
    })
  }
}
