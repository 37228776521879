import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SidePanel from "layouts/SidePanel";
import Leads from "./Leads";

const LeadsNew = () => {
    const lead = useSelector((state) => state.lead);
    const loading = lead.loading;
    // console.log("aaaaaaa", loading);
    // console.log("aaaaaaa", lead);


    const navigate = useNavigate();
    const [openFilters, setOpenFilters] = useState(false);

    const handleFilters = () => {
        setOpenFilters(!openFilters);
    }

    const handleMyBusiness = () => {
        navigate("/my-business")
    }
    const handleLeads = () => {
        navigate("/leads")
    }


    const [filters, setFilters] = useState({
        date: "all", // 'last7Days', 'lastMonth', or 'all'
        status: "all", // 'open', 'closed', or 'all'
        quotationReceived: null,
    });

    const [showAll, setShowAll] = useState(false);

    const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ];

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const handleFilterChange = useCallback((key, value) => {
        setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
    }, []);

    const filterByDate = (lead) => {
        const createdDate = new Date(lead.createdAt);
        const now = new Date();

        if (filters.date === "last7Days") {
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(now.getDate() - 7);
            return createdDate >= sevenDaysAgo;
        }

        if (filters.date === "lastMonth") {
            const lastMonth = new Date();
            lastMonth.setMonth(now.getMonth() - 1);
            return createdDate >= lastMonth && createdDate <= now;
        }

        return true; // Show all leads for "all"
    };

    const openQuotationForm = (rfq) => {
        console.log("LEAD", rfq);

        // Check if filters.quotationReceived is true, if so, modify the URL paths to include isEdit=true and supplierId
        const isEdit = filters.quotationReceived === true;

        switch (rfq?.rfqType) {
            case "material":
                if (rfq?.materials && rfq.materials.length > 0) {
                    const path = isEdit
                        ? `/material-form?rfqId=${rfq?.rfqId}&isEdit=true&rfqType=${rfq?.rfqType}`
                        : `/material-form?rfqId=${rfq?.rfqId}&isEdit=false&rfqType=${rfq?.rfqType}`;
                    navigate(path, {
                        state: { rfqData: rfq, isEdit, supplierId: rfq?.suppliers[0]?.supplierId }
                    });
                } else {
                    console.warn("Materials data is missing or empty.");
                    // Optional: Show alert or handle missing materials data
                }
                break;

            case "labour":
                if (rfq?.labourDetails && rfq.labourDetails.length > 0) {
                    const path = isEdit
                        ? `/labour-form?rfqId=${rfq?.rfqId}&isEdit=true&rfqType=${rfq?.rfqType}`
                        : `/labour-form?rfqId=${rfq?.rfqId}&isEdit=false&rfqType=${rfq?.rfqType}`;
                    navigate(path, {
                        state: { rfqData: rfq, isEdit, supplierId: rfq?.suppliers[0]?.supplierId }
                    });
                } else {
                    console.warn("Labour details are missing or empty.");
                    // Optional: Show alert or handle missing labour data
                }
                break;

            case "contractor":
                if (rfq?.providingFixingDetails) {
                    const path = isEdit
                        ? `/providing-form?rfqId=${rfq?.rfqId}&isEdit=true&rfqType=${rfq?.rfqType}`
                        : `/providing-form?rfqId=${rfq?.rfqId}&isEdit=false&rfqType=${rfq?.rfqType}`;
                    navigate(path, {
                        state: { rfqData: rfq, isEdit, supplierId: rfq?.suppliers[0]?.supplierId }
                    });
                } else {
                    console.warn("Contractor details are missing or empty.");
                    // Optional: Show alert or handle missing contractor data
                }
                break;

            default:
                console.warn("Invalid RFQ type.");
            // Optional: Handle invalid RFQ type if necessary
        }
    }


    const filterByStatus = (lead) => {
        if (filters.status === "OPEN") {
            return lead.status === "OPEN";
        }

        if (filters.status === "CLOSED") {
            return lead.status === "CLOSED";
        }

        if (filters.status === "HOLD") {
            return lead.status === "HOLD";
        }

        return true; // Show all leads for "all"
    };

    const filterBySubmission = (lead) => {
        if (filters.quotationReceived === null ) {
            return true; // No filter, show all leads
        }
        if(filters.quotationReceived){
            return (lead.suppliers[0].quotationReceived === filters.quotationReceived);
        }
        return (lead.suppliers[0].quotationReceived === filters.quotationReceived && lead?.status !== "CLOSED")
    };



    const filteredLeads = (lead?.leads || [])
        .filter(filterByDate)
        .filter(filterByStatus);

    const handleToggleView = () => {
        setShowAll((prevState) => !prevState);
    };

    const sortedLeads = (lead?.leads || [])
        .filter(filterByDate)
        // .filter((row) => row.status !== "CLOSED") 
        .filter(filterBySubmission)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sorting by 'createdAt' (newest first)


    const tableHeaders = [
        { key: "createdAt", label: "Created Date" },
        { key: "rfqId", label: "Requirement ID" },
        { key: "name", label: "Requirement Name" },
        // { key: "rfqType", label: "Requirement Type" },
        { key: "actions", label: "Actions" },
        { key: "projectName", label: "Project Name" },
        { key: "userBusinessName", label: "Client Company Name" },
        { key: "requesterName", label: "Client Name" },
        { key: "requesterContact", label: "Client Contact" },
        { key: "requestedBy", label: "Client Email" },
        { key: "quotationReceived", label: "Quotation Status" },
        // { key: "status", label: "Requirement Status" },

    ];

    return (
        <>
            <div className="bg-white  lg:hidden sticky top-0 z-20 shadow-md p-4">
                <h1 className="text-base font-bold">Leads</h1>
            </div>
            {loading ? (
                <div className="flex flex-col lg:hidden justify-center items-center h-screen bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF]">
                    <div className="animate-spin rounded-full border-2 mb-2 border-t-2 border-orange-500 border-t-gray-200 h-8 w-8"></div>
                    <p className='text-sm font-medium'>Fetching Leads...</p>
                </div>
            ) : (<div>
                {/* Filter Section */}
                {sortedLeads?.length === 0 ? (
                    <div className="min-h-[calc(100vh-5rem)] lg:hidden bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF] flex justify-center items-center ">
                        <div className="flex flex-col items-center">
                            <img
                                src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Search_image.png"
                                alt="Search"
                                className="h-[116px] w-[122px] mb-6"
                            />
                            <p className="text-md font-semibold text-[#3C3C3C] text-center">
                                You can't submit any quotations to the buyer.
                                <br /> Please open and submit the quote for the RFQ you received.
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className="lg:hidden ">
                        {/* filter options */}
                        <div className="relative flex justify-start lg:hidden bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF] gap-2 py-2 px-4">
                            <button onClick={handleFilters} className="hidden font-semibold bg-white text-sm rounded-md border border-solid lg:w-[100px] px-4 py-2 my-auto shadow-md">Filters</button>
                            {/* <div className="flex justify-start gap-4 items-center">
                                <button className="border border-black rounded-sm px-4 py-1">View & Quote</button>
                                <button className="border border-black rounded-sm px-4 py-1">Submitted</button>
                            </div> */}
                            {/* Filter Buttons */}
                            <div className="flex justify-start gap-2 items-center">
                                <button
                                    onClick={() => setFilters({ quotationReceived: null })}
                                    className={`border border-black rounded-sm px-4 py-1 ${filters.quotationReceived === null ? "bg-black text-white" : ""
                                        }`}
                                >
                                    All
                                </button>
                                <button
                                    onClick={() => setFilters({ quotationReceived: false })}
                                    className={`border border-black rounded-sm px-4 py-1 ${filters.quotationReceived === false ? "bg-black text-white" : ""
                                        }`}
                                >
                                    New Requirement
                                </button>
                                <button
                                    onClick={() => setFilters({ quotationReceived: true })}
                                    className={`border border-black rounded-sm px-4 py-1 ${filters.quotationReceived === true ? "bg-black text-white" : ""
                                        }`}
                                >
                                    Submitted
                                </button>
                            </div>
                            {/* filter chips */}
                            {Object.keys(filters).some((key) => filters[key] && filters[key] !== "all") && (
                                <div className="flex flex-wrap gap-2">
                                    {filters.date && filters.date !== "all" && (
                                        <div className="flex items-center bg-white text-xs my-auto py-2 px-2  rounded-full shadow-md">
                                            <span className="mr-2">Date: {filters.date === "last7Days" ? "Last 7 Days" : "Last Month"}</span>
                                            <button
                                                onClick={() => handleFilterChange("date", "all")}
                                                className="text-gray-600 hover:text-black"
                                            >
                                                ✕
                                            </button>
                                        </div>
                                    )}
                                    {/* {filters.status && filters.status !== "all" && (
                                    <div className="flex items-center bg-white text-xs my-auto py-2 px-2 rounded-full shadow-md">
                                        <span className="mr-2">Status: {filters.status}</span>
                                        <button
                                            onClick={() => handleFilterChange("status", "all")}
                                            className="text-gray-600 hover:text-black"
                                        >
                                            ✕
                                        </button>
                                    </div>
                                )} */}
                                </div>
                            )}
                        </div>
                        {openFilters &&
                            <div className="absolute bg-white shadow-md border py-2 rounded-sm mx-4 z-40">
                                {/* <div className="mb-4 "> */}
                                <h3 className="text-sm font-medium px-4 mb-2 pt-2">Date</h3>
                                <div className="flex flex-col gap-x-2 text-sm">
                                    <label className="hover:bg-gray-200 px-4 py-1">
                                        <input
                                            type="radio"
                                            name="date"
                                            value="last7Days"
                                            checked={filters.date === "last7Days"}
                                            onChange={() => handleFilterChange("date", "last7Days")}
                                        />
                                        <span className="ml-2">Last 7 Days</span>
                                    </label>
                                    <label className="px-4 py-1 hover:bg-gray-200">
                                        <input
                                            type="radio"
                                            name="date"
                                            value="lastMonth"
                                            checked={filters.date === "lastMonth"}
                                            onChange={() => handleFilterChange("date", "lastMonth")}
                                        />
                                        <span className="ml-2">Last Month</span>
                                    </label>
                                    <label className="px-4 py-1 hover:bg-gray-200">
                                        <input
                                            type="radio"
                                            name="date"
                                            value="all"
                                            checked={filters.date === "all"}
                                            onChange={() => handleFilterChange("date", "all")}
                                        />
                                        <span className="ml-2">All Leads</span>
                                    </label>
                                </div>
                                {/* </div> */}
                                {/* <div>
                                <h3 className="text-sm px-4 font-medium py-2">Status</h3>
                                <div className="flex flex-col text-sm gap-x-2">
                                    <label className="px-4 py-1 hover:bg-gray-200">
                                        <input
                                            type="radio"
                                            name="status"
                                            value="OPEN"
                                            checked={filters.status === "OPEN"}
                                            onChange={() => handleFilterChange("status", "OPEN")}
                                        />
                                        <span className="ml-2">Open</span>
                                    </label>
                                    <label className="px-4 py-1 hover:bg-gray-200">
                                        <input
                                            type="radio"
                                            name="status"
                                            value="CLOSED"
                                            checked={filters.status === "CLOSED"}
                                            onChange={() => handleFilterChange("status", "CLOSED")}
                                        />
                                        <span className="ml-2">Closed</span>
                                    </label>
                                    <label className="px-4 py-1 hover:bg-gray-200">
                                        <input
                                            type="radio"
                                            name="status"
                                            value="HOLD"
                                            checked={filters.status === "HOLD"}
                                            onChange={() => handleFilterChange("status", "HOLD")}
                                        />
                                        <span className="ml-2">Hold</span>
                                    </label>
                                    <label className="px-4 py-1 mb-2 hover:bg-gray-200">
                                        <input
                                            type="radio"
                                            name="status"
                                            value="all"
                                            checked={filters.status === "all"}
                                            onChange={() => handleFilterChange("status", "all")}
                                        />
                                        <span className="ml-2">All</span>
                                    </label>
                                </div>
                            </div> */}
                            </div>}
                        <div className="block lg:hidden overflow-y-auto bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF] px-4" style={{ height: "700px" }}>
                            {sortedLeads.map((lead, index) => (
                                <div
                                    onClick={() => openQuotationForm(lead)}
                                    key={index}
                                    className="bg-white p-4 mb-2 shadow-md rounded-md border"
                                >
                                    <p className="text-sm  break-words w-full">
                                        <span className="text-gray-600">Requirement Name: </span>
                                        {lead?.name || "N/A"}
                                    </p>

                                    <p className="text-sm ">
                                        <span className="text-gray-600"> Requirement ID : </span>
                                        {lead?.rfqId || "N/A"}
                                    </p>
                                    <p className="text-sm ">
                                        <span className="text-gray-600"> Project Name : </span>
                                        {lead?.projectName || "N/A"} ({lead?.location})
                                    </p>
                                    {/* <h2 className="font-bold text-sm text-gray-800 mb-2">{lead.name || "N/A"}</h2> */}
                                    {/* <div className="flex items-center justify-between"> */}
                                    {/* <p className="text-sm ">
                                        <span className="text-gray-600"> Requirement Type: </span>
                                        {lead.rfqType ? capitalizeFirstLetter(lead.rfqType) : "N/A"}
                                    </p> */}

                                    {/* Quotation Status */}
                                    {/* <p className="text-sm ">
                                        <span className="text-gray-600">Quotation Status: </span>
                                        {lead?.suppliers?.[0]?.quotationReceived
                                            ? "Submitted"
                                            : "Pending"}
                                    </p> */}
                                    {/* </div> */}

                                    <p className="text-sm ">
                                        <span className="text-gray-600">Business Name: </span>
                                        {lead.userBusinessName || "N/A"}
                                    </p>



                                    <div className="flex justify-between items-center">
                                        {/* <p className="text-sm ">
                                            <span className="text-gray-600">RFQ Status: </span>
                                            {lead.status || "N/A"}
                                        </p> */}
                                        <p className="text-sm ">
                                            <span className="text-gray-600">Created Date: </span>
                                            {lead.createdAt
                                                ? `${new Date(lead.createdAt).getDate()} ${monthNames[new Date(lead.createdAt).getMonth()]}, ${new Date(lead.createdAt).getFullYear()}`
                                                : "N/A"}
                                        </p>
                                        {/* <button
                                            className="px-2 py-1 mt-2 shadow-md text-xs rounded-sm border whitespace-normal border-black"
                                            onClick={() => openQuotationForm(lead)}
                                        >
                                            Add Quotation
                                        </button> */}
                                        <button
                                            // className="border border-gray-400 shadow-md hover:text-black hover:border-black px-2 py-1 rounded-sm whitespace-nowrap"
                                            onClick={() => openQuotationForm(lead)}
                                            className={`px-2 py-1 self-center rounded-sm text-xs whitespace-nowrap ${
                                                lead?.suppliers?.[0]?.quotationReceived
                                                    ? "bg-blue-100 text-blue-600  border border-blue-600"
                                                    : "bg-blue-100 text-blue-600 border border-blue-600"
                                            }`}
                                        >
                                            {lead?.suppliers?.[0]?.quotationReceived === true
                                                ? "Update Quote"
                                                : "View & Quote"}
                                        </button>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>)}
            </div>)}
            {/* <div className="flex justify-between items-center p-4 bg-white sticky bottom-0">
                <button>Enquiry</button>
                <button>My Business</button>
            </div> */}

            <div
                className="sticky lg:hidden bottom-0 bg-white flex justify-between"
                style={{
                    boxShadow: "0 -4px 6px -2px rgba(0, 0, 0, 0.1)", // Top shadow
                }}
            >
                <button onClick={handleMyBusiness} className="w-1/2  p-4 hover:bg-gray-100 border-r border-black">My Business</button>
                <button onClick={handleLeads} className="w-1/2 p-4 hover:bg-gray-100">Requirements</button>
            </div>


            {/* desktop */}
            <div className="hidden lg:block">
                <SidePanel>
                    <div className="w-full h-screen bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF]">
                        <div className="bg-white sticky top-0 z-20 shadow-md p-4">
                            <h1 className="text-base font-bold">Leads</h1>
                        </div>

                        {loading ? (
                            <div className="flex flex-col justify-center items-center h-screen">
                                <div className="animate-spin rounded-full border-2 mb-2 border-t-2 border-orange-500 border-t-gray-200 h-8 w-8"></div>
                                <p className='text-sm font-medium'>Fetching Leads...</p>
                            </div>
                        ) : (
                            <div className="px-4 pt-2 lg:w-full">
                                <>
                                    {/* Leads Section */}
                                    {/* {filteredLeads.length === 0 ? (
                                    <div className=" h-full w-full p-auto">No leads found for the selected filters.</div>
                                ) : ( */}
                                    {sortedLeads?.length === 0 ? (
                                        <div className="min-h-[calc(100vh-5rem)] lg:hidden bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF] flex justify-center items-center ">
                                            <div className="flex flex-col items-center">
                                                <img
                                                    src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Search_image.png"
                                                    alt="Search"
                                                    className="h-[116px] w-[122px] mb-6"
                                                />
                                                <p className="text-md font-semibold text-[#3C3C3C] text-center">
                                                    You can't submit any quotations to the buyer.
                                                    <br /> Please open and submit the quote for the RFQ you received.
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="flex justify-start items-center gap-x-2">
                                                {/* Filter Section */}
                                                <div className="hidden">
                                                    <div className="relative flex items-center justify-start gap-2 bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF]">
                                                        <button onClick={handleFilters} className="font-semibold bg-white text-sm rounded-md border border-solid lg:w-[100px] px-4 py-2 mb-1 shadow-md">Filters</button>

                                                        {/* filter chips */}
                                                        {Object.keys(filters).some((key) => filters[key] && filters[key] !== "all") && (
                                                            <div className="flex flex-wrap gap-2">
                                                                {filters.date && filters.date !== "all" && (
                                                                    <div className="flex items-center bg-white text-xs my-auto py-2 px-2  rounded-full shadow-md">
                                                                        <span className="mr-2">Date: {filters.date === "last7Days" ? "Last 7 Days" : "Last Month"}</span>
                                                                        <button
                                                                            onClick={() => handleFilterChange("date", "all")}
                                                                            className="text-gray-600 hover:text-black"
                                                                        >
                                                                            ✕
                                                                        </button>
                                                                    </div>
                                                                )}
                                                                {filters.status && filters.status !== "all" && (
                                                                    <div className="flex items-center bg-white text-xs my-auto py-2 px-2 rounded-full shadow-md">
                                                                        <span className="mr-2">Status: {filters.status}</span>
                                                                        <button
                                                                            onClick={() => handleFilterChange("status", "all")}
                                                                            className="text-gray-600 hover:text-black"
                                                                        >
                                                                            ✕
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                    {openFilters &&
                                                        <div className="absolute bg-white shadow-md border py-2 rounded-sm z-40">
                                                            {/* <div className="mb-4 "> */}
                                                            <h3 className="text-sm font-medium px-4 mb-2 ">Date</h3>
                                                            <div className="flex flex-col gap-x-2 text-sm">
                                                                <label className="hover:bg-gray-200 px-4 py-1">
                                                                    <input
                                                                        type="radio"
                                                                        name="date"
                                                                        value="last7Days"
                                                                        checked={filters.date === "last7Days"}
                                                                        onChange={() => handleFilterChange("date", "last7Days")}
                                                                    />
                                                                    <span className="ml-2 text-sm">Last 7 Days</span>
                                                                </label>
                                                                <label className="px-4 py-1 hover:bg-gray-200">
                                                                    <input
                                                                        type="radio"
                                                                        name="date"
                                                                        value="lastMonth"
                                                                        checked={filters.date === "lastMonth"}
                                                                        onChange={() => handleFilterChange("date", "lastMonth")}
                                                                    />
                                                                    <span className="ml-2 text-sm">Last Month</span>
                                                                </label>
                                                                <label className="px-4 py-1 hover:bg-gray-200">
                                                                    <input
                                                                        type="radio"
                                                                        name="date"
                                                                        value="all"
                                                                        checked={filters.date === "all"}
                                                                        onChange={() => handleFilterChange("date", "all")}
                                                                    />
                                                                    <span className="ml-2 text-sm">All Leads</span>
                                                                </label>
                                                            </div>
                                                            {/* </div> */}
                                                            {/* <div>
                                                <h3 className="text-sm px-4 font-medium py-2">Status</h3>
                                                <div className="flex flex-col text-sm gap-x-2">
                                                    <label className="px-4 py-1 hover:bg-gray-200">
                                                        <input
                                                            type="radio"
                                                            name="status"
                                                            value="OPEN"
                                                            checked={filters.status === "OPEN"}
                                                            onChange={() => handleFilterChange("status", "OPEN")}
                                                        />
                                                        <span className="ml-2">Open</span>
                                                    </label>
                                                    <label className="px-4 py-1 hover:bg-gray-200">
                                                        <input
                                                            type="radio"
                                                            name="status"
                                                            value="CLOSED"
                                                            checked={filters.status === "CLOSED"}
                                                            onChange={() => handleFilterChange("status", "CLOSED")}
                                                        />
                                                        <span className="ml-2">Closed</span>
                                                    </label>
                                                    <label className="px-4 py-1 hover:bg-gray-200">
                                                        <input
                                                            type="radio"
                                                            name="status"
                                                            value="HOLD"
                                                            checked={filters.status === "HOLD"}
                                                            onChange={() => handleFilterChange("status", "HOLD")}
                                                        />
                                                        <span className="ml-2">Hold</span>
                                                    </label>
                                                    <label className="px-4 py-1 mb-2 hover:bg-gray-200">
                                                        <input
                                                            type="radio"
                                                            name="status"
                                                            value="all"
                                                            checked={filters.status === "all"}
                                                            onChange={() => handleFilterChange("status", "all")}
                                                        />
                                                        <span className="ml-2">All</span>
                                                    </label>
                                                       </div>
                                                      </div> */}
                                                        </div>}
                                                </div>
                                                {/* Always Visible Filters Panel */}
                                                <div className=" bg-white h-full min-w-[160px] self-start shadow-md border py-4 rounded-md z-40">
                                                    <p className="px-4 text-sm font-medium pb-2">Filters</p>
                                                    {/* Date Filters */}
                                                    <h3 className="text-sm px-4 text-gray-500">Date</h3>
                                                    <div className="flex flex-col gap-x-2 text-xs">
                                                        <label className="hover:bg-gray-200 px-4 py-1">
                                                            <input
                                                                type="radio"
                                                                name="date"
                                                                value="last7Days"
                                                                checked={filters.date === "last7Days"}
                                                                onChange={() => handleFilterChange("date", "last7Days")}
                                                                className="focus:ring-1"
                                                            />
                                                            <span className="ml-2 text-xs">Last 7 Days</span>
                                                        </label>
                                                        <label className="px-4 py-1 hover:bg-gray-200">
                                                            <input
                                                                type="radio"
                                                                name="date"
                                                                value="lastMonth"
                                                                checked={filters.date === "lastMonth"}
                                                                onChange={() => handleFilterChange("date", "lastMonth")}
                                                                className="focus:ring-1"
                                                            />
                                                            <span className="ml-2 text-xs">Last Month</span>
                                                        </label>
                                                        <label className="px-4 py-1 hover:bg-gray-200">
                                                            <input
                                                                type="radio"
                                                                name="date"
                                                                value="all"
                                                                checked={filters.date === "all"}
                                                                onChange={() => handleFilterChange("date", "all")}
                                                                className="focus:ring-1"
                                                            />
                                                            <span className="ml-2 text-xs">All Leads</span>
                                                        </label>
                                                    </div>
                                                    {/* <h3 className="text-sm px-4 text-gray-">Status</h3>
                                                    <div className="flex flex-col text-sm">
                                                        <label className="px-4 py-1 hover:bg-gray-200">
                                                            <input
                                                                type="radio"
                                                                name="status"
                                                                value="CLOSED"
                                                                checked={filters.quotationReceived === "CLOSED"}
                                                                onChange={() => handleFilterChange("status", "CLOSED")}
                                                            />
                                                            <span className="ml-2">Closed</span>
                                                        </label>
                                                        <label className="px-4 py-1 hover:bg-gray-200">
                                                            <input
                                                                type="radio"
                                                                name="status"
                                                                value="HOLD"
                                                                checked={filters.status === "HOLD"}
                                                                onChange={() => handleFilterChange("status", "HOLD")}
                                                            />
                                                            <span className="ml-2">Hold</span>
                                                        </label>
                                                        <label className="px-4 py-1 mb-2 hover:bg-gray-200">
                                                            <input
                                                                type="radio"
                                                                name="status"
                                                                value="all"
                                                                checked={filters.status === "all"}
                                                                onChange={() => handleFilterChange("status", "all")}
                                                            />
                                                            <span className="ml-2">All</span>
                                                        </label>
                                                    </div> */}
                                                    {/* Quotation Status Filter */}
                                                    {/* Quotation Status Filter */}
                                                    <h3 className="text-sm px-4 pt-2 text-gray-500">Quotation Status</h3>
                                                    <div className="flex flex-col">
                                                        <label className="px-4 hover:bg-gray-200">
                                                            <input
                                                                type="radio"
                                                                name="quotationReceived"
                                                                value={true}
                                                                checked={filters.quotationReceived === true}
                                                                onChange={() => handleFilterChange("quotationReceived", true)}
                                                                className="focus:ring-1"
                                                            />
                                                            <span className="ml-2 text-xs">Submitted</span>
                                                        </label>
                                                        <label className="px-4  hover:bg-gray-200">
                                                            <input
                                                                type="radio"
                                                                name="quotationReceived"
                                                                value={false}
                                                                checked={filters.quotationReceived === false}
                                                                onChange={() => handleFilterChange("quotationReceived", false)}
                                                                className="focus:ring-1"
                                                            />
                                                            <span className="ml-2 text-xs">New Requirement</span>
                                                        </label>
                                                        <label className="px-4 hover:bg-gray-200">
                                                            <input
                                                                type="radio"
                                                                name="quotationReceived"
                                                                value={null}
                                                                checked={filters.quotationReceived === null}
                                                                onChange={() => handleFilterChange("quotationReceived", null)}
                                                                className="focus:ring-1"
                                                            />
                                                            <span className="ml-2 text-xs">All Leads</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                {/* leads table */}
                                                <>
                                                    {/* <SidePanel> */}
                                                    <div className=" overflow-x-auto bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF]">
                                                        {/* <div className=""> */}
                                                        {sortedLeads?.length === 0 ? (
                                                            <div className="min-h-[calc(100vh-5rem)] flex justify-center items-center">
                                                                <div className="flex flex-col items-center">
                                                                    <img
                                                                        src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Search_image.png"
                                                                        alt="Search"
                                                                        className="h-[116px] w-[122px] mb-6"
                                                                    />
                                                                    <p className="text-md font-semibold text-[#3C3C3C] text-center">
                                                                        You can't submit any quotations to the buyer.
                                                                        <br /> Please open and submit the quote for the RFQ you received.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {/* <div> */}
                                                                <div className="rounded-md overflow-x-auto bg-white border border-[#DCDCD0] shadow-md hidden lg:block">
                                                                    <div className="overflow-x-auto" style={{ height: "500px" }}>
                                                                        <table className="min-w-full table-auto flex-grow">
                                                                            <thead className="bg-gray-100 border-b sticky top-0 z-10">
                                                                                <tr>
                                                                                    {tableHeaders.map((header) => (
                                                                                        <th
                                                                                            key={header.key}
                                                                                            className="text-left text-xs py-3 whitespace-nowrap border-b border-gray-300 text-[#727269] font-bold px-4"
                                                                                        >
                                                                                            {header.label}
                                                                                        </th>
                                                                                    ))}
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {sortedLeads.map((lead, index) => (
                                                                                    <tr
                                                                                        key={index}
                                                                                        className="bg-white border-b hover:bg-gray-50"
                                                                                    >
                                                                                        {tableHeaders.map((header) => (
                                                                                            <td
                                                                                            key={header.key}
                                                                                            className={`text-xs px-4 py-2 ${
                                                                                                header.key === "name" || header.key === "userBusinessName"
                                                                                                    ? "min-w-[280px] max-w-[280px] whitespace-normal break-words"
                                                                                                     :header.key === "actions"
                                                                                                    ? "text-center"
                                                                                                    : header.key === "requesterName"
                                                                                                    ? "min-w-[200px] text-left whitespace-normal break-words"
                                                                                                    : header.key === "rfqId"
                                                                                                    ? "text-right" // Align text to the right for rfqId
                                                                                                    : header.key === "projectName"
                                                                                                    ? "min-w-[240px] text-left whitespace-normal break-words"
                                                                                                     // Center actions text
                                                                                                    : ""
                                                                                            }`}
                                                                                        >
                                                                                                {/* Cell content */}
                                                                                                {header.key === "actions" ? (
                                                                                                   <div className="flex justify-center items-center">
                                                                                                   <button
                                                                                                       className={`px-2 py-1 self-center rounded-sm text-xs whitespace-nowrap w-[110px] ${
                                                                                                           lead?.suppliers?.[0]?.quotationReceived
                                                                                                               ? "bg-blue-100 text-blue-600  border border-blue-600"
                                                                                                               : "bg-blue-100 text-blue-600 border border-blue-600"
                                                                                                       }`}
                                                                                                       onClick={() => openQuotationForm(lead)}
                                                                                                   >
                                                                                                       {lead?.suppliers?.[0]?.quotationReceived ? "Update Quote" : "View & Quote"}
                                                                                                   </button>
                                                                                               </div>
                                                                                               
                                                                                                ) : header.key === "rfqType" ? (
                                                                                                    capitalizeFirstLetter(lead[header.key] || "N/A")
                                                                                                ) : header.key === "createdAt" ? (
                                                                                                    lead[header.key]
                                                                                                        ? `${new Date(lead[header.key]).getDate()} ${monthNames[new Date(lead[header.key]).getMonth()]
                                                                                                        }, ${new Date(lead[header.key]).getFullYear()}`
                                                                                                        : "N/A"
                                                                                                ) : header.key === "projectName" ? (
                                                                                                    <div className="whitespace-normal break-words">
                                                                                                        <p className="">{lead.projectName || "N/A"}</p>
                                                                                                        <p className="text-gray-500 text-xs">{lead.location || "N/A"}</p>
                                                                                                    </div>
                                                                                                ) : header.key === "quotationReceived" ? (
                                                                                                    lead?.suppliers?.[0]?.quotationReceived
                                                                                                        ? "Submitted"
                                                                                                        : "Pending"
                                                                                                ) : (
                                                                                                    lead[header.key] || "N/A"
                                                                                                )}
                                                                                            </td>
                                                                                        ))}
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>

                                                                </div>
                                                                {/* </div> */}
                                                            </>
                                                        )}
                                                        {/* </div> */}
                                                    </div>
                                                    {/* </SidePanel> */}
                                                </>
                                            </div>
                                        </>
                                    )}
                                </>
                            </div>)}
                    </div >
                </SidePanel >
            </div >
        </>
    );
};

export default LeadsNew;
