import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "utils/axios";
import Skeleton from "@mui/material/Skeleton"
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { submitLabourQuotation, submitProvidingQuotation } from "actions/vendorQuotationAction";
import { useSnackbar } from 'notistack';
import CustomGstDropdown from "./CustomGstDropdown";
import { vendorQuotationService } from "services/vendorQuotationService";


// Yup validation schema
const miscellaneousSchema = Yup.object().shape({
    mrp: Yup.number().typeError("Enter a valid MRP").required("MRP is required").min(0, "Invalid MRP"),
    gst: Yup.number().typeError("Enter a valid GST").required("GST is required"),
    totalCost: Yup.number().typeError("Total cost should be a number").required(),
});
const validationSchema = Yup.object().shape({
    comments: Yup.string().required("Comments/Methods are required"),
    basePrice: Yup.number()
        .required("Base Price is required")
        .positive("Invalid Base Price")
        .typeError("Base Price must be a number"),
    gst: Yup.number()
        .required("GST is required")
        .min(0, "GST cannot be negative")
        .max(100, "GST cannot be more than 100")
        .typeError("GST must be a number"),
    // deliveryDate: Yup.date().required("Required"),
    workStartDate: Yup.date(),

    // miscellaneousCosts: Yup.lazy(() =>
    //     Yup.object().shape({
    //         transport: miscellaneousSchema,
    //         loading: miscellaneousSchema,
    //         mathadeCharges: miscellaneousSchema,
    //         miscCharges: miscellaneousSchema,
    //     })
    // ),

});

const ProvidingQuotationForm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { loading, error, errorMessage, quotation } = useSelector((state) => state.vendorQuotation);
    const { enqueueSnackbar } = useSnackbar();
    const { isEdit } = location.state;
    const [rfqData, setRfqData] = useState(location.state?.rfqData || {});

    const supplierId = location.state?.supplierId || "";
    const quotationIndex = location.state?.quotationIndex;
    const isReadOnly = quotationIndex !== undefined

    useEffect(() => {
        const fetchVendorQuotation = async () => {
            if (supplierId) {
                try {
                    const payload = { rfqId: rfqData?.rfqId, supplierId };
                    const response = await vendorQuotationService.getVendorQuotation(payload);
                    // console.log("Fetched Vendor Quotation Data:", response);

                    // Update rfqData.suppliers[0] with fetched vendorQuotations
                    if (response?.vendorQuotations) {
                        setRfqData(prevData => ({
                            ...prevData,
                            suppliers: prevData.suppliers?.map((supplier, index) =>
                                index === 0 ? { ...supplier, vendorQuotations: response.vendorQuotations } : supplier
                            ) || []
                        }));
                    }
                } catch (error) {
                    console.error("Error fetching vendor quotation:", error);
                }
            }
        };

        fetchVendorQuotation();
    }, [supplierId, isEdit, rfqData?.rfqId]);

    useEffect(() => {
        if (rfqData?.suppliers?.[0]?.vendorQuotations) {

            const latestQuotationSet = quotationIndex !== undefined ? rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.[quotationIndex] : rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0];

            setTotalAmount(latestQuotationSet?.totalWithGst || 0)
            setIsCounterTerm(!latestQuotationSet?.agreedToPaymentTerms || false)
            setSelectedFilesBase(latestQuotationSet?.attachments || [])
            setOverallTotal(quotationIndex !== undefined ? (latestQuotationSet?.othersTotalWithGst + latestQuotationSet?.totalWithGst) : rfqData.suppliers[0].vendorQuotations.grandTotalWithGst || 0)
        }
    }, [rfqData, quotationIndex]);

    const auth = useSelector((state) => state.auth);
    const userProfile = auth.user;

    const isEditing = Boolean(rfqData?.suppliers?.[0]?.vendorQuotations);
    const lastQuotationSet = isEditing
        ? (quotationIndex !== undefined
            ? rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.[quotationIndex]
            : rfqData?.suppliers?.[0]?.vendorQuotations?.quotationSets?.slice(-1)[0])
        : null;

    const contractorDetails = isEditing || quotationIndex !== undefined ? lastQuotationSet?.contractorDetails : {};

    const initialValues = useMemo(() => ({
        comments: contractorDetails.remarks || '',
        basePrice: contractorDetails.basePrice || 0, // Initialize to 0
        gst: contractorDetails.gst || 0, // Initialize to 0
        workStartDate: rfqData.providingDate ? new Date(rfqData.providingDate).toISOString().split('T')[0] : '',
        agreedToPaymentTerms: isEditing ? lastQuotationSet?.agreedToPaymentTerms : true,
        counterPaymentTerms: isEditing ? lastQuotationSet?.counterPaymentTerms || "" : "",
        notes: isEditing ? lastQuotationSet?.notes || "" : "",
        miscellaneousCosts: {
            miscCharges: {
                mrp: isEditing ? lastQuotationSet?.otherCharges?.miscCharges?.mrp || 0 : 0,
                gst: isEditing ? lastQuotationSet?.otherCharges?.miscCharges?.gst || 0 : 0,
                totalCost: isEditing ? lastQuotationSet?.otherCharges?.miscCharges?.totalCost || 0 : 0
            }
        },
    }), [contractorDetails, isEditing, lastQuotationSet, rfqData]);


    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isDescPopupOpen, setIsDescPopupOpen] = useState(false);
    const [totalAmount, setTotalAmount] = useState(lastQuotationSet?.totalWithGst || 0);
    const [isCounterTerm, setIsCounterTerm] = useState(!lastQuotationSet?.agreedToPaymentTerms || false);
    const [attachmentsSkeleton, setAttachmentsSkeleton] = useState([]);
    const [selectedFilesBase, setSelectedFilesBase] = useState(lastQuotationSet?.attachments || []);
    const [selectedFilesTotalSize, setSelectedFilesTotalSize] = useState([]);
    const [attachmentsUploadsErrors, setAttachmentsUploadsErrors] = useState('');
    const [openFilesSizeError, setOpenFilesSizeError] = useState(false);
    const [overallTotal, setOverallTotal] = useState(rfqData?.suppliers?.[0]?.vendorQuotations?.grandTotalWithGst || 0);
    // const [miscellaneousCosts, setMiscellaneousCosts] = useState({
    //     transport: { mrp: 0, gst: 0, totalCost: 0 },
    //     loading: { mrp: 0, gst: 0, totalCost: 0 },
    //     mathadeCharges: { mrp: 0, gst: 0, totalCost: 0 },
    //     miscCharges: { mrp: 0, gst: 0, totalCost: 0 }
    // });

    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const capitalizeFirstLetter = (string) => {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    const toggleDescPopup = () => {
        setIsDescPopupOpen(!isDescPopupOpen);
    };

    const miscellaneousItems = [
        // { label: "Transport", name: "transport" },
        // { label: "Loading / Offloading (w/ GST)", name: "loading" },
        // { label: "Mathade Charges (w/ GST)", name: "mathadeCharges" },
        { label: "Misc. Charges", name: "miscCharges" },
    ];

    const { otherCharges } = rfqData || {};

    const visibleMiscellaneousItems = miscellaneousItems.filter(item => otherCharges?.[item.name]);

    // Check if any items are visible
    const hasVisibleMiscellaneousItems = visibleMiscellaneousItems.length > 0;

    const currentRank = rfqData?.suppliers[0]?.vendorQuotations?.rank
    // console.log(currentRank)

    const attachmentsData = rfqData?.selectedFilesBase
        ? rfqData.selectedFilesBase.map((item) => {
            const lastIndex = item.Name.lastIndexOf('.'); // Extract file extension
            const path = lastIndex !== -1 ? item.Name.substring(lastIndex + 1) : null;
            return { ...item, Path: path }; // Add the Path to determine file type
        })
        : [];

    const handleCloseForm = () => {
        if (supplierId) {
            navigate('/leads')
        } else {
            navigate(-1)
        }
    }

    // Function to calculate total amount
    const calculateTotalAmount = (basePrice, gst) => {
        return basePrice + (basePrice * gst / 100);
    };

    const calculateOverallTotal = (miscellaneousCosts, totalAmount) => {
        // Sum all the totalCosts from miscellaneous items
        const miscTotal = Object.values(miscellaneousCosts).reduce((sum, item) => sum + item.totalCost, 0);

        // Add the base price total (totalAmount)
        return miscTotal + totalAmount;
    };

    // Handle change functions
    const handleBasePriceChange = (e, setFieldValue, values, setTotalAmount) => {
        const basePrice = parseFloat(e.target.value) || 0;
        setFieldValue("basePrice", basePrice);

        const newTotalAmount = calculateTotalAmount(basePrice, parseFloat(values.gst) || 0);
        setTotalAmount(newTotalAmount);

        // Update overall total after calculating base price total
        const newOverallTotal = calculateOverallTotal(values.miscellaneousCosts, newTotalAmount);
        setOverallTotal(newOverallTotal);
    };

    const handleGstChange = (e, setFieldValue, values, setTotalAmount) => {
        const gst = parseFloat(e.target.value) || 0;
        setFieldValue("gst", gst);

        const newTotalAmount = calculateTotalAmount(parseFloat(values.basePrice) || 0, gst);
        setTotalAmount(newTotalAmount);

        // Update overall total after calculating base price total
        const newOverallTotal = calculateOverallTotal(values.miscellaneousCosts, newTotalAmount);
        setOverallTotal(newOverallTotal);
    };


    const calculateTransportationTotalCost = (mrp, gst) => {
        const gstValue = parseFloat(gst) || 0;
        const mrpValue = parseFloat(mrp) || 0;
        return mrpValue + (mrpValue * gstValue) / 100;
    };


    // Handler for miscellaneous cost inputs
    const handleMiscellaneousChange = (field, value, setFieldValue, values, type) => {
        const parsedValue = value === "" ? "" : parseFloat(value) || 0;
        const updatedMiscellaneousCosts = { ...values.miscellaneousCosts };

        updatedMiscellaneousCosts[field][type] = parsedValue;

        // Calculate total cost if both MRP and GST are valid
        if (updatedMiscellaneousCosts[field].mrp !== "" && updatedMiscellaneousCosts[field].gst !== "") {
            updatedMiscellaneousCosts[field].totalCost = calculateTransportationTotalCost(
                updatedMiscellaneousCosts[field].mrp,
                updatedMiscellaneousCosts[field].gst
            );
        } else {
            updatedMiscellaneousCosts[field].totalCost = 0;
        }

        setFieldValue(`miscellaneousCosts.${field}.${type}`, parsedValue);
        // setMiscellaneousCosts(updatedMiscellaneousCosts);

        // Update overall total after miscellaneous items update
        const newOverallTotal = calculateOverallTotal(updatedMiscellaneousCosts, totalAmount);
        setOverallTotal(newOverallTotal);
    };

    const handleMiscGstChange = (field, value, setFieldValue, values) => {
        handleMiscellaneousChange(field, value, setFieldValue, values, 'gst');
    };

    const handleFileUploadClick = (event) => {
        event.target.value = '';
    };

    const handleFileUpload = (event) => {
        const files = event.target.files;
        const sizeOfSelectedFiles = Array.from(files).reduce((acc, obj) => acc + obj.size, 0);
        const sizeOfAllSelectedFiles = selectedFilesTotalSize.reduce((acc, obj) => acc + obj.FileSize, sizeOfSelectedFiles);

        if (sizeOfAllSelectedFiles < MAX_FILE_SIZE) {
            setAttachmentsSkeleton((prevVal) => [...prevVal, ...Array.from(files)]);

            const convertedFilesPromises = Array.from(files).map((file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const fileContent = e.target.result;
                        const encryptedContent = btoa(fileContent); // Encrypt file content in Base64
                        let contentType = file.type;
                        if (!contentType && file.name.endsWith('.dwg')) {
                            contentType = 'application/acad'; // MIME type for DWG files
                        }
                        const payload = {
                            Name: file.name,
                            Content: encryptedContent,
                            ContentType: contentType,
                            FileSize: file.size,
                        };
                        resolve(payload);
                    };
                    reader.readAsBinaryString(file);
                });
            });

            Promise.all(convertedFilesPromises)
                .then((convertedFiles) => attachmentBlobLink(convertedFiles))
                .catch((error) => console.error(error));
        } else {
            setOpenFilesSizeError(true);
            setTimeout(() => setOpenFilesSizeError(false), 6000);
        }
    };

    const handleDeleteSelectedFile = (name, event) => {
        event.preventDefault();
        setSelectedFilesBase((prevVal) => prevVal.filter((data) => data.Name !== name));
        setSelectedFilesTotalSize((prevVal) => prevVal.filter((data) => data.Name !== name));
    };

    const handleCloseFileSizeError = () => {
        setOpenFilesSizeError(false);
    };

    const attachmentBlobLink = (convertedFiles) => {
        axios.post('/api/rfqs/upload/attachments', convertedFiles)
            .then((response) => {
                setSelectedFilesBase((prevVal) => [...prevVal, ...response.data]);
                setSelectedFilesTotalSize((prevVal) => [...prevVal, ...convertedFiles]);
                setAttachmentsSkeleton([]);
            })
            .catch((error) => {
                setAttachmentsSkeleton([]);
                setAttachmentsUploadsErrors(error.message);
                setTimeout(() => setAttachmentsUploadsErrors(''), 5000);
                console.error(error);
            });
    };


    useEffect(() => {
        if (!loading && quotation && Object.keys(quotation).length > 0) {
            // Show success snackbar when quotation is successfully submitted
            enqueueSnackbar('Vendor quotation submitted successfully!', {
                variant: 'success',
                autoHideDuration: 1000,
            });
        }

        if (!loading && error) {
            // Show error snackbar when there's an error
            enqueueSnackbar(`Error: ${errorMessage}`, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    }, [loading, quotation, error, errorMessage, enqueueSnackbar]);

    const [currentStep, setCurrentStep] = useState(0);

    const steps = [
        "rfq info",
        "Input Fields",
        // "Miscellaneous",
        "Supporting Documents",
        "Additional Information & Upload",
        "Completion"
    ];

    const handleNextStep = async (validateForm, setTouched, values) => {
        const errors = await validateForm(); // Trigger Formik validation
        const hasErrors = Object.keys(errors).length > 0; // Check if there are any errors
        // Ensure all touched fields are set if there are errors
        if (hasErrors) {
            setTouched(errors, true); // This marks all error fields as touched
            return; // Prevent moving to the next step if there are errors
        }
        // If no errors, move to the next step
        setCurrentStep((prevStep) => prevStep + 1);
    };



    const handlePreviousStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    return (
        <div className="max-w-7xl lg:mx-auto min-h-screen bg-white shadow-md" style={{ fontFamily: "goldman_sans" }}>

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    const providingFixingDetails = {
                        remarks: values.comments,
                        basePrice: values.basePrice,
                        gst: values.gst,
                        workStartDate: values.workStartDate,
                        description: rfqData?.providingFixingDetails
                    }
                    const payload = {
                        providingFixingDetails,
                        paymentTerms: values.paymentTerms,
                        counterPaymentTerms: values.counterPaymentTerms,
                        notes: values.notes,
                        rfqId: rfqData?.rfqId,
                        rfqType: rfqData?.rfqType,
                        supplierId: rfqData?.suppliers?.[0]?.supplierId,
                        submittedBy: userProfile?.name,
                        userId: userProfile?.userId,
                        otherCharges: values.miscellaneousCosts,
                        selectedFilesBase

                    }

                    // const payload = { ...values, selectedFilesBase,  }
                    try {
                        await dispatch(submitProvidingQuotation({ values: payload }));
                        if (supplierId) {
                            navigate('/leads')
                        } else {
                            navigate(-1)
                        }
                        // Optionally handle success state here
                    } catch (error) {
                        // Handle errors here, such as showing an error message
                        console.error("Submission failed:", error);
                    }
                }}
            >
                {({ setFieldValue, values, validateForm, setTouched, }) => (
                    <Form onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault(); }}>

                        {/* mobile  */}
                        <div className="block lg:hidden h-screen">

                            {/* Stepper */}
                            {currentStep === 0 ? " " : (
                                <div className="z-20 sticky top-0 shadow-md bg-white flex justify-center items-center px-10 py-6 w-full lg:hidden">
                                    {/* Step 1 */}
                                    <div className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 0 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'}`}>
                                        <p className={`text-xs font-medium ${currentStep > 0 ? 'text-white' : '#313119'}`}>1</p>
                                    </div>
                                    <div className={`flex-grow h-[2px] ${currentStep > 1 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'} mx-2`}></div>

                                    {/* Step 2 */}
                                    <div className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 1 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'}`}>
                                        <p className={`text-xs font-medium ${currentStep > 1 ? 'text-white' : '#313119'}`}>2</p>
                                    </div>
                                    <div className={`flex-grow h-[2px] ${currentStep > 2 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'} mx-2`}></div>

                                    {/* Step 3 */}
                                    <div className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 2 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'}`}>
                                        <p className={`text-xs font-medium ${currentStep > 2 ? 'text-white' : '#313119'}`}>3</p>
                                    </div>
                                    {/* <div className={`flex-grow h-[2px] ${currentStep > 3 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'} mx-2`}></div> */}

                                    {/* Step 4 */}
                                    {/* <div className={`h-[30px] w-[30px] rounded-full flex justify-center items-center ${currentStep > 3 ? 'bg-[#313119]' : 'bg-[#DCDCDC]'}`}>
                                        <p className={`text-xs font-medium ${currentStep > 3 ? 'text-white' : '#313119'}`}>4</p>
                                    </div> */}
                                </div>
                            )}

                            {/* stepper content */}
                            <div className="h-screen flex-grow overflow-auto">
                                <div className="flex justify-between">
                                    {steps.map((step, index) => (
                                        <div key={index} className={`step ${currentStep === index ? 'active' : ''}`}>
                                            {/* {step} */}
                                        </div>
                                    ))}
                                </div>

                                <div className="step-content">
                                    {currentStep === 0 && (
                                        <div>
                                            <div className="p-4 sticky top-0 flex items-center justify-center shadow-md">
                                                <div >
                                                    <div className="flex items-center space-x-2">
                                                        <span class="relative flex items-center justify-center h-4 w-4 ">
                                                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-lime-500 opacity-40"></span>
                                                            <span class="relative inline-flex rounded-full h-2 w-2 bg-lime-500"></span>
                                                        </span>
                                                        <h3 className="text-sm lg:mt-1 text-center text-[#737373]">
                                                            Quotation Rank:{" "}
                                                            <span className="font-bold text-black">
                                                                {currentRank === "L1"
                                                                    ? "L2"
                                                                    : currentRank === undefined || currentRank === null
                                                                        ? "Quotation not submitted"
                                                                        : currentRank}
                                                            </span>
                                                        </h3>
                                                        {/* <p className="text-[#9F9F9F] lg:mt-1 text-xs italic hidden lg:block">Your rank will update as soon as the buyer receives atleast 3 ratings</p> */}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* rfq information */}
                                            <div className="px-4 lg:py-[24px] lg:px-8">
                                                <div className="mt-4 mb-1 lg:flex lg:justify-between lg:space-x-10">
                                                    {/* <h3 className="text-xl font-semibold text-[#3B3B3B]">{rfqData?.name}</h3> */}
                                                    <h3 className="text-sm font-semibold text-[#3B3B3B]"> <span className="text-[#787878]">Requirement Name:</span>  {rfqData?.name}</h3>
                                                    {/* <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Bookmark.svg" alt="save" className="self-start" /> */}
                                                </div>
                                                <p className="text-sm font-normal text-[#787878] lg:hidden">Create Date: {new Date(rfqData?.createdAt).toLocaleDateString('en-GB')}</p>

                                                <p className="text-sm font-medium mb-2 mt-6 lg:hidden">RFQ Information</p>
                                                <div className="border sm:grid sm:grid-cols-2 space-y-4 sm:space-y-0 shadow-lg bg-white p-5 lg:py-[28px] lg:px-[20px]">
                                                    <div className="space-y-4">
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">Status</p>
                                                            <div className="flex items-center space-x-2">
                                                                <div className="w-[15px] h-[15px] rounded-full bg-[#BFFFBA]"></div>
                                                                <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.status}</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">RFQ Name</p>
                                                            <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.name}</p>
                                                        </div>

                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">RFQ Type</p>
                                                            <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.rfqType}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">Client</p>
                                                            <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.userBusinessName}</p>
                                                        </div>
                                                    </div>
                                                    <div className="space-y-4">
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">Expected Delivery Date</p>
                                                            <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.providingDate ? new Date(rfqData?.providingDate).toLocaleDateString('en-GB') : "-"}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">Project Name</p>
                                                            <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.projectName}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal">Delivery Location</p>
                                                            <div className="flex items-center gap-2">
                                                                <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/map_pin_vendor_quotation.svg" alt="" className="mb-1" />
                                                                <p className="text-[#4F4F42] text-sm font-bold">{rfqData?.location}</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className="text-[#6B7280] text-sm font-normal  ">Contact</p>
                                                            <p className="text-[#4F4F42] text-sm font-bold ">{rfqData?.requesterName} ({rfqData?.requesterContact})</p>
                                                        </div>
                                                        {/* <div>
                                                        <p className="text-[#6B7280] text-sm font-normal">Payment Terms</p>
                                                        <p className="text-[#4F4F42] text-sm font-bold bg-[#ECECEC] lg:bg-white mt-1 p-4 lg:m-0 lg:p-0">90 Days PDC</p>
                                                    </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="px-4">
                                        {currentStep === 1 && (
                                            <div>
                                                {/* input fields */}
                                                {/* providing and fixing */}
                                                <div >
                                                    <div className="flex items-center mt-4">
                                                        <h3 className="whitespace-nowrap text-[#434343] hidden lg:block uppercase text-sm font-medium">Providing & Fixing</h3>
                                                        <hr className="flex-grow border-t hidden lg:block border-gray-300 ml-4" />
                                                    </div>
                                                    <div>
                                                        <h3 className="text-sm font-semibold text-[#3B3B3B]  lg:hidden">Providing & Fixing Requirements</h3>
                                                        <p className="text-xs font-normal text-[#9D9D9D]  lg:hidden pb-4">Add quotations for providing & fixing
                                                            requirements below</p>
                                                    </div>

                                                    <div className="space-y-4 lg:gap-4 text-[#373737]">

                                                        {/* details */}
                                                        <div className="bg-[#F6F6F4] max-h-[100px] overflow-y-auto col-span-2 py-2 px-4 w-full " >
                                                            <p className="w-full border-none col-span-3 p-0 bg-[#F6F6F4] text-sm" dangerouslySetInnerHTML={{
                                                                __html:
                                                                    rfqData?.providingFixingDetails.length > 100
                                                                        ? rfqData?.providingFixingDetails.slice(0, 100) + "..."
                                                                        : rfqData?.providingFixingDetails,
                                                            }}>
                                                            </p>
                                                            {rfqData?.providingFixingDetails.length > 100 && <button
                                                                onClick={togglePopup}
                                                                type="button"
                                                                className="text-blue-600 text-xs underline pt-2"
                                                            >
                                                                Read More
                                                            </button>}

                                                            {/* Popup Modal */}
                                                            {isPopupOpen && (
                                                                <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">

                                                                    <div className="bg-white border-[25px] mx-4 border-white rounded-lg shadow-lg max-w-md w-full max-h-[320px] relative" style={{ overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                                                        <style>{` .hide-scrollbar {-ms-overflow-style: none; /* Internet Explorer 10+ */
                                                                                      scrollbar-width: none; /* Firefox */
                                                                                       }
                                                                                     .hide-scrollbar::-webkit-scrollbar {
                                                                                    display: none; /* Safari and Chrome */
                                                                                   }
                                                                               `}
                                                                        </style>
                                                                        <div className="sticky top-0 bg-white pb-4">
                                                                            {/* Close Button */}
                                                                            <button
                                                                                onClick={togglePopup}
                                                                                className="absolute top-0 right-0 text-gray-400 hover:text-gray-600"
                                                                            >
                                                                                <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                                                </svg>
                                                                            </button>
                                                                            <h2 className="text-sm font-semibold mb-2">Providing & Fixing Details</h2>
                                                                            <hr />
                                                                        </div>
                                                                        <p className="text-sm text-[#3D3D3D] overflow-y-auto " dangerouslySetInnerHTML={{
                                                                            __html: rfqData?.providingFixingDetails
                                                                        }}>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div>
                                                            <label className="font-semibold text-sm pb-2 text-start col-span-1 flex items-center relative">
                                                                Comments/Methods
                                                                <span className="bg-[#cac9c9] text-white text-xs h-[15px] w-[15px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group hover:cursor-pointer">
                                                                    i
                                                                    <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] z-10 transform -translate-x-1/2  py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                        Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                                    </span>
                                                                </span>
                                                            </label>
                                                            <Field
                                                                as="textarea"
                                                                name="comments"
                                                                className=" overflow-y-auto p-2 resize-none border whitespace-pre-line border-gray-300 w-full placeholder-[#373737]"
                                                            // placeholder="jdvwhgevd hvwdvwe vehvwe hvhg vhvshd dshs dsdhj vsvd bshdcs vvdwev hsdh hsdchvsdk jsdjcs shdcjksvd svdcs hsdcvshd"
                                                            // rows="4"
                                                            />
                                                            <ErrorMessage name="comments" component="div" className="text-red-500 text-sm mt-1" />
                                                        </div>

                                                        <div className=" self-start">
                                                            <label className="font-semibold text-sm pb-2 text-start col-span-1 flex items-center relative">
                                                                Base Price
                                                                <span className="bg-[#cac9c9] text-white text-xs h-[15px] w-[15px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group hover:cursor-pointer">
                                                                    i
                                                                    <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] z-10 transform -translate-x-1/2  py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                        Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                                    </span>
                                                                </span>
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                name="basePrice"
                                                                className=" h-[40px] border border-gray-300 placeholder-[#373737]"
                                                                value={values.basePrice} // Bind to Formik's values
                                                                onChange={(e) => handleBasePriceChange(e, setFieldValue, values, setTotalAmount)} // Update and calculate
                                                            />
                                                            <ErrorMessage name="basePrice" component="div" className="text-red-500 text-sm mt-1" />
                                                        </div>
                                                        {/* gst */}
                                                        <div className="self-start h-fit w-1/3">
                                                            <label htmlFor="" className="text-sm pb-2 font-semibold lg:hidden">GST (%)</label>
                                                            <CustomGstDropdown
                                                                options={[0, 5, 12, 18, 28]}
                                                                // Display the current value
                                                                value={values.gst} // Bind GST dropdown to Formik's values
                                                                onChange={(value) => handleGstChange({ target: { value } }, setFieldValue, values, setTotalAmount)} // Update and calculate
                                                                className=" mt-1 h-[40px]"
                                                            />
                                                            {/* <ErrorMessage name={`lineItems[${index}].gst`} component="div" className="text-red-500 text-sm h-fit" /> */}
                                                        </div>



                                                        <div className="self-start relative">
                                                            <label className="font-semibold text-sm pb-2 text-start col-span-1 flex items-center relative">
                                                                Work Start Date
                                                                <span className="bg-[#cac9c9] text-white text-xs h-[15px] w-[15px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group hover:cursor-pointer">
                                                                    i
                                                                    <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] z-10 transform -translate-x-1/2  py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                        Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                                    </span>
                                                                </span>
                                                            </label>
                                                            <Field
                                                                type="date"
                                                                name="workStartDate"
                                                                className="h-[40px] border border-gray-300 placeholder-[#373737]"
                                                            />
                                                            <div className="absolute mt-1 text-red-500 text-sm">
                                                                <ErrorMessage name="workStartDate" component="div" className="text-red-500 text-sm mt-1" />
                                                            </div>
                                                        </div>

                                                        <div className="col-span-1 py-2 px-4 text-base flex justify-between items-center w-full bg-[#E7EAEF] border border-t-[#004491] ">
                                                            <p className="text-[#004491] font-bold lg:hidden">Total Amount</p>
                                                            <div className="p-2 h-[40px] font-bold lg:font-normal text-right lg:text-black text-[#004491]">
                                                                Rs.{totalAmount.toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {/* miscellaneous */}
                                        {/* {currentStep === 2 && (
                                            <div>
                                                <div>
                                                    <h3 className="text-sm mt-4 font-medium text-[#3B3B3B] lg:hidden">Miscellaneous Charges</h3>
                                                    <p className="text-xs font-normal text-[#9D9D9D] pb-4 lg:hidden">
                                                        Please indicate any miscellaneous charges that will be incurred
                                                    </p>

                                                    <div className="border shadow-md px-3 pb-3 pt-4 space-y-6 lg:hidden">
                                                        {miscellaneousItems.map((item, index) => (
                                                            <div key={index} className="grid grid-cols-1 gap-4">
                                                                <p className="text-sm font-semibold">{item.label}</p>

                                                                <div className="flex justify-between items-center">
                                                                    <div className="flex justify-center items-center space-x-4">
                                                                        <Field
                                                                            name={`miscellaneousCosts.${item.name}.mrp`}
                                                                            type="text"
                                                                            className="h-[40px] border border-gray-300"
                                                                            placeholder="Rs."
                                                                            onChange={(e) =>
                                                                                handleMiscellaneousChange(
                                                                                    item.name,
                                                                                    e.target.value,
                                                                                    setFieldValue,
                                                                                    values,
                                                                                    'mrp'
                                                                                )
                                                                            }
                                                                            value={values.miscellaneousCosts[item.name]?.mrp || ''}
                                                                        />
                                                                    </div>

                                                                    <div>
                                                                        <p className="text-sm font-semibold">GST</p>

                                                                        <CustomGstDropdown
                                                                            className="w-1/2 h-[40px]"
                                                                            options={[0, 5, 12, 18, 28]}
                                                                            value={values.miscellaneousCosts[item.name]?.gst}
                                                                            onChange={(value) =>
                                                                                handleMiscGstChange(item.name, value, setFieldValue, values)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="flex justify-between items-center py-3 px-4 bg-[#E7EAEF] border border-t-[#004491]">
                                                                    <p className="text-[#004491] font-semibold">Sub Total</p>
                                                                    <p className="text-[#004491] font-semibold">
                                                                        Rs. {values.miscellaneousCosts[item.name]?.totalCost?.toFixed(2) || '0.00'}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ))}

                                                        <div className="flex px-8 py-5 justify-between items-center border border-t-[#007366] bg-[#E0F0EE]">
                                                            <p className="text-[#007366] font-semibold">Total Amount</p>
                                                            <p className="bg-[#E0F0EE] text-[#007366] font-semibold text-end w-fit min-w-[164px] py-2">
                                                                Rs. {totalAmount?.toFixed(2) || '0.00'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        )} */}
                                        {currentStep === 2 && (
                                            <div>
                                                {/* Supporting Documents Section */}
                                                <h3 className="text-sm font-semibold text-[#3B3B3B] lg:hidden  mt-4">Supporting Documents</h3>
                                                <p className="text-sm font-normal text-[#9D9D9D]  pb-4">Below are the supporting documents uploaded by the buyer. Please review them.</p>
                                                <div className="lg:grid lg:grid-cols-4 lg:gap-4 space-y-4 lg:space-y-0 lg:mt-4 ">
                                                    {attachmentsData.length > 0 ? (
                                                        attachmentsData.map((doc, index) => (
                                                            <div key={index} className="flex justify-between items-center mx-2">
                                                                <div className="px-2 flex w-fit items-center justify-center border border-[#8E8E85] rounded-full py-1 space-x-2 text-[#434343]">
                                                                    {/* Dynamic icon based on file type */}
                                                                    <img src={`/assets/icons/${doc.Path}.png`} alt={doc.Path} className="h-5 w-5" />
                                                                    {/* Document name now clickable */}
                                                                    <a
                                                                        href={doc.Content}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download={doc.Name}
                                                                        className="text-xs text-[#434343] hover:underline"
                                                                    >
                                                                        {doc.Name}
                                                                    </a>
                                                                </div>
                                                                <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/download_solid.svg" alt="" className="lg:hidden" />
                                                            </div>
                                                        ))) : <>
                                                        <div className="text-gray-400 italic">No Supporting Documents provided by Buyer, move to next step </div>
                                                    </>}
                                                </div>
                                            </div>
                                        )}

                                        {currentStep === 3 && (
                                            <div >
                                                {/* Additional Information Section */}
                                                <div className="mb-8">

                                                    <h3 className="text-sm mt-4 font-semibold text-[#3B3B3B] lg:hidden">Additional Information</h3>
                                                    <p className="text-sm font-normal lg:mx-0 pb-2 pt-4 lg:hidden">Below are our payment Terms. </p>

                                                    <div className="lg:flex justify-between items-center">
                                                        <div>
                                                            <div className="bg-[#F6F6F4] py-2 px-4 lg:w-[390px] lg:h-[182px] lg:mx-0 " >
                                                                <p
                                                                    className="w-full border-none p-0 bg-[#F6F6F4] text-[#3D3D3D] text-sm"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            rfqData?.paymentTerms.length > 180
                                                                                ? rfqData.paymentTerms.slice(0, 180) + "..."
                                                                                : rfqData.paymentTerms,
                                                                    }}
                                                                ></p>

                                                                {rfqData?.paymentTerms.length > 180 && <button
                                                                    onClick={togglePopup}
                                                                    type="button"
                                                                    className="text-[#8E8E85] text-sm underline pt-2"
                                                                >
                                                                    Read More
                                                                </button>}

                                                                {/* Popup Modal */}
                                                                {isPopupOpen && (
                                                                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">

                                                                        <div className="bg-white border-[25px] mx-4 border-white rounded-lg shadow-lg max-w-md w-full max-h-[320px] relative" style={{ overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                                                            <style>{` .hide-scrollbar {-ms-overflow-style: none; /* Internet Explorer 10+ */
                                                                          scrollbar-width: none; /* Firefox */
                                                                             }
                                                                          .hide-scrollbar::-webkit-scrollbar {
                                                                           display: none; /* Safari and Chrome */
                                                                           }
                                                                         `}
                                                                            </style>
                                                                            <div className="sticky top-0 bg-white pb-4">
                                                                                {/* Close Button */}
                                                                                <button
                                                                                    onClick={togglePopup}
                                                                                    className="absolute top-0 right-0 text-gray-400 hover:text-gray-600"
                                                                                >
                                                                                    <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                                                    </svg>
                                                                                </button>
                                                                                <h2 className="text-lg font-semibold">Payment Details</h2>
                                                                                <hr />
                                                                            </div>
                                                                            <p className="text-sm text-[#3D3D3D] overflow-y-auto " dangerouslySetInnerHTML={{ __html: rfqData?.paymentTerms }}>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="lg:hidden text-sm mx-4 justify-between items-center py-4">
                                                            <p >
                                                                Do you agree ?
                                                            </p>
                                                            <div className="flex space-x-12 mt-2">
                                                                <label className="inline-flex items-center">
                                                                    <input type="radio" name="paymentTerms" className="form-radio" />
                                                                    <span className="ml-2 ">Yes</span>
                                                                </label>
                                                                <label className="inline-flex items-center">
                                                                    <input type="radio" name="paymentTerms" className="form-radio" />
                                                                    <span className="ml-2">Counter Offer</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className="font-semibold text-sm text-[#4F4F42]">Counter Payment Terms</p>
                                                            <Field
                                                                as="textarea"
                                                                name="counterPaymentTerms"
                                                                className="lg:w-[390px] w-full text-sm h-[144px] my-2 border border-gray-300 placeholder:italic"
                                                                placeholder="Please write your counter payment terms Rich Text "
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className=" mt-4">
                                                        <p className="text-[#434343] mb-2 lg:mb-0">Notes</p>
                                                        <Field
                                                            as="textarea"
                                                            name="notes"
                                                            placeholder="Add Notes (Optional) "
                                                            className="border-[#CDCDB8] w-full placeholder:text-sm lg:ml-5 lg:w-full h-[125px] placeholder:italic placeholder-[#8C8C8C]"
                                                        />
                                                    </div>
                                                </div>

                                                {/* Upload Supporting Documents */}
                                                <div className="pb-12 ">
                                                    <h3 className="whitespace-nowrap text-[#434343] text-sm font-bold pb-4">Upload Supporting Documents</h3>

                                                    {attachmentsSkeleton.length > 0 && (
                                                        <p className="text-xs pl-3">
                                                            Your file is being scanned for upload, be patient and continue to fill
                                                            the rest of the form.
                                                        </p>
                                                    )}

                                                    <div className="">
                                                        {/* Display the selected file names */}
                                                        <ul style={{ display: "flex", flexWrap: "wrap" }}>
                                                            {selectedFilesBase.map((file, index) => (
                                                                <a
                                                                    href={file.Content}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="border border-gray-300 px-3 py-1 rounded-full flex mb-4 ml-10"
                                                                    key={file.Name}
                                                                >
                                                                    <span className="text-sm overflow-hidden whitespace-nowrap w-36 truncate">
                                                                        {file.Name}
                                                                    </span>
                                                                    <span
                                                                        className="ml-1 cursor-pointer"
                                                                        onClick={(event) => handleDeleteSelectedFile(file.Name, event)}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="18"
                                                                            height="18"
                                                                            viewBox="0 0 48 48"
                                                                        >
                                                                            <circle cx="24" cy="24" r="22" fill="gray" />
                                                                            <line
                                                                                x1="15"
                                                                                y1="15"
                                                                                x2="33"
                                                                                y2="33"
                                                                                stroke="white"
                                                                                strokeWidth="2"
                                                                            />
                                                                            <line
                                                                                x1="33"
                                                                                y1="15"
                                                                                x2="15"
                                                                                y2="33"
                                                                                stroke="white"
                                                                                strokeWidth="2"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                </a>
                                                            ))}

                                                            {/* Skeleton loaders for files being processed */}
                                                            {attachmentsSkeleton.map((_, index) => (
                                                                <Skeleton
                                                                    key={index}
                                                                    variant="rounded"
                                                                    sx={{ borderRadius: "20px", margin: "4px" }}
                                                                    width={150}
                                                                    height={32}
                                                                />
                                                            ))}
                                                        </ul>
                                                    </div>

                                                    <div>
                                                        {attachmentsUploadsErrors && (
                                                            <div className="mt-3">
                                                                <p className="text-red-500 font-semibold text-sm mt-14">
                                                                    {attachmentsUploadsErrors}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>

                                                    {/* Error handling for file size */}
                                                    <div
                                                        className={`fixed bottom-0 left-2  p-4 ${openFilesSizeError ? "block" : "hidden"
                                                            }`}
                                                    >
                                                        <div className="bg-orange-100 w-[440px] flex items-center justify-between  px-4 py-1 text-yellow-900 rounded-lg shadow-md p-4">
                                                            <p>Total size of attachments is not more than 10 MB</p>
                                                            <button
                                                                onClick={handleCloseFileSizeError}
                                                                className="px-4 py-2 focus:outline-none"
                                                            >
                                                                <svg
                                                                    className="h-6 w-6"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="2"
                                                                        d="M6 18L18 6M6 6l12 12"
                                                                    ></path>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>


                                                    {/* drag and drop file */}
                                                    <div className="mb-10 flex items-center justify-center h-[175px] bg-[#F1F7FF] border-2 border-dashed border-[#004491] relative">
                                                        <div className="lg:hidden flex flex-col items-center justify-center">
                                                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/create_rfq_upload.svg" alt="" className="mx-auto" />
                                                            <p className="text-center text-sm mt-4">Upload your files here</p>
                                                            <label htmlFor="file" className="text-[#1D28FA] text-sm underline text-center cursor-pointer">
                                                                Browse
                                                            </label>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.xls,.csv,.dwg" // Specify allowed file types
                                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                                            onClick={handleFileUploadClick}
                                                            onChange={handleFileUpload}
                                                            multiple // Allow multiple files to be selected
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* desktop */}
                        <div className="hidden lg:block bg-[#F6F6F4] pb-8">
                            {/* Header Section */}
                            <div className="z-20 lg:grid lg:grid-cols-3 flex items-center flex-col-reverse lg:px-8   lg:py-2 shadow-md sticky top-0 bg-white ">
                                <div >
                                    <div className="lg:flex items-center hidden ">
                                        {/* <h1 className="text-[28px] text-[#434343] font-semibold ">Add Proposal: Rs. {overallTotal.toFixed(2)} </h1>
                                        <span className="text-sm bg-[#E9E9E9] ml-4 px-4 py-2 text-[#434343]">Draft</span> */}
                                        <button type="button" onClick={(() => isEdit ? navigate(-1) : (supplierId ? navigate("/leads") : navigate(-1)))} className="text-sm bg-[#E9E9E9] cursor:pointer px-4 py-1 my-1 text-[#434343]">Back</button>
                                    </div>
                                </div>
                                {/* <div className="flex justify-center items-center lg:items-start lg:justify-start my-4">

                                    <div className="lg:w-9 lg:h-9 w-6 h-6 mx-3 flex justify-center items-center rounded-full bg-[#DFF1C8] opacity-95">
                                        <div className="lg:w-[18px] lg:h-[18px] w-3 h-3 rounded-full bg-lime-500"></div>
                                    </div>

                                    <h3 className="lg:text-2xl lg:mt-1 text-center text-sm text-[#737373]">Live Rank: <span className="font-bold text-black">L2</span></h3>
                                </div> */}
                                    <div className="flex items-center justify-center space-x-2">
                                        <span class="relative flex items-center justify-center h-4 w-4 ">
                                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-lime-500 opacity-40"></span>
                                            <span class="relative inline-flex rounded-full h-2 w-2 bg-lime-500"></span>
                                        </span>
                                        <h3 className="text-sm lg:mt-1 text-center text-[#737373]">
                                            Quotation Rank:{" "}
                                            <span className="font-bold text-black">
                                                {currentRank === "L1"
                                                    ? "L2"
                                                    : currentRank === undefined || currentRank === null
                                                        ? "Quotation not submitted"
                                                        : currentRank}
                                            </span>
                                        </h3>
                                        {/* <p className="text-[#9F9F9F] lg:mt-1 text-xs italic hidden lg:block">Your rank will update as soon as the buyer receives atleast 3 ratings</p> */}
                                    </div>
                            </div>

                            {/* rfq information */}
                            <div className="px-4 lg:py-[24px] lg:px-8 ">
                                <div className="shadow-md bg-white border px-8 lg:py-[20px]">
                                    <h3 className="text-sm font-semibold hidden lg:block pb-2">RFQ Information</h3>
                                    <div className="text-xs flex flex-col-reverse lg:grid lg:grid-cols-3 lg:gap-x-8 lg:gap-y-4 bg-white">
                                        <div className="lg:space-y-5 space-y-4">
                                            <div>
                                                <p className="text-[#6B7280] font-normal hidden lg:block">RFQ Name</p>
                                                <p className="text-[#4F4F42] font-bold hidden lg:block">{rfqData?.name}</p>
                                            </div>
                                            <div>
                                                <p className="text-[#6B7280] font-normal hidden lg:block">Created Date</p>
                                                <p className="text-[#4F4F42] font-bold hidden lg:block">{new Date(rfqData?.createdAt).toLocaleDateString('en-GB')}</p>
                                            </div>
                                            <div>
                                                <p className="text-[#6B7280] font-normal  ">Client Name</p>
                                                <p className="text-[#4F4F42] font-bold ">{rfqData?.userBusinessName}</p>
                                            </div>

                                            {/* <div>
                                                <p className="text-[#6B7280] font-normal">Payment Terms</p>
                                                <p
                                                    className="text-[#4F4F42] font-bold bg-[#ECECEC] lg:bg-white mt-1 p-4 lg:m-0 lg:p-0"
                                                    dangerouslySetInnerHTML={{ __html: rfqData?.paymentTerms }}
                                                ></p>
                                            </div> */}
                                        </div>
                                        <div className="lg:space-y-5 space-y-4">
                                            <div>
                                                <p className="text-[#6B7280] font-normal  hidden lg:block">Requirement Type</p>
                                                <p className="text-[#4F4F42] font-bold  hidden lg:block">{capitalizeFirstLetter(rfqData?.rfqType)}</p>
                                            </div>
                                            <div>
                                                <p className="text-[#6B7280] font-normal">Project Name</p>
                                                <p className="text-[#4F4F42] font-bold">{rfqData?.projectName}</p>
                                            </div>
                                            <div>
                                                <p className="text-[#6B7280] font-normal  hidden lg:block">Expected Delivery Date</p>
                                                <p className="text-[#4F4F42] font-bold hidden lg:block">{rfqData?.providingDate ? new Date(rfqData?.providingDate).toLocaleDateString('en-GB') : "-"}</p>
                                            </div>

                                        </div>
                                        <div className="lg:space-y-5 space-y-4">
                                            <div>
                                                <p className="text-[#6B7280] font-normal">Status</p>
                                                <div className="flex items-center space-x-2">
                                                    <div className="w-[15px] h-[15px] rounded-full bg-[#BFFFBA]"></div>
                                                    <p className="text-[#4F4F42] font-bold">{rfqData?.status}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="text-[#6B7280] font-normal  ">Contact</p>
                                                <p className="text-[#4F4F42] font-bold ">{rfqData?.requesterName} ({rfqData?.requesterMobile})</p>
                                            </div>
                                            <div>
                                                <p className="text-[#6B7280] font-normal">Delivery Location</p>
                                                <div className="flex items-center gap-2">
                                                    <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/map_pin_vendor_quotation.svg" alt="" className="mb-1" />
                                                    <p className="text-[#4F4F42] font-bold">{rfqData?.location}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:px-8 space-y-6 px-4">
                                {/* line items */}
                                <div >

                                    {/* Providing & Fixing Section */}
                                    <div className="flex items-center">
                                        <h3 className="whitespace-nowrap text-[#434343] hidden lg:block uppercase text-sm font-semibold">Providing & Fixing</h3>
                                        <hr className="flex-grow border-t hidden lg:block border-gray-300 ml-4" />
                                    </div>
                                    <p className="text-xs mb-[10px] mt-2 font-medium text-[#434343] hidden lg:block ">Your bid value is the sum of these line items</p>

                                    {/* <p className="text-xl font-bold text-[#434343] hidden lg:block">Line Items</p> */}
                                    <div className="mb-5 mt-2 hidden lg:grid lg:grid-cols-10 lg:gap-4 text-[#777777] text-xs font-normal uppercase border-y py-2">
                                        <label className="block font-medium col-span-1 ">Sr.No.</label>
                                        <label className="block font-medium col-span-2 ">Description</label>
                                        <label className="block font-medium col-span-3 ">COMMENTS / METHODS</label>
                                        <label className="block font-medium col-span-1 text-center ">BASE PRICE</label>
                                        <label className="block font-medium text-right col-span-1 ">Gst (%)</label>
                                        {/* <label className="block font-medium text-right col-span-1 uppercase">Delivery Date</label> */}
                                        <label className="block font-medium text-center col-span-1 uppercase">Work Start Date</label>
                                        <label className="block font-medium text-center col-span-1">Total Amount</label>
                                    </div>


                                    {/* providing and fixing */}
                                    <div >
                                        <div className="lg:grid lg:grid-cols-10 text-xs space-y-8 lg:space-y-0 lg:gap-4 text-[#373737]">
                                        <p className="pl-2 py-2">1</p>
                                            <div className="bg-[#F6F6F4] text-xs col-span-2 py-2 px-4 w-full " >
                                                <p
                                                    className="w-full border-none col-span-3 p-0 bg-[#F6F6F4] max-h-[182px] overflow-hidden"
                                                    dangerouslySetInnerHTML={{
                                                        __html: rfqData?.providingFixingDetails
                                                            ? rfqData.providingFixingDetails.slice(0, 230) + (rfqData.providingFixingDetails.length > 230 ? "..." : "")
                                                            : ""
                                                    }}
                                                ></p>

                                                {rfqData?.providingFixingDetails.length > 180 && <button
                                                    onClick={toggleDescPopup}
                                                    type="button"
                                                    className="text-blue-600 underline pt-2"
                                                >
                                                    Read More
                                                </button>}

                                                {/* Popup Modal */}
                                                {isDescPopupOpen && (
                                                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">

                                                        <div className="bg-white border-[25px] mx-4 border-white rounded-lg shadow-lg max-w-md w-full max-h-[320px] relative" style={{ overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                                            <style>{` .hide-scrollbar {-ms-overflow-style: none; /* Internet Explorer 10+ */
                                                                         scrollbar-width: none; /* Firefox */
                                                                          }
                                                                       .hide-scrollbar::-webkit-scrollbar {
                                                                       display: none; /* Safari and Chrome */
                                                                          }
                                                                       `}
                                                            </style>
                                                            <div className="sticky top-0 bg-white pb-4">
                                                                {/* Close Button */}
                                                                <button
                                                                    onClick={toggleDescPopup}
                                                                    className="absolute top-0 right-0 text-gray-400 hover:text-gray-600"
                                                                >
                                                                    <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                                    </svg>
                                                                </button>
                                                                <h2 className="text-sm font-semibold mb-4">Work Description</h2>
                                                                <hr />
                                                            </div>
                                                            <p className=" text-[#3D3D3D] overflow-y-auto " dangerouslySetInnerHTML={{ __html: rfqData?.providingFixingDetails }}>
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-span-3 px-4 lg:px-0">
                                                <Field
                                                    as="textarea"
                                                    name="comments"
                                                    className={`h-full w-[262px] text-xs p-2 resize-none border whitespace-pre-line placeholder:text-xs border-gray-300 lg:w-full placeholder-[#373737] ${isReadOnly && "bg-gray-100"}`}
                                                    placeholder=""
                                                    disabled = {isReadOnly}
                                                // rows="4"
                                                />
                                                <ErrorMessage name="comments" component="div" className="text-red-500 text-xs" />
                                            </div>

                                            <div className="col-span-1 self-end px-4 lg:px-0">
                                                <Field
                                                    type="text"
                                                    name="basePrice"
                                                    className={`p-2 lg:w-full w-[262px] text-right h-[40px] border text-xs border-gray-300 placeholder-[#373737] ${isReadOnly && "bg-gray-100"}`}
                                                    value={values.basePrice} // Bind to Formik's values
                                                    onChange={(e) => handleBasePriceChange(e, setFieldValue, values, setTotalAmount)} // Update and calculate
                                                    disabled = {isReadOnly}
                                                />
                                                <div className="w-fit">
                                                    <ErrorMessage name="basePrice" component="div" className="text-red-500 text-xs absolute mt-1" />
                                                </div>
                                            </div>

                                            {/* gst */}
                                            <div className="col-span-1 lg:ml-auto self-end h-fit lg:w-[64px] w-[262px] px-4 lg:px-0">
                                                <CustomGstDropdown
                                                    options={[0, 5, 12, 18, 28]} // GST options
                                                    value={values.gst} // Bind GST dropdown to Formik's values
                                                    onChange={(value) => handleGstChange({ target: { value } }, setFieldValue, values, setTotalAmount)} // Update and calculate
                                                    className="lg:w-full w-[262px] h-[40px]"
                                                    disabled = {isReadOnly}
                                                />
                                                {/* <ErrorMessage name={`lineItems[${index}].gst`} component="div" className="text-red-500 text-sm h-fit" /> */}
                                            </div>

                                            {/* <div className="self-end px-4 lg:px-0 relative">
                                                <Field
                                                    type="date"
                                                    name="deliveryDate"
                                                    className="p-2 lg:w-full w-[262px] h-[40px] border text-xs border-gray-300 placeholder-[#373737]"
                                                />
                                                <div className="absolute mt-1 text-red-500 text-xs">
                                                    <ErrorMessage name="deliveryDate" component="div" className="text-xs" />
                                                </div>
                                            </div> */}


                                            <div className="self-end px-4 lg:px-0 relative">
                                                <Field
                                                    type="date"
                                                    name="workStartDate"
                                                    className={`p-2 lg:w-full w-[262px] h-[40px] text-xs border border-gray-300 placeholder-[#373737] ${isReadOnly && "bg-gray-100"}`}
                                                    disabled = {isReadOnly}
                                                />
                                                <div className="absolute mt-1 text-red-500">
                                                    <ErrorMessage name="workStartDate" component="div" className="text-red-500 text-xs mt-1" />
                                                </div>
                                            </div>

                                            <div className="col-span-1 mt-2 py-4 px-4 lg:py-0 lg:px-0 flex justify-between lg:justify-end items-center w-full self-end border border-t-[#004491] lg:border-none">
                                                <div className="p-2  h-[40px] font-bold lg:font-normal text-xs text-right lg:text-black text-[#004491]">
                                                    Rs.{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(totalAmount.toFixed(2))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* other charges */}
                                <div className="hidden lg:block bg-white p-8 shadow-md">
                                    {hasVisibleMiscellaneousItems && (
                                        <div className="pb-6">
                                            <h3 className="text-sm font-semibold text-[#434343] hidden lg:block mb-3">Other Charges</h3>

                                            <div className="text-xs bg-white border py-4 shadow-md lg:border-none lg:mx-0 lg:shadow-none lg:py-0">
                                                <div className="mb-3 hidden lg:grid lg:grid-cols-6 items-center gap-2 text-[#777777] font-normal uppercase border-y py-2">
                                                    <label className="block font-medium col-span-3">Charges</label>
                                                    <label className="font-medium text-start col-span-1 flex justify-center items-center relative">
                                                        MRP
                                                        <span className="bg-[#cac9c9] text-white text-xs h-[15px] w-[15px] flex justify-center items-center mb-1 ml-1 pt-1 rounded-full lowercase relative group hover:cursor-pointer">
                                                            i
                                                            <span className="absolute bottom-[130%] text-xs left-1/2 w-[200px] transform -translate-x-1/2 px-2 py-1 text-[#434343] text-left bg-white border border-gray-300 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                                                                Enter the cost per unit for the selected construction material. Ensure accuracy as this value impacts overall project budgeting and pricing.
                                                            </span>
                                                        </span>
                                                    </label>
                                                    <label className="block font-medium text-center col-span-1">GST (%)</label>
                                                    <label className="block font-medium text-right col-span-1">Total Cost</label>
                                                </div>

                                                {/* Render visible miscellaneous items */}
                                                {visibleMiscellaneousItems.map((item) => (
                                                    <div key={item.name} className="lg:grid lg:grid-cols-6 mb-8 lg:mb-0 space-y-3 lg:space-y-2 gap-4 items-center">
                                                        <label className="block lg:mb-2 col-span-3 font-semibold lg:font-medium ">
                                                            {item.label}
                                                        </label>

                                                        {/* MRP Input Field */}
                                                        <div className="col-span-1 w-full">
                                                            <Field
                                                                name={`miscellaneousCosts.${item.name}.mrp`}
                                                                type="text"
                                                                className={`w-fit hidden text-xs text-right lg:block lg:w-full h-[30px] placeholder-[#373737] p-2 border border-gray-300 ${isReadOnly && "bg-gray-100"}`}
                                                                placeholder="Rs."
                                                                onChange={(e) => handleMiscellaneousChange(item.name, e.target.value, setFieldValue, values, 'mrp')}
                                                                disabled = {isReadOnly}
                                                            />
                                                            <ErrorMessage
                                                                name={`miscellaneousCosts.${item.name}.mrp`}
                                                                component="div"
                                                                className="text-red-500 text-xs mt-1"
                                                            />
                                                        </div>

                                                        {/* GST Dropdown */}
                                                        <div className="col-span-1 lg:flex hidden justify-center h-fit lg:w-full w-[262px]">
                                                            <CustomGstDropdown
                                                                options={[0, 5, 12, 18, 28]}
                                                                value={values.miscellaneousCosts[item.name]?.gst}
                                                                onChange={(value) => handleMiscGstChange(item.name, value, setFieldValue, values)}
                                                                className="lg:w-[64px] w-[262px] justify-between h-[30px]"
                                                                disabled = {isReadOnly}
                                                            />
                                                            <ErrorMessage
                                                                name={`miscellaneousCosts.${item.name}.gst`}
                                                                component="div"
                                                                className="text-red-500 text-xs mt-1"
                                                            />
                                                        </div>

                                                        {/* Total Cost */}
                                                        <div className="flex justify-between lg:justify-end items-center border border-t-[#004491] py-3 bg-[#E7EAEF] lg:border-none lg:bg-white">
                                                            <p className="text-[#004491] font-bold lg:font-normal col-span-1 lg:text-black lg:text-right">
                                                                Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(values.miscellaneousCosts[item.name]?.totalCost?.toFixed(2)) || '0.00'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}


                                    <div className="">
                                        <hr className="hidden lg:block" />
                                        <div className="flex py-5 lg:p-0 justify-between items-center border lg:border-none border-t-[#007366] bg-[#E0F0EE] lg:bg-white">
                                            <p className="text-[#007366] text-sm lg:text-black ">Total Amount</p>
                                            <p className="bg-[#E0F0EE] text-[#007366] text-xs font-semibold text-end lg:font-normal lg:text-black w-fit min-w-[164px] lg:px-4 py-2">Rs. {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(overallTotal.toFixed(2))}</p>
                                        </div>
                                    </div>
                                </div>


                                {/* Supporting Documents Section */}
                                {attachmentsData.length > 0 && (
                                    <div className="mb-4 hidden lg:block bg-white p-8 shadow-md">
                                        <>
                                            <h3 className="text-sm font-semibold text-[#434343] hidden lg:block">Supporting Documents</h3>
                                            <p className="text-xs font-normal text-[#9D9D9D] "> <span className="font-bold text-gray-500">Note: </span>  Below are the supporting documents uploaded by the buyer. Please review them.</p>
                                            <div className="lg:grid lg:grid-cols-4 lg:gap-4 space-y-4 lg:space-y-0 lg:mt-4 mx-auto">
                                                {attachmentsData.map((doc, index) => (
                                                    <div key={index} className="flex justify-between items-center mx-2">
                                                        <div className="px-2 flex w-fit items-center justify-center border border-[#8E8E85] rounded-full py-1 space-x-2 text-[#434343]">
                                                            {/* Dynamic icon based on file type */}
                                                            <img src={`/assets/icons/${doc.Path}.png`} alt={doc.Path} className="h-5 w-5" />
                                                            {/* Document name now clickable */}
                                                            <a
                                                                href={doc.Content}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                download={doc.Name}
                                                                className="text-xs text-[#434343] hover:underline"
                                                            >
                                                                {doc.Name}
                                                            </a>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    </div>
                                )}

                                {/* Additional Information Section */}
                                <div className="mb-4 bg-white p-8 shadow-md">
                                    {/* <div className="flex items-center mt-[60px] mb-[30px]">
                                    <h3 className="whitespace-nowrap text-[#434343] uppercase text-sm font-medium">ADDITIONAL INFORMATION</h3>
                                    <hr className="flex-grow border-t border-gray-300 ml-4" />
                                     </div> */}
                                    <h3 className="text-sm font-semibold text-[#434343] hidden lg:block ">Additional Information</h3>

                                    {rfqData?.acceptCounterTerms && <div className="hidden lg:flex justify-between items-center  mb-4">
                                        <p className="mb-2 text-sm ">
                                            Below are our payment terms. Do you agree?
                                        </p>
                                        <div className="flex space-x-8 mb-4 text-sm">
                                            <label className="inline-flex items-center">
                                                <Field
                                                    type="radio"
                                                    name="agreedToPaymentTerms"
                                                    // value="true"
                                                    checked={values.agreedToPaymentTerms} // Controlled check status
                                                    onChange={() => {
                                                        setFieldValue('agreedToPaymentTerms', true);
                                                        setIsCounterTerm(false); // Update counter term
                                                    }}
                                                    className="form-radio"
                                                />
                                                <span className="ml-2">Yes</span>
                                            </label>

                                            <label className="inline-flex items-center">
                                                <Field
                                                    type="radio"
                                                    name="agreedToPaymentTerms"
                                                    // value="false"
                                                    checked={values.agreedToPaymentTerms} // Controlled check status
                                                    onChange={() => {
                                                        setFieldValue('agreedToPaymentTerms', false);
                                                        setIsCounterTerm(true); // Update counter term
                                                    }}
                                                    disabled = {isReadOnly}
                                                    className="form-radio"
                                                />
                                                <span className="ml-2">Counter Offer</span>
                                            </label>
                                        </div>
                                        <ErrorMessage name="agreedToPaymentTerms" component="div" className="text-red-500 text-sm" />

                                    </div>}

                                    {rfqData?.acceptCounterTerms && <div className="lg:flex justify-between items-center">
                                        <div>
                                            {/* <p className="text-sm text-[#4F4F42] pb-2 hidden lg:block">Payment Terms</p> */}

                                            <div className="bg-[#F6F6F4] p-4 lg:w-[390px] lg:h-[120px] lg:mx-0 mx-auto" >
                                                <p className="w-full border-none text-xs p-0 bg-[#F6F6F4] text-black" dangerouslySetInnerHTML={{
                                                    __html: rfqData?.paymentTerms?.length > 180
                                                        ? `${rfqData.paymentTerms.substring(0, 180)}...`
                                                        : rfqData?.paymentTerms
                                                }}>
                                                </p>
                                                {rfqData?.paymentTerms.length > 180 && <button
                                                    onClick={togglePopup}
                                                    type="button"
                                                    className="text-blue-600 text-xs underline pt-2"
                                                >
                                                    Read More
                                                </button>}

                                                {/* Popup Modal */}
                                                {isPopupOpen && (
                                                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">

                                                        <div className="bg-white border-[25px] mx-4 border-white rounded-lg shadow-lg max-w-md w-full h-[320px] relative" style={{ overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                                                            <style>{` .hide-scrollbar {-ms-overflow-style: none; /* Internet Explorer 10+ */
                                                                        scrollbar-width: none; /* Firefox */
                                                                        }
                                                                        .hide-scrollbar::-webkit-scrollbar {
                                                                        display: none; /* Safari and Chrome */`}
                                                            </style>
                                                            <div className="sticky top-0 bg-white pb-4">
                                                                {/* Close Button */}
                                                                <button
                                                                    onClick={togglePopup}
                                                                    className="absolute top-0 right-0 text-gray-400 hover:text-gray-600"
                                                                >
                                                                    <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                                    </svg>
                                                                </button>
                                                                <h2 className="text-lg font-semibold mb-4">Payment Details</h2>
                                                                <hr />
                                                            </div>
                                                            <p className="text-sm text-[#3D3D3D] overflow-y-auto " dangerouslySetInnerHTML={{ __html: rfqData?.paymentTerms }}>
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>


                                        {isCounterTerm && <div className=" mx-4 lg:mx-0">
                                            <p className="text-sm text-[#4F4F42]">Counter Payment Terms</p>
                                            <Field
                                                as="textarea"
                                                name="counterPaymentTerms"
                                                className={`lg:w-[390px] w-full h-[120px]  text-xs placeholder:text-xs border border-gray-300 placeholder:italic 
                                                    ${values.agreedToPaymentTerms === true ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : 'bg-white'}`}
                                                placeholder="Please write your counter payment terms Rich Text"
                                                disabled={values.agreedToPaymentTerms === true || isReadOnly}
                                            />
                                        </div>}
                                    </div>}
                                    <div className=" lg:flex mt-4 mx-4 lg:mx-0">
                                        <p className="text-[#434343] text-sm mb-2 lg:mb-0">Notes</p>
                                        <Field
                                            as="textarea"
                                            name="notes"
                                            placeholder="Add Notes (Optional) "
                                            className={`border-[#CDCDB8] w-full lg:ml-5 placeholder:text-xs lg:w-full text-xs h-[120px] placeholder:italic placeholder-[#8C8C8C] ${isReadOnly && "bg-gray-100"}`}
                                            disabled = {isReadOnly}
                                        />
                                    </div>
                                </div>

                                {/* Upload Supporting Documents */}
                                <div className=" mx-4 lg:mx-0 bg-white p-8 shadow-md">
                                    <h3 className="whitespace-nowrap text-[#434343] text-sm font-bold mb-4 lg:mb-[15px]">Upload Supporting Documents</h3>

                                    {attachmentsSkeleton.length > 0 && (
                                        <p className="text-xs pl-3">
                                            Your file is being scanned for upload, be patient and continue to fill
                                            the rest of the form.
                                        </p>
                                    )}

                                    <div className="px-3">
                                        {/* Display the selected file names */}
                                        <ul style={{ display: "flex", flexWrap: "wrap" }}>
                                            {selectedFilesBase.map((file, index) => (
                                                <a
                                                    href={file.Content}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="border border-gray-300 px-3 py-1 rounded-full flex mb-4 ml-10"
                                                    key={file.Name}
                                                >
                                                    <span className="text-sm overflow-hidden whitespace-nowrap w-36 truncate">
                                                        {file.Name}
                                                    </span>
                                                    <span
                                                        className="ml-1 cursor-pointer"
                                                        onClick={(event) => handleDeleteSelectedFile(file.Name, event)}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="18"
                                                            height="18"
                                                            viewBox="0 0 48 48"
                                                        >
                                                            <circle cx="24" cy="24" r="22" fill="gray" />
                                                            <line
                                                                x1="15"
                                                                y1="15"
                                                                x2="33"
                                                                y2="33"
                                                                stroke="white"
                                                                strokeWidth="2"
                                                            />
                                                            <line
                                                                x1="33"
                                                                y1="15"
                                                                x2="15"
                                                                y2="33"
                                                                stroke="white"
                                                                strokeWidth="2"
                                                            />
                                                        </svg>
                                                    </span>
                                                </a>
                                            ))}

                                            {/* Skeleton loaders for files being processed */}
                                            {attachmentsSkeleton.map((_, index) => (
                                                <Skeleton
                                                    key={index}
                                                    variant="rounded"
                                                    sx={{ borderRadius: "20px", margin: "4px" }}
                                                    width={150}
                                                    height={32}
                                                />
                                            ))}
                                        </ul>
                                    </div>

                                    <div>
                                        {attachmentsUploadsErrors && (
                                            <div className="mt-3">
                                                <p className="text-red-500 font-semibold text-sm mt-14">
                                                    {attachmentsUploadsErrors}
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    {/* Error handling for file size */}
                                    <div
                                        className={`fixed bottom-0 left-2  p-4 ${openFilesSizeError ? "block" : "hidden"
                                            }`}
                                    >
                                        <div className="bg-orange-100 w-[440px] flex items-center justify-between  px-4 py-1 text-yellow-900 rounded-lg shadow-md p-4">
                                            <p>Total size of attachments is not more than 10 MB</p>
                                            <button
                                                onClick={handleCloseFileSizeError}
                                                className="px-4 py-2 focus:outline-none"
                                            >
                                                <svg
                                                    className="h-6 w-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M6 18L18 6M6 6l12 12"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>


                                    {/* drag and drop file */}
                                    {!isReadOnly && <div className="lg:ml-14 lg:mr-0 flex items-center justify-center h-[175px] lg:h-[180px] bg-[#F1F7FF] lg:bg-white border-2 border-dashed border-[#004491] lg:border-[#7D7D7D] relative">
                                        <input
                                            type="file"
                                            accept=".pdf,.jpg,.jpeg,.png,.gif,.xlsx,.xls,.csv,.dwg" // Specify allowed file types
                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                            onClick={handleFileUploadClick}
                                            onChange={handleFileUpload}
                                            multiple // Allow multiple files to be selected
                                        />
                                        <div className="absolute inset-0 flex flex-col items-center justify-center text-gray-500 pointer-events-none">
                                            <div className="relative mx-auto hidden lg:block">
                                                {/* Overlapping rectangles */}
                                                <div className="absolute top-10 -left-8 h-[80px] w-[65px] bg-white border border-[#878787]"></div>
                                                <div className="absolute top-2 left-0 h-[80px] w-[65px] bg-white border border-[#878787]"></div>
                                            </div>
                                            {/* Centered text */}
                                            <p className="mt-32 text-center text-sm hidden lg:block">Drag & Drop or Click to upload your proposal instead</p>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>

                        {/* Action Buttons */}
                        <div className="sticky bottom-0 z-20">
                            {/* action buttons for mobile */}
                            <div
                                style={{ boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1)' }}
                                className="lg:hidden flex justify-end p-4 space-x-8 bg-white "
                            >
                                {currentStep === 0 ? (
                                    <div className="space-x-4">
                                        <button type="button" onClick={(() => isEdit ? navigate(-1) : (supplierId ? navigate("/leads") : navigate(-1)))} className="text-xs bg-[#E9E9E9] cursor:pointer px-4 py-2 text-[#434343]">Back</button>
                                        <button
                                            type="button"
                                            className="bg-[#1E1E08] px-4 py-2 text-xs text-white "
                                            onClick={() => setCurrentStep(currentStep + 1)}
                                        >
                                            Start Quotation
                                        </button>
                                    </div>
                                ) : (
                                    currentStep > 0 && (
                                        <button
                                            type="button"
                                            className="underline text-xs"
                                            onClick={handlePreviousStep}
                                        >
                                            Back
                                        </button>
                                    )
                                )}

                                {/* Show "Next" button for all steps except "Additional Information & Upload" and "Completion" */}
                                {currentStep > 0 && currentStep < steps.length - 2 && (
                                    <button
                                        type="button"
                                        className="bg-[#1E1E08] px-4 py-2 text-xs text-white "
                                        onClick={() => handleNextStep(validateForm, setTouched, values)}
                                    >
                                        Next
                                    </button>
                                )}

                                {/* Show "Submit" button on "Additional Information & Upload" step */}
                                {currentStep === steps.length - 2 && (
                                    <button
                                        type="submit"
                                        className="bg-[#1E1E08] px-4 py-2 text-xs text-white "
                                        disabled={loading}
                                    >
                                        {loading ? "Submitting..." : "Submit"}
                                    </button>
                                )}

                                {/* No buttons displayed on "Completion" step */}
                            </div>
                            {/* action buttons for desktop */}
                            {!isReadOnly && <div style={{ boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1)' }} className="sticky bottom-0 space-x-4 bg-white hidden lg:flex justify-end items-center py-3 lg:px-8 px-4">
                                <button type="button" onClick={handleCloseForm} className=" w-[120px] border border-[#1E1E08] px-3 py-2 text-sm text-[#434343] font-bold">
                                    Cancel
                                </button>
                                <button type="submit" disabled={loading} className="bg-black border border-[#1E1E08] text-white px-3 py-2 text-sm flex justify-center items-center">
                                    {loading ? "Submitting..." : isEdit ? "Update Quotation" : "Submit Quotation"}
                                </button>
                            </div>}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ProvidingQuotationForm;