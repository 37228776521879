import React, { useEffect, useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import EmailTextField from "./EmailTextfield";
import CustomInput from "./CustomInput";
import CustomSelect from "./CustomSelect";
import { Button } from "flowbite-react";
import StaticPopup from "components/StaticPopup";
import RfqTypeMaterial from "./RfqTypeMaterial";
import RfqTypeLabour from "./RfqTypeLabour";
import RfqTypeProviding from "./RfqTypeProviding";
import RfqDetailsSection from "./RfqDetailsSection";
import CreateNewProjectForm from "views/Project/components/CreateNewProjectForm";
import { CcMemberList } from "actions/rfqActions";
import { useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";

const GeneralInformationNew = ({ projects, updateRfqFormValues, entityId }) => {
  const { values, errors, setFieldValue } = useFormikContext();

  const [showCreateProjectForm, setShowCreateProjectForm] = useState(false);
  // const [currentValues, setCurrentValues] = useState({});
  const dispatch = useDispatch();

  const [isFocusedCC, setIsFocusedCC] = useState(false);

  const handleOpenCreateProjectForm = () => {
    // setCurrentValues(values);
    setShowCreateProjectForm(true);
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  // Function to handle project creation and close the form
  const handleProjectCreation = () => {
    setShowCreateProjectForm(false);

    // Restore the form values after the project is created
    // Object.keys(currentValues).forEach((key) => {
    //   setFieldValue(key, currentValues[key]);
    // });
  };

  useEffect(() => {
    dispatch(CcMemberList(entityId));
  }, [dispatch, entityId]);

  useEffect(() => {
    // Avoid resetting 'otherCharges' when it's already set
    if (!values?.otherCharges) {
      setFieldValue('otherCharges', {}); // Only reset if 'otherCharges' is undefined
    }
  
    // Reset values based on requirementType
    if (values.requirementType === 'labour') {
      setFieldValue('providingFixingDetails', ''); // Reset providing & fixing details
      setFieldValue('materials', []); // Reset material details
    } else if (values.requirementType === 'contractor') {
      setFieldValue('labourDetails', []); // Reset labour details
      setFieldValue('materials', []); // Reset materials
    } else if (values.requirementType === 'material') {
      setFieldValue('labourDetails', []); // Reset labour details
      setFieldValue('providingFixingDetails', ''); // Reset providing & fixing details
    }
  }, [values.requirementType, setFieldValue]); // Don't include 'values.otherCharges' here



  return (
    <div className="w-full px-10 space-y-4 h-full  pt-2 overflow-y-auto">

      {/* project name */}
      <div>
        <Field
          component={CustomSelect}
          id="projectName"
          name="projectName"
          options={projects?.map((project) => ({
            value: project._id,
            label: project.name,
            description: `${project.location.city}, ${project.location.state}`,
          }))}
          placeholder="Project Name"
          selectedId={values?.projectName}
          selectedOption={
            projects.filter((project) => project._id === values?.projectName)[0]?.name ?? ""
          }
          onChange={(option) => setFieldValue("projectName", option.value)}
          onAddNew={handleOpenCreateProjectForm} // Add new project handler
        />
        <ErrorMessage
          name="projectName"
          component="div"
          className="text-red-500 text-xs mt-1"
        />
      </div>


      {/* project address */}
      {/* <div >
        <Field
          as={CustomInput}
          type="text"
          id="projectAddress"
          name="projectAddress"
          placeholder="Enter Credit Project Address"
          label="Project Address"
        />
        <ErrorMessage
          name="projectAddress"
          component="div"
          className="text-red-500 text-xs"
        />
      </div> */}

      <div>
        <Field
          as={CustomInput}
          type="text"
          id="rfqName"
          name="rfqName"
          label="RFQ Subject"
          className="bg-transparent h-[30px] text-sm font-normal"
        />
        <p className="text-[10px] text-[#4F4F42] font-normal mt-1 "> <span className="font-semibold">Note :</span>  Provide the name of RFQ for your internal reference. e.g. <i>  steel, cement, RCC work, etc. </i></p>
        <ErrorMessage
          name="rfqName"
          component="div"
          className="text-red-500 text-xs mt-1 "
        />
      </div>

      {/* email */}
      <div>
        <Field
          as={EmailTextField}
          name="emailCopyRecipients"
          id="emailCopyRecipients"
          label="CC Recipients"
          className="bg-transparent text-sm placeholder:text-sm h-[20px] border-0 rounded-none"
          onFocus={() => setIsFocusedCC(true)}
          onBlur={() => setIsFocusedCC(false)}
        />
        <ErrorMessage
          name="emailCopyRecipients"
          component="div"
          className="text-red-500 text-xs mt-1"
        />
      </div>

      {/* rfq type */}
      <div>
        <Field
          className="cursor-pointer"
          component={CustomSelect}
          id="requirementType"
          name="requirementType"
          options={[
            {
              value: "contractor",
              label: "Contractor With Material",
              description: "Select this option if this requirement is for Turnkey Contractor",
            },
            {
              value: "labour",
              label: "Labour",
              description: "Select this option if this requirement is for Labour",
            },
            {
              value: "material",
              label: "Material",
              description: "Select this option if this requirement is for Material",
            },
          ]}
          placeholder="Requirement Type"
          selectedId={values?.requirementType}
          selectedOption={capitalizeFirstLetter(values?.requirementType)}
          onChange={(option) => setFieldValue("requirementType", option.value)}
          alwaysShowOptions={true} // This prop can be utilized in CustomSelect
        />
        <ErrorMessage
          name="requirementType"
          component="div"
          className="text-red-500 text-xs p-0 mt-1"
        />
      </div>

      {/* providing Date field */}
      <div>
        <Field
          as={CustomInput}
          type="date"
          id="providingDate"
          name="providingDate"
          label={values.requirementType === 'material' ? "Expected Delivery Date" : "Expected Work Start Date"}
          className="bg-transparent cursor-pointer text-sm placeholder:text-sm  h-[30px]"
        />
        <ErrorMessage
          name="providingDate"
          component="div"
          className="text-red-500 text-xs mt-1"
        />
      </div>

      {/* Conditionally render components based on requirementType */}
      {values.requirementType === 'material' && <RfqTypeMaterial />}
      {values.requirementType === 'labour' && <RfqTypeLabour />}
      {values.requirementType === 'contractor' && <RfqTypeProviding />}

      <div>
        <h3 className="text-[#434343] font-medium pt-4">Payment Terms</h3>
        <label className="inline-flex justify-between items-center w-full mb-3 cursor-pointer">
          <span className="text-sm font-normal text-[#505050] dark:text-gray-300">
            Vendor can offer counter terms
          </span>
          <div className="flex items-center space-x-6 ml-auto">
            {/* "Yes" option */}
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="radio"
                name="acceptCounterTerms"
                value="yes"
                className="h-3 w-3" // Add margin for spacing
                onChange={(e) => setFieldValue('acceptCounterTerms', e.target.value === 'yes')}
                defaultChecked
              />
              <span className="ml-2 text-[#505050] text-sm">Yes</span>
            </label>

            {/* "No" option */}
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="radio"
                name="acceptCounterTerms"
                value="no"
                className="h-3 w-3" // Add margin for spacing
                onChange={(e) => setFieldValue('acceptCounterTerms', e.target.value === 'yes')}
              />
              <span className="ml-2  text-sm">No</span>
            </label>
          </div>
        </label>
        <div >
          <RfqDetailsSection
            fieldName="paymentTerms"
            updateFormValues={updateRfqFormValues}
            showLabel={false}
            placeholder="Please write your payment terms here"
            className="placeholder:text-sm h-[100px]"
          />
        </div>
      </div>


      {values?.rfqDetails && <div className="pb-10 space-y-2">
        <h3 className="text-[#434343] font-medium">Old RFQ Details</h3>
        <div className="border px-4 py-2 bg-gray-100 text-gray-500">
          {values?.indentId && <p> <b>IndentId : </b> {values?.indentId} </p>}
          {values?.rfqDetails && <p> {ReactHtmlParser(values?.rfqDetails)} </p>}
        </div>
      </div>}


      {/* StaticPopup for adding a new project */}
      <StaticPopup
        openModal={showCreateProjectForm}
        handleClose={() => setShowCreateProjectForm(false)}
        header={"Add Project"}
        size={"sm"}
        width={"3xl"}
        bodyStyle={"pb-0 pt-2"}
      >
        {showCreateProjectForm && (
          <CreateNewProjectForm
            setShowCreateProjectForm={setShowCreateProjectForm}
            onProjectCreated={handleProjectCreation} // Callback to restore form values
          />
        )}
      </StaticPopup>
    </div>
  );
};

export default GeneralInformationNew;
