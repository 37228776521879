import {
  TRASH_RFQ_FAILURE,
  TRASH_RFQ_SUCCESS,
  TRASH_RFQ,
  RESTORE_RFQ,
  FETCH_RFQS,
  FETCH_RFQS_FAILURE,
  FETCH_RFQS_SUCCESS,
  RESTORE_RFQ_FAILURE,
  DELETE_RFQ_SUCCESS,
  DELETE_RFQ_FAILURE,
  DELETE_RFQ,
  RESTORE_RFQ_SUCCESS,
} from "actions/rfqActions";
import produce from "immer";
const initialState = {
  loading: false,
  count: 0,
  rfqs: [],
  statusWiseCounts: [],
  totalCount: 0,
  totalPages: 0,
  currentPage: 0,
  error: false,
  errorMessage: "",
  searchParam: "",
  startDate: "",
  endDate: "",
  startDateOfClosing: "",
  endDateOfClosing: "",
  filterStatus: "",
  filterUserIds: [],
  dateFilterIsActive: false,
  closeDateFilterIsActive: false,
};

const rfqs = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RFQS:
    case RESTORE_RFQ:
    case DELETE_RFQ:
    case TRASH_RFQ: {
      return produce(state, (draft) => {
        draft.loading = true;
        draft.error = false;
        draft.errorMessage = "";
        draft.searchParam = action?.payload?.searchParam ?? "";
        draft.startDate = action?.payload?.startDate ?? "";
        draft.endDate = action?.payload?.endDate ?? "";
        draft.startDateOfClosing = action?.payload?.startDateOfClosing ?? "";
        draft.endDateOfClosing = action?.payload?.endDateOfClosing ?? "";
        draft.filterStatus = action?.payload?.filterStatus ?? "";
        draft.filterUserIds =
          action?.payload?.filterUserIds ?? state?.filterUserIds;
        draft.dateFilterIsActive = action?.payload?.dateFilterIsActive ?? false;
        draft.closeDateFilterIsActive =
          action?.payload?.closeDateFilterIsActive ?? false;
        // draft.rfqs = [];
      });
    }
    case FETCH_RFQS_SUCCESS:
    case DELETE_RFQ_SUCCESS:
    case RESTORE_RFQ_SUCCESS:
    case TRASH_RFQ_SUCCESS: {
      const payload = action?.payload;
      return produce(state, (draft) => {
        draft.loading = false;
        draft.rfqs = payload?.rfqs;
        draft.count = payload?.count ?? state?.count;
        draft.totalCount = payload?.totalCount ?? state?.totalCount;
        draft.totalPages = payload?.totalPages ?? state?.totalPages;
        draft.currentPage = payload?.currentPage ?? state?.currentPage;
        draft.statusWiseCounts =
          payload?.statusWiseCounts ?? state?.statusWiseCounts;
        draft.error = false;
        draft.errorMessage = "";
        draft.filterStatus = ""
      });
    }
    case FETCH_RFQS_FAILURE:
    case RESTORE_RFQ_FAILURE:
    case DELETE_RFQ_FAILURE:
    case TRASH_RFQ_FAILURE: {
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = true;
        draft.errorMessage = action.errorMessage ?? "Something went wrong";
      });
    }
    default: {
      return state;
    }
  }
};
export default rfqs;
