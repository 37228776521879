import {
  ADD_EMAILS_TO_SUPPLIER,
  ADD_VENDORS_TO_RFQ,
  CHANGE_STATUS,
  CLOSE_RFQ,
  CLOSE_RFQ_V2,
  CLOSE_RFQ_WITHOUT_VENDOR,
  CREATE_NEW_PROJECT,
  DELETE_RFQ,
  DELETE_SINGLE_RFQ,
  EDIT_RFQ,
  EDIT_RFQ_PROJECT,
  GET_MEMBER_LIST,
  GET_RFQ,
  GET_RFQS,
  GET_RFQS_BY_STATUS,
  GET_RFQ_PROJECTS,
  GET_USER_CONTACTS_BY_ID,
  INVITE_VENDORS,
  MUL_REINVITE_RFQ,
  QUOTATION_RECEIVED,
  REINVITE_RFQ,
  REJECT_RFQ_APPROVAL_URL,
  REMOVE_VENDORS_RFQ,
  RESTORE_RFQ,
  RFQ_FILTER_DATE,
  SAVE_RFQ,
  SEARCH_RFQ,
  SEND_RFQ,
  SEND_RFQ_FOR_APPROVAL_URL,
  TRASH_RFQ,
  UPDATE_EMAIL_VENDOR,
} from "config/services";
import axios from "utils/axios";

class RfqService {
  saveRfq = (newReqInfo) =>
    new Promise((resolve, reject) => {
      axios
        .post(SAVE_RFQ, newReqInfo)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  createNewProject = (values) => {
    return new Promise((resolve, reject) => {
      axios
        .post(CREATE_NEW_PROJECT, values)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  searchRfq = ({ entityId, searchParam, status, userIds }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(SEARCH_RFQ, { entityId, searchParam, status, userIds })
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  searchRfqByDateFilter = ({
    projectId,
    entityId,
    searchParam,
    status,
    userIds,
    startDate,
    endDate,
    startDateOfClosing,
    endDateOfClosing,
  }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(SEARCH_RFQ, {
          projectId,
          entityId,
          searchParam,
          status,
          userIds,
          startDate,
          endDate,
          startDateOfClosing,
          endDateOfClosing,
        })
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  editRfqProject = (values, projectId) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${EDIT_RFQ_PROJECT}?projectId=${projectId}`, values)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  editRfq = (newReqInfo) =>
    new Promise((resolve, reject) => {
      axios
        .patch(EDIT_RFQ, newReqInfo)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  trashedRfq = ({
    rfqId,
    entityId,
    userIds,
    currentStatus,
    actionBy,
    startDate,
    endDate,
  }) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${TRASH_RFQ}?rfqId=${rfqId}&entityId=${entityId}&currentStatus=${currentStatus}&page=1`,
          { actionBy, userIds, startDate, endDate }
        )
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  deleteRfq = ({
    rfqId,
    entityId,
    userIds,
    currentStatus,
    actionBy,
    startDate,
    endDate,
  }) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${DELETE_RFQ}?rfqId=${rfqId}&entityId=${entityId}&currentStatus=${currentStatus}&page=1`,
          { actionBy, userIds, startDate, endDate }
        )
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  restoreRfq = ({
    rfqId,
    entityId,
    userIds,
    currentStatus,
    actionBy,
    startDate,
    endDate,
  }) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${RESTORE_RFQ}?rfqId=${rfqId}&entityId=${entityId}&currentStatus=${currentStatus}&page=1`,
          { actionBy, userIds, startDate, endDate }
        )
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  inviteVendors = ({ rfqId, supplierIds }) =>
    new Promise((resolve, reject) => {
      axios
        .patch(INVITE_VENDORS, { rfqId: rfqId, supplierIds: supplierIds })
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  removeVendorsFromRfq = (rfqId, supplierId) =>
    new Promise((resolve, reject) => {
      axios
        .delete(`${REMOVE_VENDORS_RFQ}?rfqId=${rfqId}&supplierId=${supplierId}`)

        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  resendBounceEmail = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .patch(`${UPDATE_EMAIL_VENDOR}`, payload)

        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  resendRfq = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .patch(REINVITE_RFQ, payload)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  mulResendRfq = (payload) =>
    new Promise((resolve, reject) => {
      axios
        .patch(MUL_REINVITE_RFQ, payload)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  addVendorsToRfq = ({ rfqId, suppliers, requesterName, requestedBy, senderEmail }) =>
    new Promise((resolve, reject) => {
      axios
        .patch(ADD_VENDORS_TO_RFQ, {
          rfqId,
          suppliers,
          requesterName,
          requestedBy,
          senderEmail
        })
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  addEmailsToSupplier = ({ entityId, rfqId, supplierId, emails }) =>
    new Promise((resolve, reject) => {
      axios
        .patch(ADD_EMAILS_TO_SUPPLIER, { entityId, rfqId, supplierId, emails })
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  sendRfq = (userId, rfqId) =>
    new Promise((resolve, reject) => {
      axios
        .patch(SEND_RFQ, { userId, rfqId })
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  getRfqs = ({ entityId, userIds, status, page }) =>
    new Promise((resolve, reject) => {
      axios
        .post(status === "" ? GET_RFQS : GET_RFQS_BY_STATUS, {
          entityId,
          userIds,
          status,
          page,
        })

        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  rfqFilterDate = ({ payload }) =>
    new Promise((resolve, reject) => {
      axios
        .post(RFQ_FILTER_DATE, payload)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  getRfq = ({ entityId, rfqId }) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${GET_RFQ}?entityId=${entityId}&rfqId=${rfqId}`)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  closeRfq = ({ payload }) =>
    new Promise((resolve, reject) => {

      const url = payload.isApprovalRequired ? SEND_RFQ_FOR_APPROVAL_URL : CLOSE_RFQ_V2;  
      axios
      .patch(url, payload)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  closeRfqWithoutVendor = ({ rfqId }) =>
    new Promise((resolve, reject) => {
      axios
        .patch(CLOSE_RFQ_WITHOUT_VENDOR, { rfqId: rfqId })
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  rejectRfqApproval = ({ payload }) =>
    new Promise((resolve, reject) => {
      axios
        .patch(REJECT_RFQ_APPROVAL_URL,  payload)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  deleteSingleRfq = ({ rfqId, userId, actionBy }) =>
    new Promise((resolve, reject) => {
      axios
        .patch(`${DELETE_SINGLE_RFQ}?rfqId=${rfqId}&userId=${userId}`, {
          actionBy,
        })
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  changeStatus = ({ payload }) =>
    new Promise((resolve, reject) => {
      axios
        .patch(CHANGE_STATUS, payload)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  getUserContacts = ({ userId }) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${GET_USER_CONTACTS_BY_ID}?userId=${userId}`)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  quotationReceived = ({ payload }) =>
    new Promise((resolve, reject) => {
      axios
        .patch(QUOTATION_RECEIVED, payload)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  getRfqProjects = (entityId) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${GET_RFQ_PROJECTS}?entityId=${entityId}`)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  getMemberList = (entityId) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${GET_MEMBER_LIST}?entityId=${entityId}`)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

const rfqService = new RfqService();

export default rfqService;
