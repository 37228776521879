import React, { useEffect } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import ReactQuill from 'react-quill'; // Importing React Quill
import 'react-quill/dist/quill.snow.css'; // Importing the CSS for the editor

const RfqTypeLabour = () => {
    const { values, setFieldValue } = useFormikContext();
    const labourDetails = values.labourDetails;

    const otherCharges = [
        { label: "Mathade Charges", key: "mathadeCharges" },
        { label: "Misc. Charges", key: "miscCharges" }
    ];

    const skillOptions = [
        { value: "", label: "Select Skill" },
        { value: "RCC", label: "RCC" },
        { value: "Re-Inforcement", label: "Re-Inforcement" },
        { value: "Concreting", label: "Concreting" },
        { value: "Shuttering", label: "Shuttering" },
        { value: "Centering", label: "Centering" },
        { value: "Block / Brick Work", label: "Block / Brick Work" },
        { value: "Plastering", label: "Plastering" },
        { value: "Waterproofing", label: "Waterproofing" },
        { value: "Fabrication", label: "Fabrication" },
        { value: "Tiles/Flooring", label: "Tiles/Flooring" },
        { value: "Electrical", label: "Electrical" },
        { value: "Plumbing", label: "Plumbing" },
        { value: "Paver Blocks", label: "Paver Blocks" },
        { value: "Housekeeping & Security", label: "Housekeeping & Security" },
        { value: "Unskilled Labour", label: "Unskilled Labour" },
        { value: "Gypsum", label: "Gypsum" },
        { value: "Demolition", label: "Demolition" },
        { value: "Fire Fighting", label: "Fire Fighting" },
        { value: "Barrication", label: "Barrication" }
    ];

    useEffect(() => {
        if (labourDetails.length === 0) {
            setFieldValue('labourDetails', [{ skill: '', quantity: '', remark: '' }]);
        }
    }, [labourDetails, setFieldValue]);

    const addInputField = () => {
        const newLabourDetails = [...labourDetails, { skill: '', quantity: '', remark: '' }];
        setFieldValue('labourDetails', newLabourDetails);
    };

    const removeInputField = (index) => {
        if (labourDetails.length > 1) {
            const newLabourDetails = labourDetails.filter((_, i) => i !== index);
            setFieldValue('labourDetails', newLabourDetails);
        }
    };

    const isLastEntryComplete = () => {
        const lastEntry = labourDetails[labourDetails.length - 1];
        return lastEntry?.skill?.trim() !== '' && lastEntry?.quantity !== '';
    };

    const handleRadioChange = (key, value) => {
        setFieldValue(`otherCharges.${key}`, value);
    };

    const handleRemarkChange = (value, index) => {
        const updatedLabourDetails = labourDetails.map((detail, i) => (
            i === index ? { ...detail, remark: value } : detail
        ));
        setFieldValue('labourDetails', updatedLabourDetails);
    };

    return (
        <div>
            <div className="space-y-6 pt-4">

                <div>
                    <h3 className="text-base font-medium text-[#434343] mb-2">Add Labour Requirements</h3>
                    <div className="border border-black p-2">
                        <div className="grid grid-cols-12 mb-1 text-sm">
                            <p className="col-span-4 text-sm ml-1">Skill</p>
                            <p className="col-span-2 text-sm ml-2">Quantity</p>
                            <p className="col-span-6 text-sm ml-2">Work Details</p>
                        </div>
                        <div className="max-h-[250px] overflow-y-auto">
                            {labourDetails.map((field, index) => (
                                <div key={index} className="grid grid-cols-12 m-1 gap-2 mb-2 items-start">
                                    <div className="col-span-4">
                                        <Field
                                            as="select"
                                            name={`labourDetails[${index}].skill`}
                                            className="h-[40px] text-sm border focus:outline-none focus:ring-0 border-black w-full bg-color"
                                            onChange={(e) => setFieldValue(`labourDetails[${index}].skill`, e.target.value)}
                                            value={labourDetails[index].skill}
                                        >
                                            {skillOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage
                                            name={`labourDetails[${index}].skill`}
                                            component="div"
                                            className="text-red-500 text-xs mt-1"
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <Field
                                            type="number"
                                            name={`labourDetails[${index}].quantity`}
                                            className="h-[40px] text-sm border focus:outline-none focus:ring-0 border-[#DCDCD0] w-full"
                                        />
                                        <ErrorMessage
                                            name={`labourDetails[${index}].quantity`}
                                            component="div"
                                            className="text-red-500 text-xs mt-1"
                                        />
                                    </div>
                                    <div className="col-span-5">
                                        <ReactQuill
                                            value={field.remark}
                                            onChange={(value) => handleRemarkChange(value, index)}
                                            theme="snow"
                                            className="h-[100px] word-break whitespace-normal focus:outline-none focus:ring-0 text-sm border border-[#DCDCD0] w-full overflow-y-auto" // Set min and max height
                                            modules={{ toolbar: false }} 
                                        /><ErrorMessage
                                            name={`labourDetails[${index}].remark`}
                                            component="div"
                                            className="text-red-500 text-xs mt-1"
                                        />
                                    </div>

                                    <div className="col-span-1 flex justify-end">
                                        {labourDetails.length > 1 && (
                                            <button
                                                type="button"
                                                className="text-red-500"
                                                onClick={() => removeInputField(index)}
                                            >
                                                <svg
                                                    className="w-5 h-5"
                                                    focusable="false"
                                                    aria-hidden="true"
                                                    viewBox="0 0 24 24"
                                                    title="Delete Forever"
                                                >
                                                    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zm2.46-7.12 1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"></path>
                                                </svg>
                                            </button>
                                        )}
                                    </div>

                                </div>
                            ))}
                        </div>

                        <div className="flex justify-end w-full">
                            <button
                                type="button"
                                className="font-medium w-fit text-xs underline"
                                onClick={addInputField}
                                // Disable button if last entry is incomplete
                                // disabled={!isLastEntryComplete()} 
                                // style={{
                                //     cursor: isLastEntryComplete() ? 'pointer' : 'not-allowed',
                                //     color: isLastEntryComplete() ? '#000' : '#888',
                                // }}
                            >
                                + Add Another
                            </button>
                        </div>
                    </div>
                </div>

                {/* Miscellaneous Charges */}
                <div>
                    <p className="text-base font-medium text-[#434343]">Other Charges</p>
                    <p className="text-[#8C8C8C] text-[10px]">
                        Select whether each extra cost is needed for the bid. Vendors will fill them in when sending their offers.
                    </p>
                    <div className="pt-4">
                        {otherCharges?.map((charge) => (
                            <div key={charge?.key} className="mb-4 flex items-center justify-between">
                                <span className="text-sm font-medium text-[#434343]">
                                    {charge.label}
                                </span>
                                <div className="flex items-center space-x-6 ml-auto">
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input
                                            type="radio"
                                            name={`otherCharges.${charge?.key}`}
                                            value="yes"
                                            className="focus:outline-none focus:ring-0 h-3 w-3"
                                            checked={values?.otherCharges[charge?.key] === true}
                                            onChange={() => handleRadioChange(charge?.key, true)}
                                        />
                                        <span className="ml-2 text-sm text-[#505050]">Yes</span>
                                    </label>
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input
                                            type="radio"
                                            name={`otherCharges.${charge.key}`}
                                            value="no"
                                            className="focus:outline-none focus:ring-0 h-3 w-3"
                                            checked={values?.otherCharges[charge.key] === false || values?.otherCharges[charge?.key] === undefined}
                                            onChange={() => handleRadioChange(charge?.key, false)}
                                        />
                                        <span className="ml-2 text-sm text-[#505050]">No</span>
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RfqTypeLabour;
