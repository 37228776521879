import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseRfqOpt from './CloseRfqOpt';
import StaticPopupNew from 'components/StaticPopupNew';



const MoreOptions = ({ index, rfqData, allSuppliers, userProfile }) => {

    const [openDropdown, setOpenDropdown] = useState(null);
    const [openHistory, setOpenHistory] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isClosedRfq, setIsClosedRfq] = useState(false);
    const [isVendorFilesPopupOpen, setIsVendorFilesPopupOpen] = useState(false);
    const [vendorAttachments, setVendorAttachments] = useState([]);
    const isRfqClosed = (rfqData?.status === "CLOSED" || rfqData?.status === "PENDING APPROVAL");
    const latestQuotationSet = rfqData?.suppliers[0]?.vendorQuotations?.quotationSets.slice(-1)[0];
    const dropdownRef = useRef(null);


    const hasApprovalFlowFeature = userProfile?.userEntity?.features?.some(
        feature => feature.featureName === "APPROVAL_FLOW" && feature.hasFeature
    );

    const closeModal = () => {
        setIsModalOpen(!isClosedRfq);
    };
    const handleOpenModal = () => {
        setIsClosedRfq(true);
    };

    const handleCloseModal = () => {
        setIsClosedRfq(false);
    };


    const navigate = useNavigate()

    const navigateToEditRfq = () => {
        switch (rfqData?.rfqType) {
            case "material":
                navigate(`/material-form?rfqId=${rfqData?.rfqId}&isEdit=true&rfqType=${rfqData?.rfqType}`, { state: { rfqData: rfqData, isEdit: true } });
                break;

            case "labour":
                navigate(`/labour-form?rfqId=${rfqData?.rfqId}&isEdit=true&rfqType=${rfqData?.rfqType}`, { state: { rfqData: rfqData, isEdit: true } })
                break;

            case "contractor":
                navigate(`/providing-form?rfqId=${rfqData?.rfqId}&isEdit=true&rfqType=${rfqData?.rfqType}`, { state: { rfqData: rfqData, isEdit: true } })
                break;
        }
    }

     // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(null);
        setOpenHistory(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

    const handleDropdown = (index) => {
        if (openDropdown === index) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(index);
            setOpenHistory(false); // Close history when main dropdown opens
        }
    }

    const handleHistory = () => {
        setOpenHistory(!openHistory);
    }

    const handleOpenVendorFilesPopup = () => {
        const latestQuotationSet = rfqData?.suppliers[0]?.vendorQuotations?.quotationSets.slice(-1)[0];
        if (latestQuotationSet) {
            setVendorAttachments(latestQuotationSet.attachments);
            setIsVendorFilesPopupOpen(true);
        }
    };

    const handleCloseVendorFilesPopup = () => {
        setIsVendorFilesPopupOpen(false);
        setVendorAttachments([]);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleString('en-US', options);
    };

    const renderVersionHistory = () => {
        const quotations = rfqData?.suppliers[0]?.vendorQuotations?.quotationSets || [];

        if (quotations.length === 0) return null; // If no quotations exist, don't render anything.

        const handleQuotationSetClick = (index) => {
            // Check if rfqType is available
            if (!rfqData?.rfqType) {
                console.error("RFQ type is not defined");
                return;
            }

            // Navigate based on rfqType and include the quotationSet index
            switch (rfqData.rfqType) {
                case "material":
                    navigate(`/material-form?rfqId=${rfqData?.rfqId}&quotationIndex=${index}&readOnly=true&rfqType=${rfqData?.rfqType}`, {
                        state: { rfqData, quotationIndex: index, isEdit: true }
                    });
                    break;

                case "labour":
                    navigate(`/labour-form?rfqId=${rfqData?.rfqId}&quotationIndex=${index}&readOnly=true&rfqType=${rfqData?.rfqType}`, {
                        state: { rfqData, quotationIndex: index, isEdit: true }
                    });
                    break;

                case "contractor":
                    navigate(`/providing-form?rfqId=${rfqData?.rfqId}&quotationIndex=${index}&readOnly=true&rfqType=${rfqData?.rfqType}`, {
                        state: { rfqData, quotationIndex: index, isEdit: true }
                    });
                    break;

                default:
                    console.error("Invalid RFQ type");
            }
        };


        return quotations.map((item, index) => {
            // Determine if it's the original or latest version
            const isOriginal = index === 0;
            const isLatest = index === quotations.length - 1;

            // Get the previous quotation to calculate the price difference
            const prevQuotation = quotations[index - 1];
            const priceDifference = prevQuotation
                ? (prevQuotation.totalWithoutGst + prevQuotation.othersTotalWithoutGst) - (item.totalWithoutGst + item.othersTotalWithoutGst)
                : 0;

            const priceDifferencePercent = prevQuotation
                ? ((priceDifference / (prevQuotation.totalWithoutGst + prevQuotation.othersTotalWithoutGst)) * 100).toFixed(2)
                : 0;

            // Format the difference display text
            const differenceDisplay = !isOriginal
                ? ` (${priceDifference > 0 ? "-" : "+"}${new Intl.NumberFormat('en-IN').format(Math.abs(priceDifference))} / ${priceDifference > 0 ? "-" : "+"}${Math.abs(priceDifferencePercent)}%)`
                : "";


            // Get the appropriate heading
            const versionLabel = isOriginal
                ? "Original Quotation"
                : `Revision ${index} ${isLatest ? "(Latest Quotation)" : ""}`;

            return (
                <div key={index}>
                    <li
                        className="flex items-center hover:bg-gray-200 cursor-pointer py-2 bg-white"
                        onClick={() => handleQuotationSetClick(index)}
                    >
                        <div className="pl-4">
                            <h3 className="font-medium text-left leading-tight text-black">{versionLabel}</h3>
                            <p className="font-normal text-left text-xs">{formatDate(item.submittedAt)}</p>
                            <p className="font-normal text-left text-xs">By {item.submittedBy}</p>
                            <p className="font-normal text-left text-xs text-black">
                                Amount: {new Intl.NumberFormat('en-IN').format((item.totalWithoutGst + item.othersTotalWithoutGst).toFixed(0))}
                                {!isOriginal && <span className="text-xs text-gray-600">{differenceDisplay}</span>}
                            </p>
                        </div>
                    </li>
                    {index < quotations.length - 1 && (
                        <hr className="border-gray-200 px-2" />
                    )}
                </div>
            );
        });
    };





    const renderAttachments = () => {
        return vendorAttachments.map((attachment, index) => {
            const lastIndex = attachment.Name.lastIndexOf('.'); // Extract file extension
            const path = lastIndex !== -1 ? attachment.Name.substring(lastIndex + 1) : null;
            return (
                <div key={index} className="flex justify-between items-center mx-2 mt-2 ">
                    <div className="mb-2 px-2 flex w-fit items-center justify-center border border-[#8E8E85] rounded-full py-1 space-x-2 text-[#434343]">
                        <img src={`/assets/icons/${path}.png`} alt={path} className="h-5 w-5" />
                        <a
                            href={attachment.Content}
                            target="_blank"
                            rel="noopener noreferrer"
                            download={attachment.Name}
                            className="text-xs text-[#434343] hover:underline"
                        >
                            {attachment.Name}
                        </a>
                    </div>
                </div>
            );
        });
    };

    return (
        <div ref={dropdownRef}>
            {/* dropdown button */}
            <div className='relative'>
                <div onClick={() => handleDropdown(index)} className={`cursor-pointer border-[1px] bg-white border-[#767676] w-6 h-6 flex justify-center items-center my-2
                     ${openDropdown === index ? 'rotate-180 ' : ''}`}>
                    <img
                        src="https://storagereponeevaydevcdn.blob.core.windows.net/business/searchbar_arrow.svg"
                        alt=""
                    />
                </div>
                {/* <span className='absolute -top-1 -right-1 w-[11px] h-[11px] bg-[#FF4848] rounded-full'></span> */}
            </div>
            {/* dropdown */}
            {openDropdown === index && (
                <div className='absolute right-0 z-20 w-[170px]'>
                    {openHistory && (
                        <ul className="absolute right-[170px] mt-[100px] py-2 max-h-[250px] overflow-y-auto  w-[250px] text-xs shadow-xl border-t text-gray-500 border-s bg-white border-gray-200 dark:border-gray-700 dark:text-gray-400">
                            {/* {timelineItems.map(item => (
                                <li key={item.id} className="flex items-center hover:bg-[#F5F5F3] py-2 bg-white">
                                    
                                    <div className='pl-4'>
                                        <h3 className="font-medium leading-tight text-black">{item.time}</h3>
                                        {item.person && <p className="text-sm font-normal text-left">{item.person}</p>}
                                    </div>
                                </li>
                            ))} */}
                            {renderVersionHistory()}
                        </ul>
                    )}
                    <ul className='bg-white shadow-lg py-2 border text-xs'>
                        {!isRfqClosed && <li
                            onClick={navigateToEditRfq}
                            className='flex items-center px-3 space-x-4 py-2 relative group bg-white hover:bg-[#F5F5F3]'
                        >
                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/bid_levelling_pencil.svg" alt="" className='w-4 h-4' />
                            <span className='w-full text-left text-[#3C3C3C] font-normal cursor-pointer'>Edit Quote</span>
                            {/* <button className='right-0 opacity-0 group-hover:opacity-100 transition-opacity duration-200 text-[#A6A6A6]'>
                                ✕
                            </button> */}
                        </li>}
                        {!isRfqClosed && < li
                            onClick={handleOpenModal}
                            className='flex items-center px-3 space-x-4 py-2 relative group bg-white hover:bg-[#F5F5F3]'
                        >
                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/bid_leveling_soft_award.svg" alt="" className='w-4 h-4' />
                            <span className='w-full text-left text-[#3C3C3C] font-normal cursor-pointer'>{hasApprovalFlowFeature ? 'Send for Approval' : 'Close RFQ'}</span>
                            {/* <button className='right-0 opacity-0 group-hover:opacity-100 transition-opacity duration-200 text-[#A6A6A6]'>
                                ✕
                            </button> */}
                        </li>}

                        {latestQuotationSet?.attachments?.length > 0 && <li onClick={handleOpenVendorFilesPopup} className='flex items-center px-3 space-x-4 py-2 relative group bg-white hover:bg-[#F5F5F3]'>
                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/files.svg" alt="" className='w-4 h-4' />
                            <span className='w-full text-left text-[#3C3C3C] font-normal cursor-pointer'>Vendor Files</span>
                            {/* <button className='right-0 opacity-0 group-hover:opacity-100 transition-opacity duration-200 text-[#A6A6A6]'>
                                ✕
                            </button> */}
                        </li>}

                        <li onClick={handleHistory} className='flex justify-between items-center bg-white hover:bg-[#F5F5F3] px-3'>
                            <div className='flex items-center space-x-4 py-2 '>
                                <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/clock_rotate_left.svg" alt="svg" />
                                <span className='group-hover:opacity-100 transition-opacity duration-200 text-left  text-[#3C3C3C] font-normal cursor-pointer'>Negotations</span>
                            </div>
                            <img
                                src="https://storagereponeevaydevcdn.blob.core.windows.net/business/searchbar_arrow.svg"
                                alt=""
                                className={`transition-transform duration-300 h-2 w-2 ${openHistory ? 'rotate-180' : ''}`}
                            />
                        </li>
                    </ul>

                </div>
            )
            }


            {/* Vendor Files Popup */}
            {
                isVendorFilesPopupOpen && (
                    <StaticPopupNew header={"Vendor Files"} bodyStyle={`pb-0 pt-0`} openModal={isVendorFilesPopupOpen} handleClose={handleCloseVendorFilesPopup}>
                        <div className="px-4 py-2">
                            <h3 className="text-xs font-semibold text-[#434343]">Attachments</h3>
                            <div className="space-y-2">
                                {renderAttachments()}
                            </div>
                        </div>
                    </StaticPopupNew>
                )
            }


            {
                isClosedRfq && (
                    <StaticPopupNew header={"Close RFQ"} bodyStyle={`pb-0 pt-0`} openModal={isClosedRfq} handleClose={handleCloseModal}>
                        <CloseRfqOpt closeModal={handleCloseModal} selectedSupplier={rfqData?.suppliers[0]} allSuppliers={allSuppliers} rfqId={rfqData?.rfqId} entityId={rfqData?.entityId} isApprovalRequired={hasApprovalFlowFeature} />
                    </StaticPopupNew>
                )
            }

        </div >
    );
};

export default MoreOptions;
