import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginWithToken } from 'actions/authActions'; // Import your loginWithToken action
import { Grid, Typography } from '@mui/material';

const TokenValidation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const lead = useSelector((state) => state.lead);
  const isLeadsLoading = lead.loading;
  const leads = lead.leads;

  const token = new URLSearchParams(location.search).get('token');
  const rfqId = new URLSearchParams(location.search).get('rfqId');
  const rfqType = new URLSearchParams(location.search).get('rfqType');

  const [message, setMessage] = useState('');
  const [rfqData, setRfqData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [navigated, setNavigated] = useState(false);

  // Handle token validation
  useEffect(() => {
    if (token) {
      dispatch(loginWithToken(token))
        .then(() => {
          setMessage('Validating RFQ data...');
          setLoading(false);
        })
        .catch((err) => {
          setMessage('Invalid or expired link. Please request a new one. Kindly contact us at support@neevay.com or call us at +91-9503339814.');
          setLoading(false);
          console.error('Token validation failed:', err);
        });
    } else {
      setMessage('Token is missing or invalid.');
      setLoading(false);
    }
  }, [token, dispatch]);

  // Wait for leads to load and process RFQ data
  useEffect(() => {
    if (!isLeadsLoading && leads && leads.length > 0) {
      const foundRfqData = leads.find((rfq) => rfq.rfqId === Number(rfqId));
      if (foundRfqData) {
        setRfqData(foundRfqData);
        setMessage('');
      } else {
        setMessage('RFQ not found.');
      }
    } else if (!isLeadsLoading && (!leads || leads.length === 0)) {
      setMessage('Leads data is unavailable.');
    }
  }, [isLeadsLoading, leads, rfqId]);

  // Navigate to the appropriate form
  useEffect(() => {
    if (rfqData && !navigated) {
      switch (rfqType) {
        case 'material':
          navigate(`/material-form?rfqId=${rfqData?.rfqId}&rfqType=${rfqData?.rfqType}`, { state: { rfqData: rfqData, isEdit: false, supplierId: rfqData?.suppliers[0]?.supplierId } });
          break;
        case 'labour':
          navigate(`/labour-form?rfqId=${rfqData?.rfqId}&rfqType=${rfqData?.rfqType}`, { state: { rfqData: rfqData, isEdit: false, supplierId: rfqData?.suppliers[0]?.supplierId } });
          break;
        case 'contractor':
          navigate(`/providing-form?rfqId=${rfqData?.rfqId}&rfqType=${rfqData?.rfqType}`, { state: { rfqData: rfqData, isEdit: false, supplierId: rfqData?.suppliers[0]?.supplierId } });
          break;
        default:
          setMessage('Invalid RFQ Type');
          break;
      }
      setNavigated(true);
    }
  }, [rfqData, rfqType, navigate, navigated]);

  const loader = (
    <div className="animate-spin rounded-full border-2 mb-2 border-t-2 border-orange-500 border-t-gray-200 h-8 w-8"></div>
  );

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      sx={{
        minHeight: '100vh',
        padding: { xs: '20px', sm: '40px' },
        textAlign: 'center',
      }}
    >
      {loading || isLeadsLoading ? (
        <>
          {loader}
          <Typography
            variant="h6"
            sx={{ fontSize: { xs: '16px', sm: '18px' }, marginTop: '16px' }}
          >
            Fetching Leads or Validating your Token...
          </Typography>
        </>
      ) : (
        <Typography
          variant="h6"
          sx={{ fontSize: { xs: '16px', sm: '18px' }, marginTop: '16px' }}
        >
          {message || 'Redirecting...'}
        </Typography>
      )}
    </Grid>
  );
};

export default TokenValidation;
